import React, { useRef, useState } from 'react';
import './reception.css';
import chat_close_icon from "../../Assets/images/chat/chat_close_icon.png";
import carts_bay_logo from "../../Assets/images/chat/carts_bay_logo.png";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const OrderReception = ({ show, handleClose }) => {
  const componentRef = useRef(null);

  const handleDownload = () => {
    console.log('Download button clicked');
    const doc = new jsPDF();
    const element = componentRef.current;
    html2canvas(element, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      doc.addImage(imgData, 'PNG', 10, 10, 190, 250);
      doc.save('invoice.pdf');
    });
  };

  const handlePrint = () => {
    const contentElement = componentRef.current;
    html2canvas(contentElement, {
      backgroundColor: 'transparent',
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const printWindow = window.open('', '', 'width=600,height=500');
      printWindow.document.open();
      printWindow.document.write('<img src="' + imgData + '" onload="window.print();window.close()" />');
      printWindow.document.close();
    });
  };

  if (!show) {
    return null;
  }
  return (
    <div className="main mb-2 order-reception-modal bg-white " ref={componentRef} style={{ width: '51%', height: '64vh' }}>
      <div className='order-reception-content'>
        <div className="main-header w-100 ">
          <div className="d-flex justify-content-between align-items-between p-2">
            <div className='text-white font-weight-bold'>
              <h5>Invoice</h5>
            </div>
            <div className='close-icon' >
              <img onClick={handleClose} src={chat_close_icon} alt="hide invoice" />
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-between p-3'>
          <div className='invoice-to'>
            <h6>TO:</h6>
            <h5 className='Terry Baptista'>Terry Baptista</h5>
            <p>3455 Geraldline Lane,</p>
            <p>New York</p>
            <p>TOD13</p>
            <p>United States</p>
          </div>
          <div className='invoice-to '>
            <h6 className='invoice-to-from'>FROM:</h6>
            <h5 className='Terry Baptista'>Red Software</h5>
            <p>4945 Forest Avenue,</p>
            <p>New York</p>
            <p>TOD14</p>
            <p>United States</p>
          </div>
          <div className='invoice-to invoice-to-info '>
            <h6>INFO:</h6>
            <h5 className='Terry Baptista'>Amount:$4950.00</h5>
            <p>Date:27/10/2023</p>
            <p>Red:INV-057</p>
            <p>Dues :04/042023</p>
          </div>
        </div>
        <div className='subject d-flex justify-content-start  w-100 ml-3'>
          <h4>Subject:</h4>
          <p className='ml-2 pt-2'>Responsive web design</p>
        </div>
        <div className='item-description d-flex justify-content-between  custom-bottom-line pl-3 pr-3'>
          <div className='description'>
            <h6>ITEM DESCRIPTION</h6>
            <p>Product Name</p>
            <p>Product Name</p>
          </div>
          <div className='qty'>
            <h6>QTY</h6>
            <p className='font-weight-bold'>01</p>
            <p className='font-weight-bold'>01</p>
          </div>
          <div className='rate'>
            <h6>RATE</h6>
            <p className='font-weight-bold'>3,000.00</p>
            <p className='font-weight-bold'>3,000.00</p>
          </div>
          <div className='amount'>
            <h6>AMOUNT</h6>
            <p className='font-weight-bold'>$3,000.00</p>
            <p className='font-weight-bold'>$3,000.00</p>
          </div>
        </div>
        <div className='item-description sub-total d-flex justify-content-between amount-bottom-line pl-3 pr-3 pt-2'>
          <div className='sub-total-text'>
            <p>Sub Total</p>
            <p>GST(10%)</p>
            <p>Delivery fee</p>
          </div>
          <div className='sub-total-amount'>
            <p className='font-weight-bold'>$4,500.00</p>
            <p className='font-weight-bold'>$450.00</p>
            <p className='font-weight-bold align-items-end'>$00</p>
          </div>
        </div>
        <div className='thanks-shopping pl-3 pr-3 d-flex justify-content-start align-items-start'>
          <h6>Thanks for the Shopping</h6>
        </div>
        <div className='pl-3 pr-3 d-flex justify-content-between align-items-center amount-bottom-line p-2'>
          <div>
            <img src={carts_bay_logo} alt="reception icon" />
          </div>
          <div className='payment-detail'>
            <p>payment details</p> 
          </div>
          <div className='paypal-id'>
            <p className='mt-2'>paypal:<b>example@email.com</b></p>
            <p>upi:<b>userid@0kbank</b></p>
          </div>
        </div>
        <div className='d-flex justify-content-between align-items-center payment-detail pl-3 pr-3 '>
          <div>
            <p>www.cartsbay.com</p>
          </div>
          <div>
            <p>support@cartsbay.com</p>
          </div>
          <div>
            <p>646-888-6885</p>
          </div>
        </div>
        <div className='download-button d-flex justify-content-end align-items-end mr-2'>
          <div className='mr-2'>
            <button className='download text-white' onClick={handleDownload}>Download</button>
          </div>
          <div>
            <button className='print text-white' onClick={handlePrint}>Print</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderReception;
