import axios from "axios";
import React, { useEffect, useState } from "react";
import moment from "moment";

import { useSelector } from "react-redux";
import { apiBaseUrl, imgBaseUrl } from "../../config/Config";
import iconAccepted from "../../../Assets/images/icons/icon-accepted.svg";
import iconOnWay from "../../../Assets/images/icons/icon-onway.svg";
import iconDelivered from "../../../Assets/images/icons/icon-delivered.svg";
import IconFreeShipping from "../../../Assets/images/icons/icon-free-shipping.svg";
import Loader from "../../Loader/Loader";
import NoOrderYet from "./NoOrderYet";
import ShipOrderModal from "./ShipOrderModal";
import RejectOrderModal from "./RejectOrderModal";
import ViewReasonModal from "./ViewReasonModal";

const AllOrders = () => {
  const { userToken } = useSelector((state) => state.store);

  const [ordervalue, setOrdervalue] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoad, setIsLoad] = useState(true);
  const [loadData, setLoadData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [noDataFound, setNodataFound] = useState(false);
  const [orderId, setOrderId] = useState(0);
  const [filterAcceptedOrder, setFilterAcceptedOrder] = useState(false);
  const [filterDEliveredOrder, setFilterDeliveredOrder] = useState(false);
  const [shipOrderModal, setShipOrderModal] = useState(false);
  const [rejectOrderModal, setRejectOrderModal] = useState(false);
  const [reasonMessage, setReasonMessage] = useState("");
  const [viewReasonModal, setViewReasonOrderModal] = useState(false);

  useEffect(() => {
    const config = {
      headers: {
        "x-auth-token": userToken,
      },
    };
    axios
      .get(`${apiBaseUrl}/api/order/store-order?page=/${page}`, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          setOrdervalue([...ordervalue, ...res.data.data.order]);
          setLoading(false);
        }
        if (res.data.meta.statusCode === 206) {
          setLoading(false);
          setNodataFound(true);
        }
        if (res.data.meta.page === page) {
          setIsLoad(false);
        } else {
          setPage(res.data.meta.page);
        }
      })
      .catch((err) => {
        console.log("error is >>>>", err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  useEffect(() => {
    FilterOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterAcceptedOrder, filterDEliveredOrder]);

  const FilterOrder = async () => {
    if (filterAcceptedOrder) {
      const config = {
        headers: {
          "x-auth-token": userToken,
        },
      };
      await axios
        .put(`${apiBaseUrl}/api/order/store-accept-order`, { orderId }, config)
        .then((res) => {
          if (res.data.meta.statusCode === 200) {
            let filtereData = ordervalue.map((value) => {
              if (value._id !== res.data.data.order._id) {
                return value;
              } else {
                return res.data.data.order;
              }
            });
            setOrdervalue(filtereData);
          }
        })
        .catch((err) => {
          console.log("error is >>>>", err);
        });
    }
    if (filterDEliveredOrder) {
      const config = {
        headers: {
          "x-auth-token": userToken,
        },
      };
      await axios
        .put(`${apiBaseUrl}/api/order/store-delivered-order`, { orderId }, config)
        .then((res) => {
          if (res.data.meta.statusCode === 200) {
            let filtereData = ordervalue.map((value) => {
              if (value._id !== res.data.data.order._id) {
                return value;
              } else {
                return res.data.data.order;
              }
            });
            setOrdervalue(filtereData);
          }
        })
        .catch((err) => {
          console.log("error is >>>>", err);
        });
    }
  };

  const acceptOrder = (id) => {
    setOrderId(id);
    setFilterAcceptedOrder(true);
  };
  const rejectOrder = (id) => {
    setOrderId(id);
    setRejectOrderModal(true);
  };
  const viewReason = (reason) => {
    setReasonMessage(reason);
    setViewReasonOrderModal(true);
  };

  const shipOrder = (id) => {
    setOrderId(id);
    setShipOrderModal(true);
  };

  const deliverOrder = (id) => {
    setOrderId(id);
    setFilterDeliveredOrder(true);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="mt-3">
            {ordervalue.map((order, index) => {
              return (
                <div key={index} className="inner-card mb-3">
                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="d-flex justify-content-start mt-1">
                      <img
                        className="user-image mr-2"
                        src={`${imgBaseUrl}/profile/avatar_${order.userInfo.image}`}
                        alt="user"
                      />
                      <div className="d-flex flex-column justify-content-start">
                        <div className="user-name">{order.userInfo.name}</div>
                        <div className="user-email mt-1">
                          {order.userInfo.email}
                        </div>
                        <div className="user-email mt-1">
                          {order.userInfo.contact}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column justify-content-between">
                      <div className="d-flex flex-row">
                        <div className="heading-address">
                          Province:{" "}
                          <span className="ml-3 heading-address-span">
                            {order.order.address.province}
                          </span>
                        </div>
                        <div className="mx-5 heading-address">
                          City:{" "}
                          <span className="ml-3 heading-address-span">
                            {order.order.address.city}
                          </span>
                        </div>
                        <div className="heading-address">
                          Postal/Zip Code:{" "}
                          <span className="ml-3 heading-address-span">
                            {order.order.address.postalOrZip}
                          </span>
                        </div>
                      </div>
                      <div className="heading-address mt-3">
                        Address :{" "}
                        <span className="ml-3 heading-address-span">
                          {order.order.address.completeAddress}
                        </span>
                      </div>
                      <div className="heading-address mt-3">
                        Landmark :{" "}
                        <span className="ml-3 heading-address-span">
                          {order.order.address.landmark}
                        </span>
                      </div>
                    </div>
                    {order.order.status === "Accepted" ||
                    order.order.status === "Shipped" ||
                    order.order.status === "Delivered" ? (
                      <>
                        <div className="w-100 mt-2 mb-auto">
                          <div className="d-flex justify-content-between order-progressbar-track">
                            <div
                              className={
                                order.order.status === "Accepted"
                                  ? "track-progressbar text-center bg-progressbar-accepted"
                                  : "track-progressbar text-center bg-progressbar"
                              }
                            >
                              <img src={iconAccepted} alt="iconAccepted" />
                            </div>
                            {order.order.status === "Accepted" ? (
                              <>
                                <div
                                  role="button"
                                  className={
                                    order.order.status === "Shipped"
                                      ? "track-progressbar text-center bg-progressbar-onway"
                                      : "track-progressbar text-center bg-progressbar"
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    shipOrder(order.order._id);
                                  }}
                                  data-toggle="modal"
                                  data-target="#ship-order-modal"
                                >
                                  <img src={iconOnWay} alt="iconOnWay" />
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className={
                                    order.order.status === "Shipped"
                                      ? "track-progressbar text-center bg-progressbar-onway"
                                      : "track-progressbar text-center bg-progressbar"
                                  }
                                >
                                  <img src={iconOnWay} alt="iconOnWay" />
                                </div>
                              </>
                            )}
                            {order.order.status === "Shipped" ? (
                              <>
                                <div
                                  role="button"
                                  className={
                                    order.order.status === "Delivered"
                                      ? "track-progressbar text-center bg-progressbar-delivered"
                                      : "track-progressbar text-center bg-progressbar"
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    deliverOrder(order.order._id);
                                  }}
                                >
                                  <img
                                    src={iconDelivered}
                                    alt="iconDelivered"
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className={
                                    order.order.status === "Delivered"
                                      ? "track-progressbar text-center bg-progressbar-delivered"
                                      : "track-progressbar text-center bg-progressbar"
                                  }
                                >
                                  <img
                                    src={iconDelivered}
                                    alt="iconDelivered"
                                  />
                                </div>
                              </>
                            )}

                            <div id="tracker"></div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="w-25 mt-auto mb-auto">
                          <div className="d-flex justify-content-between order-progressbar-track">
                            <div className="track-progressbar text-center bg-progressbar">
                              <img src={iconAccepted} alt="iconAccepted" />
                            </div>
                            <div className="track-progressbar text-center bg-progressbar">
                              <img src={iconOnWay} alt="iconOnWay" />
                            </div>
                            <div className="track-progressbar text-center bg-progressbar">
                              <img src={iconDelivered} alt="iconDelivered" />
                            </div>
                            <div id="tracker"></div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="bottom-line mt-2"></div>
                  <div className="d-flex">
                    <div className="heading-order mt-2 p-2">
                      Order #{" "}
                      <span className="ml-3 heading-order-span">
                        {order.order.orderId}
                      </span>
                    </div>
                    <div className="mx-3 heading-order mt-2 p-2">
                      Placed on{" "}
                      <span className="ml-3 heading-order-span">
                        {moment(order.order.createdAt).format("DD  MMMM  YYYY")}{" "}
                        {moment(order.order.createdAt).format("h : mm : ss a")}
                      </span>
                    </div>
                    {order.order.deliveredAt === null ? (
                      <></>
                    ) : (
                      <>
                        <div className="mx-3 heading-order mt-2 p-2">
                          Delivered on{" "}
                          <span className="ml-3 heading-order-span">
                            {moment(order.order.deliveredAt).format(
                              "DD  MMMM  YYYY"
                            )}{" "}
                            {moment(order.order.deliveredAt).format(
                              "h : mm : ss a"
                            )}
                          </span>
                        </div>
                      </>
                    )}
                    {order.order.claimedAt === null ? (
                      <></>
                    ) : (
                      <>
                        <div className="mx-3 heading-order mt-2 p-2">
                          Claimed on{" "}
                          <span className="ml-3 heading-order-span">
                            {moment(order.order.claimedAt).format(
                              "DD  MMMM  YYYY"
                            )}{" "}
                            {moment(order.order.claimedAt).format(
                              "h : mm : ss a"
                            )}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="d-flex mt-3">
                      <img
                        className="image-item"
                        src={`${imgBaseUrl}/listing/small_${order.order.itemProperties.image}`}
                        alt="image-item"
                      />
                      <div className="ml-4 d-flex flex-column justify-content-between">
                        <div className="item-title">
                          {order.order.itemProperties.title}
                        </div>
                        <div className="d-flex">
                          <h5 className="item-price">
                            RS.{" "}
                            {order.order.itemProperties.price -
                              (order.order.itemProperties.price *
                                order.order.itemProperties.salePercent) /
                                100}
                          </h5>
                          {order.order.itemProperties.isSale === true && (
                            <h5 className="item-discount-price ml-2">
                              {order.order.itemProperties.price}
                            </h5>
                          )}
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                          <div className="mr-1">
                            <div className="item-detail-heading">Colors</div>
                            <div className="d-flex flex-wrap mt-1">
                              <div
                                className="color-index mr-2 mb-2"
                                style={{
                                  background: `${order.order.itemProperties.color}`,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="mx-1">
                            <div className="item-detail-heading">Size</div>
                            <div className="d-flex flex-wrap mt-1">
                              <div className="size-index mr-2 mb-2 text-center">
                                <div className="size-index-text mt-1">
                                  {order.order.itemProperties.type}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mx-1">
                            <div className="item-detail-heading">Quantity</div>
                            <div className="d-flex mt-2">
                              <div className="item-quantity">
                                {order.order.itemProperties.quantity}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end flex-column">
                      <div className="d-flex justify-content-end align-items-start mb-5">
                        {order.order.itemProperties.isDelivery === false && (
                          <img
                            className="free-shipping-icon mb-4"
                            src={IconFreeShipping}
                            alt="IconFreeShipping"
                          />
                        )}
                      </div>
                      <div className="d-flex">
                        {order.order.status === "Pending" ? (
                          <>
                            <div
                              role="button"
                              className="button-reject text-center mr-2"
                              onClick={(e) => {
                                e.preventDefault();
                                rejectOrder(order.order._id);
                              }}
                              data-toggle="modal"
                              data-target="#reject-order-modal"
                            >
                              Reject
                            </div>
                            <div
                              role="button"
                              className="button-status-accept text-center"
                              onClick={(e) => {
                                e.preventDefault();
                                acceptOrder(order.order._id);
                              }}
                            >
                              Accept
                            </div>
                          </>
                        ) : order.order.status === "Accepted" ? (
                          <>
                            <div className="button-status-accepted text-center">
                              Accepted
                            </div>
                          </>
                        ) : order.order.status === "Shipped" ? (
                          <>
                            <div className="button-status-shipped text-center">
                              Shipped
                            </div>
                          </>
                        ) : order.order.status === "Delivered" ? (
                          <>
                            <div className="button-status-delivered text-center">
                              Delivered
                            </div>
                          </>
                        ) : order.order.status === "Cancelled" ? (
                          <>
                            <div className="button-status-cancelled text-center">
                              Cancelled
                            </div>
                          </>
                        ) : order.order.status === "Rejected" ? (
                          <>
                            <div className="d-flex align-items-center">
                              <div
                                role="button"
                                className="reject-text text-center mr-3"
                                data-toggle="modal"
                                data-target="#view-reason-modal"
                                onClick={(e) => {
                                  e.preventDefault();
                                  viewReason(order.order.rejectedMessage);
                                }}
                              >
                                View Reason
                              </div>
                              <div className="button-status-rejected text-center">
                                Rejected
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {shipOrderModal && (
              <ShipOrderModal
                orderId={orderId}
                ordervalue={ordervalue}
                setOrdervalue={setOrdervalue}
              />
            )}
            {rejectOrderModal && (
              <RejectOrderModal
                orderId={orderId}
                ordervalue={ordervalue}
                setOrdervalue={setOrdervalue}
              />
            )}
            {viewReasonModal && (
              <ViewReasonModal reasonMessage={reasonMessage} />
            )}
          </div>
          {noDataFound === true && <NoOrderYet />}
        </>
      )}
    </>
  );
};

export default AllOrders;
