import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LoginImage from "../Assets/images/login-img.svg";
import IconWave from "../Assets/images/icons/icon-wave.svg";
import IconFacebook from "../Assets/images/icons/icon-facebook.svg";
import IconGoogle from "../Assets/images/icons/icon-google.svg";
import Logo from "../Assets/images/logo-white.svg";
import Joi from "joi";
import { contactRegex, passwordregex } from "../shared/validators";
import { useDispatch, useSelector } from "react-redux";
import { storeData } from "../app/features/store/storeActions";

function Login() {
  const { storeInfo, error } = useSelector((state) => state.store);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [inputType, setInputType] = useState("email");
  const [isEmail, setIsEmail] = useState(true);
  const [formError, setFormError]=useState("");
  const [ errors,setErrors] = useState({password:""})
  const [values, setValues] = useState({
    password: "",
  });

  const [emailValues, setEmailValues] = useState({
    email: "",
  });

  const [contactValues, setContactValues] = useState({
    contact: "",
  });
  const schema = Joi.object({
    password: Joi.string().regex(passwordregex).min(8).max(100).required().messages({
      "string.empty": "Password cannot be empty",
      "string.pattern.base": "Please enter the correct format for the password",
      "string.min": "Password must be at least 8 characters long",
      "string.max": "Password must be no more than 100 characters long"
    }),
  });
  const validateEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
    return emailRegex.test(email);
  }
  const validateContact = (contact) => {
    return contactRegex.test(contact);
  };
  const onChangeInputType = (e) => {
    setFormError("");
    let value = e.target.value;
    if (value && value.includes("@")) {
      setInputType("email");
      setIsEmail(true);
      setEmailValues({
        ...emailValues,
        email: value,
      });
    } else {
      setInputType("contact");
      setIsEmail(false);
      setContactValues({
        ...contactValues,
        contact: value,
      });
    }
  };
  const formValidation = () => {
    const result = schema.validate(values)
    if (!result.error) return null;
    let errors = {};
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };
  const handleInputOnChange = (e) => {
    setErrors("");
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formErrors = formValidation();
    if (formErrors) {
      setErrors(formErrors);
      setLoginError(true);
    }
    let { password } = values;
    let { email } = emailValues;
    let { contact } = contactValues;
    if (isEmail === true) {
      if( validateEmail){
      logIn({
        isEmail,
        email,
        password,
      });
    }else{
      setFormError("enter valid email")
    }}
     else if (isEmail === false) {
      if(validateContact){
      logIn({
        isEmail,
        contact,
        password,
      })}
      else{
        setFormError("enter a valid contact")
      }
    }
  }
  const logIn = (user) => {
    if(!errors){
    // axios
    //   .post(`${apiBaseUrl}/api/store/signin`, { ...user })
    //   .then((res) => {
    //     if (res.data.meta.statusCode === 200) {
    //       sessionStorage.setItem("token", res.data.data.token);
    //       sessionStorage.setItem("isEmail", res.data.data.isEmail);
    //       if(res.data.data.isEmail){
    //         sessionStorage.setItem("email", res.data.data.email);
    //       }else{
    //         sessionStorage.setItem("contact", res.data.data.contact);
    //       }
    //       sessionStorage.setItem("password", res.data.data.password);
    //       navigate("/otp");
    //     }
    //   })
    //   .catch((err) => {
    //     if (err.response.data.meta.statusCode === 400) {
    //       setLoginError(true);
    //       setLoginErrorMessage(err.response.data.meta.message);
    //       setTimeout(() => {
    //         setLoginError(false);
    //         setLoginErrorMessage("");
    //       }, 5000);
    //     }

    //     if (err.response.data.meta.statusCode === 401) {
    //       setLoginError(true);
    //       setLoginErrorMessage(err.response.data.meta.message);
    //       setTimeout(() => {
    //         setLoginError(false);
    //         setLoginErrorMessage("");
    //       }, 5000);
    //     }
    //   });

    const res = storeData({...user})
    dispatch(res);
    }
  };

  useEffect(() => {
    if (error) {
      navigate("/login");
    } else if (storeInfo) {
      navigate("/dashboard");
    }else{
      navigate("/login");
    }
  }, [navigate, storeInfo, error]);

  return (
    <div id="login" style={{overflow: "none"}}>
      <div className="login-container ">
        <Link to="/" className="header-logo">
          <img src={Logo} alt="Logo" />
        </Link>
        <div className="d-flex login-full justify-content-md-center justify-content-between ">
          <div className="left">
            <div className="heading-login">Amazing day.</div>
            <div className="description-login mt-3">
              Maybe some text here will help me see it better. Oh God. Oke,
              let’s do it then.
            </div>
            <div className="text-center">
            <img
              className="login-image-section"
              src={LoginImage}
              alt="LoginImage"
            />
            </div>
          </div>
          <div className="mb-5 right d-flex justify-content-end">
            <div className="login-form">
              <img src={IconWave} alt="IconCancel" />
              <div className="mt-2">
                <div className="heading-form">Welcome back!</div>
                <div className="text-para mt-2">Please Login to Continue</div>
                {loginError && (
                  <div
                    className="error-text text-center alert-danger"
                    role="alert"
                  >
                    {loginErrorMessage}
                  </div>
                )}
              </div>
              <form onSubmit={handleOnSubmit}>
                <div className="mt-4">
                  <div className="field-heading">E-mail or phone number</div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Type your e-mail or phone number"
                      name={inputType}
                      // type={inputType}
                      onChange={(e) => onChangeInputType(e)}
                      required
                    />
                  </div>
                  {errors[inputType] && (
                    <div className="error-text pb-1">
                      {errors[inputType]}
                     </div>
                    )}
                  {formError && (
                    <div className="error-text pb-1">
                      {formError}
                     </div>
                    )}
                </div>

                <div className="mt-2">
                  <div className="field-heading">Password</div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="********"
                      name="password"
                      value={values.password}
                      onChange={handleInputOnChange}
                      required
                    />
                  </div>
                  {errors.password && (
                    <div className="error-text pb-1">
                      {errors.password} 
                    </div>
                  )}
                </div>
                <div className="forget-text">
                  <Link to="/forgot-password">Forget Password ?</Link>
                </div>
                <div className="mt-2">
                  <button type="submit" className="btn-sign-in w-100">
                    Sign In
                  </button>
                </div>
              </form>
              <div className="d-flex justify-content-center align-items-center py-2">
                <span className="line-login"></span>
                <small className="px-2 line-text">
                  or do it via other accounts
                </small>
                <span className="line-login"></span>
              </div>
              <div className="d-flex justify-content-center align-items-center py-3">
                <div role="button" className="icon-card mx-3">
                  <img src={IconGoogle} alt="IconGoogle" />
                </div>
                <div role="button" className="icon-card mx-3">
                  <img src={IconFacebook} alt="IconFacebook" />
                </div>
              </div>
              <div className="mt-3">
                <div className="login-footer mx-1">
                  Don’t have an account?{" "}
                  <span className="login-footer-span">
                    <Link to="/register-seller">Get started</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
