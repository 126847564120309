import React from "react";
import Header from "./Header";
import SideBar from "./SideBar";

function PageLayout(props) {
  return (
    <div id="wrapper" className="d-flex flex-column">
      <Header />
      <div id="content-wrapper" className="d-flex flex-row mt-5">
        <SideBar />
        <div id="content">{props.children}</div>
      </div>
    </div>
  );
}

export default PageLayout;
