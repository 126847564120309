import React from "react";
import IconCancel from "../../../Assets/images/icons/icon-cancel.svg";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl } from "../../config/Config";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateBanner } from "../../../app/features/store/storeSlice";

const DeleteBannerModal = (bannerUrl) => {
  const { userToken, storeInfo} = useSelector((state) => state.store);
  const [BannnerError, setBannerError] = useState(false);
  const [bannerErrorMessage, setBannerErrorMessage] = useState("");
  const dispatch = useDispatch();

  const handleModalClose = () => {
    $("#delete-banner-modal").modal("hide");
  };
  const deleteBanner = ()=>{
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "x-auth-token": userToken,
      },
    };
    axios
    .delete(`${apiBaseUrl}/api/store/delete-banner/${bannerUrl['bannerUrl']}`, config)
    .then((res) => {
      if (res.data.meta.statusCode === 200) {
        dispatch(updateBanner(res.data.data.banner));
        handleModalClose();
      }
    })
    .catch((err) => {
      if (err.response.data.meta.statusCode === 400) {
        setBannerError(true);
        setBannerErrorMessage(err.response.data.meta.message);
        setTimeout(() => {
          setBannerError(false);
          setBannerErrorMessage("");
        }, 5000);
      }

      if (err.response.data.meta.statusCode === 401) {
        setBannerError(true);
        setBannerErrorMessage(err.response.data.meta.message);
        setTimeout(() => {
          setBannerError(false);
          setBannerErrorMessage("");
        }, 5000);
      }
    });           
  }
  return (
    <div
      className="modal"
      id="delete-banner-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="deleteBannerModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="delete-banner-modal">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={IconCancel} alt="IconCancel" />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="body-modal">
                <div className="mt-1">
                  <div className="heading-modal">
                    Are you sure you want to delete?
                  </div>
                </div>

                <div className="mt-1 mb-3 w-100 m-auto d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-cancel"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    No
                  </button>
                  <button type="button" onClick = {deleteBanner} className="btn-delete ml-3">
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteBannerModal;
