import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiBaseUrl } from "../Components/config/Config";
import { toast } from "react-toastify";

const RegisterOtp = () => {
  let navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const handleChange = (OTP) => {
    setOTP(OTP);
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    otpCall(OTP);
  };

  const otpCall = (otp) => {
    const otpParse = Number(otp);
    const config = {
      headers: {
        "x-auth-otp": sessionStorage.getItem("token"),
      },
    };
    axios
      .post(`${apiBaseUrl}/api/store/verify-signup-store-otp`, { otp: otpParse }, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          sessionStorage.setItem("token", res.data.data.token);
          navigate("/register-details");
        }
      })
      .catch((err) => {
        if (err.response.data.meta.statusCode === 400) {
          setOtpError(true);
          setOtpErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setOtpError(false);
            setOtpErrorMessage("");
          }, 5000);
        }

        if (err.response.data.meta.statusCode === 401) {
          setOtpError(true);
          setOtpErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setOtpError(false);
            setOtpErrorMessage("");
          }, 5000);
        }
      });
  };

  const resendOtp = () => {
    let user;
    let isEmail = sessionStorage.getItem('isEmail')
    if(isEmail){
      user = {
        isEmail: sessionStorage.getItem('isEmail'),
        email: sessionStorage.getItem('email'),
        password: sessionStorage.getItem('password'),
      }
    }else{
      user = {
        isEmail: sessionStorage.getItem('isEmail'),
        contact: sessionStorage.getItem('contact'),
        password: sessionStorage.getItem('password'),
      }
    }
      axios
        .post(`${apiBaseUrl}/api/store/signin`,user)
        .then((res) => {
          if (res.data.meta.statusCode === 200) {
            sessionStorage.setItem("token", res.data.data.token);
            sessionStorage.setItem("isEmail", res.data.data.isEmail);
            if(res.data.data.isEmail){
              sessionStorage.setItem("email", res.data.data.email);
            }else{
              sessionStorage.setItem("contact", res.data.data.contact);
            }
            sessionStorage.setItem("password", res.data.data.password);
            navigate("/register-otp");
            toast(`OTP resend successfully!`);
          }
        })
        .catch((err) => {
          if (err.response.data.meta.statusCode === 400) {
            // setLoginError(true);
            // setLoginErrorMessage(err.response.data.meta.message);
            // setTimeout(() => {
            //   setLoginError(false);
            //   setLoginErrorMessage("");
            // }, 5000);
          }
  
          if (err.response.data.meta.statusCode === 401) {
            // setLoginError(true);
            // setLoginErrorMessage(err.response.data.meta.message);
            // setTimeout(() => {
            //   setLoginError(false);
            //   setLoginErrorMessage("");
            // }, 5000);
          }
        });
  }

  return (
    <div id="registerotp">
      <div className="card-center">
        <div className="otp-card">
          <div className="heading-form mt-3">Become a Seller!</div>
          <div className="text-para mt-2">
            Please Enter OTP Send to Email or Phone Number
          </div>
          {otpError && (
            <div className="error-text text-center alert-danger" role="alert">
              {otpErrorMessage}
            </div>
          )}
          <form onSubmit={handleOnSubmit}>
            <div className="py-2 mt-2">
              <div className="field-heading">OTP</div>
              <div className="mt-1">
                <OtpInput
                  onChange={handleChange}
                  value={OTP}
                  inputStyle="otpinputStyle"
                  numInputs={6}
                  separator={<span></span>}
                />
              </div>
            </div>

            <div className="d-flex justify-content-between mt-2 mb-3">
              <div className="otp-resend">Didn't receive OTP</div>
              <div role="button" className="otp-resend-link" onClick={()=>{resendOtp()}}>
                Resend
              </div>
            </div>
            <div className="py-2">
              <button type="submit" className="btn-sign-in w-100">
                Verify
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterOtp;
