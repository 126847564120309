import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import LoginImage from "../Assets/images/login-img.svg";
import IconWave from "../Assets/images/icons/icon-wave.svg";
import Logo from "../Assets/images/logo-white.svg";
import axios from "axios";
import { apiBaseUrl } from "../Components/config/Config";
import { toast } from "react-toastify";
import { clearOtpStorage } from "../shared/clearOtpStorage"
// redux imports
import { useDispatch, useSelector } from "react-redux";
import { storeData } from "../app/features/store/storeActions";

function Otp() {
  let navigate = useNavigate();
  const { loading, storeInfo, error } = useSelector((state) => state.store);
  const dispatch = useDispatch();

  const [OTP, setOTP] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (error) {
      setOTP("");

      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    } else if (storeInfo) {
      navigate("/dashboard");
      clearOtpStorage();
    }
  }, [navigate, storeInfo, error]);

  const handleChange = (OTP) => {
    setOTP(OTP);
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    dispatch(storeData(OTP));
  };

  const BackToLogin = () => {
    clearOtpStorage();
    navigate("/login");
  };

  const resendOtp = () => {
    let user;
    let isEmail = sessionStorage.getItem('isEmail')
    if(isEmail){
      user = {
        isEmail: sessionStorage.getItem('isEmail'),
        email: sessionStorage.getItem('email'),
        password: sessionStorage.getItem('password'),
      }
    }else{
      user = {
        isEmail: sessionStorage.getItem('isEmail'),
        contact: sessionStorage.getItem('contact'),
        password: sessionStorage.getItem('password'),
      }
    }
      axios
        .post(`${apiBaseUrl}/api/store/signin`,user)
        .then((res) => {
          if (res.data.meta.statusCode === 200) {
            sessionStorage.setItem("token", res.data.data.token);
            sessionStorage.setItem("isEmail", res.data.data.isEmail);
            if(res.data.data.isEmail){
              sessionStorage.setItem("email", res.data.data.email);
            }else{
              sessionStorage.setItem("contact", res.data.data.contact);
            }
            sessionStorage.setItem("password", res.data.data.password);
            navigate("/otp");
            toast(`OTP resend successfully!`);
          }
        })
        .catch((err) => {
          if (err.response.data.meta.statusCode === 400) {
            toast(err.response.data.meta.message);
            navigate("/login");
          } if (err.response.data.meta.statusCode === 401) {
             toast(err.response.data.meta.message);
             navigate("/login");
          }
        });
  }

  return (
    <div id="login">
      <div className="login-container">
        <div className="header-logo">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="d-flex justify-content-sm-center  center justify-content-between">
          <div className="d-flex left flex-column">
            <div className="heading-login">Amazing day.</div>
            <div className="description-login mt-3">
              Maybe some text here will help me see it better. Oh God. Oke,
              let’s do it then.
            </div>
            <img
              className="login-image-section"
              src={LoginImage}
              alt="LoginImage"
            />
          </div>
          <div className="w-50 d-flex  justify-content-end">
            <div className="login-form mr-5">
              <img src={IconWave} alt="IconCancel" />
              <div className="mt-2">
                <div className="heading-form">Enter OTP!</div>
                <div className="text-para mt-2">
                  Please Enter OTP Send to Email or Phone Number
                </div>
                {error && (
                  <div
                    className="error-text text-center alert-danger"
                    role="alert"
                  >
                    {errorMessage}
                  </div>
                )}
              </div>
              <form onSubmit={handleOnSubmit}>
                <div className="py-2 mt-2">
                  <div className="field-heading">OTP</div>
                  <div className="mt-1">
                    <OtpInput
                      onChange={handleChange}
                      value={OTP}
                      inputStyle="otpinputStyle"
                      numInputs={6}
                      separator={<span></span>}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-2 mb-3">
                  <div className="otp-resend">Didn't receive OTP</div>
                  <div role="button" className="otp-resend-link" onClick={()=>{resendOtp()}}>
                    Resend
                  </div>
                </div>
                <div className="py-2">
                  <button
                    type="submit"
                    className="btn-sign-in w-100"
                    disabled={loading}
                  >
                    {loading ? "...loading" : "Verify"}
                  </button>
                </div>
              </form>
              <div className="mt-5 ">
                <div className="login-footer mx-3">
                  Back to{" "}
                  <span
                    role="button"
                    className="login-footer-span"
                    onClick={BackToLogin}
                  >
                    Login
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Otp;
