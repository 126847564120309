import { createSlice } from "@reduxjs/toolkit";
import { storeData } from "./storeActions";

const userToken = sessionStorage.getItem("userToken")
  ? sessionStorage.getItem("userToken")
  : null;

const initialState = {
  loading: false,
  storeInfo: null,
  userToken,
  error: null,
  success: false,
  storeListing: [],
};

const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    logout: (state) => {
      state.loading = false;
      state.storeInfo = null;
      state.userToken = null;
      state.error = null;
      state.storeListing = [];
    },
    updateStoreData:(state, {payload}) => {
      state.storeInfo["store"] = payload;
      state.storeInfo["token"] = payload;
    
    },
    updateLogo: (state, { payload }) => {
      state.storeInfo["logo"] = payload;
    },
    updateName: (state, { payload }) => {
      state.storeInfo["storeName"] = payload;
    },
    updateEmail: (state, { payload }) => {
      state.storeInfo["email"] = payload;
      state.storeInfo["isEmailVerify"]= true;
    },
    updateContact: (state, { payload }) => {
      state.storeInfo["contact"] = payload;
      state.storeInfo["isContactVerify"]= true;
    },
    updateBanner: (state, { payload }) => {
      state.storeInfo["banner"] = payload;
    },
    addAddress: (state, { payload }) => {
      state.storeInfo["address"] = payload;
      state.storeInfo["isAddress"] = true;
    },
    clearListing: (state) => {
      state.storeListing = [];
    },
    addMapAddress:(state, {payload})=>{
      state.storeInfo["mapAddress"] = payload['mapAddress'];
      state.storeInfo["location"] = (payload.mapLocation.coordinates);
      state.storeInfo["isMapAddress"] = payload['isMapAddress'];
    },
    addListing: (state, { payload }) => {
      state.storeListing = [...state.storeListing, ...payload];
    },
    deleteListingByID: (state, action) => {
      state.storeListing = state.storeListing.filter(
        (item) => item["_id"] !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(storeData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(storeData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.storeInfo = payload.data.store;
      state.userToken = payload.data.token;
    });
    builder.addCase(storeData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export const {
  logout,
  updateLogo,
  updateName,
  updateEmail,
  updateContact,
  updateBanner,
  addAddress,
  addListing,
  clearListing,
  deleteListingByID,
  addMapAddress,
  updateStoreData
} = storeSlice.actions;
export default storeSlice.reducer;
