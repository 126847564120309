import { useEffect, useState } from "react";

import "react-toastify/dist/ReactToastify.css";
import UserAvatar from "../../Assets/images/avatar.svg";
import IconLocation from "../../Assets/images/icons/dashboard-icons/icon-location.svg";
import IconName from "../../Assets/images/icons/dashboard-icons/userprofile.svg";
import IconEmail from "../../Assets/images/icons/dashboard-icons/icon-email.svg";
import IconEmailDanger from "../../Assets/images/icons/dashboard-icons/icon-email-danger.svg";
import IconPhone from "../../Assets/images/icons/dashboard-icons/icon-phone.svg";
import IconPhoneDanger from "../../Assets/images/icons/dashboard-icons/icon-phone-danger.svg";
import IconPassword from "../../Assets/images/icons/dashboard-icons/icon-password.svg";
import IconBank from "../../Assets/images/icons/dashboard-icons/icon-bank.svg";
import AccountTitle from "../../Assets/images/icons/dashboard-icons/icon-account-title.svg";
import AccountNumber from "../../Assets/images/icons/dashboard-icons/icon-account-number.svg";
import CancelPreview from "../../Assets/images/icons/dashboard-icons/icon-cancel-preview.svg";
import { apiBaseUrl, imgBaseUrl } from "../../Components/config/Config";
import ChangeNameModal from "../../Components/DashboardComponents/Modals/ChangeNameModal";
import ChangeEmailModal from "../../Components/DashboardComponents/Modals/ChangeEmailModal";
import ChangePasswordModal from "../../Components/DashboardComponents/Modals/ChangePasswordModal";
import ChangeNumberModal from "../../Components/DashboardComponents/Modals/ChangeNumberModal";
import ChangeAddressModal from "../../Components/DashboardComponents/Modals/ChangeAddressModal";
import { useDispatch, useSelector } from "react-redux";
import ChangeProfileModal from "../../Components/DashboardComponents/Modals/ChangeProfileModal";
import ChangeKeywordsModal from "../../Components/DashboardComponents/Modals/ChangeKeywordsModal";
import EditBankModal from "../../Components/DashboardComponents/Modals/EditBankModal";
import EditIdentityModal from "../../Components/DashboardComponents/Modals/EditIdentityModal";
import AddEmailModal from "../../Components/DashboardComponents/Modals/AddEmailModal";
import AddPhoneModal from "../../Components/DashboardComponents/Modals/AddPhoneModal";
import AddAddressModal from "../../Components/DashboardComponents/Modals/AddAddressModal";
import ChangeBannerModal from "../../Components/DashboardComponents/Modals/ChangeBannerModal";
import DeleteBannerModal from "../../Components/DashboardComponents/Modals/DeleteBannerModal";
import ChangeMapLocationModal from "../../Components/DashboardComponents/Modals/ChangeMapLocatinModal";
import MapView from "../../Components/MapView/MapView";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { addAddress } from "../../app/features/store/storeSlice";
import axios from "axios"
const Settings = () => {
  const { storeInfo, userToken } = useSelector((state) => state.store);
  const [tab, setTab] = useState("Store Details");
  const [selectedindex, setSelectedIndex] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [deleteBannerUrl, setDeleteBannerUrl] = useState("");
  const tabSelected = (tabitem) => {
    setTab(tabitem);
  };
  const dispatch = useDispatch();
  const config = {
    headers: {
      "x-auth-token": userToken,
    },
  };

  const handleAddAddressClick = () => {
    if (storeInfo?.address.length < 5) {
      setShowAlert(false);
    } else {
      setShowAlert(true);
      toast.error("No more than five addresses");
    }
  };
  const removeAddress = async (indexNumber) => {
    try {
      axios.delete(`${apiBaseUrl}/api/store/deleteaddress/${indexNumber}`, config).then((res) => {
        dispatch(addAddress(res.data.data.address));
      })
    }
    catch (error) {
      toast.error("error occurred while deleting address");
    }
  }
  return (
    <>
      <div id="profilesetting" className="p-3">
        <div className="d-flex flex-wrap justify-content-start">
          {" "}
          {storeInfo.logo === null ? (
            <>
              <img src={UserAvatar} className="image-user" alt="UserAvatar" />
              <div className="ml-3 d-flex flex-column justify-content-between">
                <div className="name"> {storeInfo.storeName}</div>
                <div
                  role="button"
                  className="button-status-cancel text-center mb-2"
                  data-toggle="modal"
                  data-target="#profile-modal"
                >
                  Add Logo
                </div>
              </div>
            </>
          ) : (
            <>
              <img
                src={`${imgBaseUrl}/profile/avatar_${storeInfo.logo}`}
                className="image-user"
                alt="UserImage"
              />
              <div className="ml-3 d-flex flex-column justify-content-between">
                <div className="name"> {storeInfo.storeName}</div>
                <div
                  role="button"
                  className="button-status-cancel text-center mb-2"
                  data-toggle="modal"
                  data-target="#profile-modal"
                >
                  Change Store Picture
                </div>
              </div>
            </>
          )}
        </div>
        <div className="d-flex justify-content-end">
          {storeInfo.status !== "Active"?(<> 
          <p class="warning-box">
            <b>Note: </b>{'Your store is not active Yet'}
          </p>
          </>):(<></>)
          }
        </div>

        <div className="row-scroller">
          <div className="d-flex mt-4">
            <div
              role="button"
              className={
                tab === "Store Details"
                  ? "heading-section heading-section-selected "
                  : "heading-section-normal "
              }
              onClick={(e) => {
                tabSelected("Store Details");
              }}
            >
              {" "}
              Store Details{" "}
            </div>
            <div
              role="button"
              className={
                tab === "Address"
                  ? "heading-section heading-section-selected"
                  : "heading-section-normal "
              }
              onClick={(e) => {
                tabSelected("Address");
              }}
            >
              {" "}
              Address{" "}
            </div>
            <div
              role="button"
              className={
                tab === "Store Banner"
                  ? "heading-section heading-section-selected "
                  : "heading-section-normal "
              }
              onClick={(e) => {
                tabSelected("Store Banner");
              }}
            >
              {" "}
              Store Banner{" "}
            </div>
            <div
              role="button"
              className={
                tab === "Bank Information"
                  ? "heading-section heading-section-selected "
                  : "heading-section-normal "
              }
              onClick={(e) => {
                tabSelected("Bank Information");
              }}
            >
              {" "}
              Bank Information{" "}
            </div>
            <div
              role="button"
              className={
                tab === "Identity"
                  ? "heading-section heading-section-selected "
                  : "heading-section-normal"
              }
              onClick={(e) => {
                tabSelected("Identity");
              }}
            >
              {" "}
              Identity{" "}
            </div>
            <div
              role="button"
              className={
                tab === "Map Location"
                  ? "heading-section heading-section-selected mr-1"
                  : "heading-section-normal mr-1"
              }
              onClick={(e) => {
                tabSelected("Map Location");
              }}
            >
              {" "}
              Map Location{" "}
            </div>
          </div>
        </div>

        {tab === "Store Details" && (
          <>
            <div className="mt-4 d-flex flex-wrap justify-content-around flex-lg-row flex-md-column  flex-sm-column">
              <fieldset className="field-set p-1 address-1">
                <legend className="w-auto ml-2 mr-2 field-set-legend">
                  <div className="ml-2 mr-2">Store Name</div>
                </legend>
                <div className="d-flex flex-column justify-content-start">
                  <div className="d-flex">
                    <div className="mx-3">
                      {" "}
                      <img
                        className="field-set-icon"
                        src={IconName}
                        alt="IconName"
                      />{" "}
                    </div>
                    <div className="field-set-text"> {storeInfo.storeName}</div>
                  </div>
                  <div className="d-flex justify-content-end py-2 px-2">
                    <div
                      className="field-set-link mx-2"
                      role="button"
                      data-toggle="modal"
                      data-target="#name-modal"
                    >
                      {" "}
                      Change
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset className="field-set p-1 address-1 ">
                <legend className="w-auto ml-2 mr-2 field-set-legend">
                  <div className="ml-2 mr-2">Email</div>
                </legend>{" "}
                {storeInfo.isEmailVerify === true ? (
                  <>
                    <div className="d-flex flex-column justify-content-start">
                      <div className="d-flex">
                        <div className="mx-3">
                          {" "}
                          <img
                            className="field-set-icon"
                            src={IconEmail}
                            alt="IconEmail"
                          />{" "}
                        </div>
                        <div className="field-set-text"> {storeInfo.email}</div>
                      </div>
                      <div className="d-flex justify-content-end py-2 px-2">
                        <div
                          className="field-set-link mx-2"
                          role="button"
                          data-toggle="modal"
                          data-target="#email-modal"
                        >
                          Change{" "}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex flex-column justify-content-start">
                      <div className="d-flex">
                        <div className="mx-3">
                          {" "}
                          <img
                            className="field-set-icon"
                            src={IconEmailDanger}
                            alt="IconEmailDanger"
                          />
                        </div>
                        <div className="field-set-text-danger">
                          {" "}
                          Email not verified yet !{" "}
                        </div>
                      </div>
                      <div className="d-flex justify-content-end py-2 px-2">
                        <div
                          className="field-set-text-danger mx-2"
                          role="button"
                          data-toggle="modal"
                          data-target="#add-email-modal"
                        >
                          {" "}
                          Add Email{" "}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </fieldset>
            </div>
            <div className="mt-4 d-flex flex-wrap justify-content-between flex-lg-row flex-md-column flex-sm-column">
              <fieldset className="field-set p-1 address-1">
                <legend className="w-auto ml-2 mr-2 field-set-legend">
                  <div className="ml-2 mr-2">Phone</div>
                </legend>{" "}
                {storeInfo.isContactVerify === true ? (
                  <>
                    <div className="d-flex flex-column justify-content-start">
                      <div className="d-flex">
                        <div className="mx-3">
                          {" "}
                          <img
                            className="field-set-icon"
                            src={IconPhone}
                            alt="IconPhone"
                          />{" "}
                        </div>
                        <div className="field-set-text">
                          {" "}
                          {storeInfo.contact}
                        </div>
                      </div>
                      <div className="d-flex justify-content-end py-2 px-2">
                        <div
                          className="field-set-link mx-2"
                          role="button"
                          data-toggle="modal"
                          data-target="#number-modal"
                        >
                          Change{" "}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex flex-column justify-content-start">
                      <div className="d-flex">
                        <div className="mx-3">
                          {" "}
                          <img
                            className="field-set-icon"
                            src={IconPhoneDanger}
                            alt="IconPhoneDanger"
                          />
                        </div>
                        <div className="field-set-text-danger">
                          {" "}
                          Contact not verified yet !{" "}
                        </div>
                      </div>
                      <div className="d-flex justify-content-end py-2 px-2">
                        <div
                          className="field-set-text-danger mx-2"
                          role="button"
                          data-toggle="modal"
                          data-target="#add-number-modal"
                        >
                          {" "}
                          Add Phone{" "}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </fieldset>
              <fieldset className="field-set p-1 address-1">
                <legend className="w-auto ml-2 mr-2 field-set-legend">
                  <div className="ml-2 mr-2">Password</div>
                </legend>
                <div className="d-flex flex-column justify-content-start">
                  <div className="d-flex">
                    <div className="mx-3">
                      {" "}
                      <img
                        className="field-set-icon"
                        src={IconPassword}
                        alt="IconPassword"
                      />{" "}
                    </div>
                    <div className="field-set-text">**********************</div>
                  </div>
                  <div className="d-flex justify-content-end py-2 px-2">
                    <div
                      className="field-set-link mx-2"
                      role="button"
                      data-toggle="modal"
                      data-target="#password-modal"
                    >
                      Change{" "}
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="mt-3">
              <fieldset className="field-set p-1">
                <legend className="w-auto ml-2 mr-2 field-set-legend">
                  <div className="ml-2 mr-2">Keywords</div>
                </legend>
                <div className="d-flex  justify-content-start">
                  {" "}
                  {JSON.parse(storeInfo.keyWords).map((keywords, index) => {
                    return (
                      <div
                        key={index}
                        role="button"
                        className="ml-3 button-status-cancel text-center"
                      >
                        {" "}
                        {keywords}
                      </div>
                    );
                  })}
                </div>
                <div className="d-flex justify-content-end py-2 px-2">
                  <div
                    className="field-set-link mx-2"
                    role="button"
                    data-toggle="modal"
                    data-target="#keywords-modal"
                  >
                    {" "}
                    Change
                  </div>
                </div>
              </fieldset>
            </div>
          </>
        )}

        {tab === "Address" && (
          <>
          <div className="d-flex justify-content-between">
            {storeInfo['isAddress']?(
                <> <p class="warning-box2 mt-3">
                <b>Note: </b>{'Your address is not verified yet!'}
              </p> </>
              ):(<></>)}
            <div className="d-flex justify-content-end mt-2">
              <div
                role="button"
                className="button-status-cancel text-center mb-2"
                data-toggle="modal"
                data-target={showAlert ? undefined : "#add-address-modal"}
                onClick={handleAddAddressClick}
              >
                {" "}
                Add New Address{" "}
              </div>
            </div>
            </div>
            <div className="content-box">
              <div className="mt-1">
                {" "}
                {storeInfo.address.map((address, index) => {
                  return (
                    <fieldset key={index} className="field-set p-1">
                      <legend className="w-auto ml-2 mr-2 field-set-legend">
                        <div className="ml-2 mr-2">
                          {" "}
                          Store Address {index + 1}
                        </div>
                      </legend>
                      <div className="d-flex flex-column justify-content-start">
                        <div className="d-flex">
                          <div className="mx-3">
                            {" "}
                            <img
                              className="field-set-icon"
                              src={IconLocation}
                              alt="IconLocation"
                            />{" "}
                          </div>
                          <div className="field-set-text">
                            {" "}
                            {address.storeAddress}
                            {", "}
                            {address.postalOrZip}
                            {", "}
                            {address.area}
                            {", "}
                            {address.city}
                            {", "}
                            {address.province}
                          </div>
                        </div>
                        <div className="d-flex justify-content-end py-2 px-2">
                          {storeInfo.address.length > 1 ?
                            <div
                              className="field-set-link mx-2"
                              role="button"
                              onClick={() => removeAddress(index)}
                            >
                              Remove
                            </div> : <></>}
                          <div
                            className="field-set-link mx-2"
                            role="button"
                            data-toggle="modal"
                            data-target="#address-modal"
                            onClick={() => setSelectedIndex(index)}
                          >
                            Change{" "}
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  );
                })}
              </div>
            </div>
          </>
        )}

        {tab === "Store Banner" && (
          <>
            <div className="mt-3">
              <fieldset className="field-set p-1">
                <legend className="w-auto ml-2 mr-2 field-set-legend">
                  <div className="ml-2 mr-2">Banner Images</div>
                </legend>
                <div className="d-flex flex-column justify-content-start">
                  <div className="d-flex flex-wrap">
                    {" "}
                    {storeInfo.banner.map((banners, index) => {
                      return (
                        <>
                          <div className="position-relative">
                            <div
                              onClick={() => setDeleteBannerUrl(banners)}
                              role="button"
                              className="mr-1 d-flex justify-content-end"
                              data-toggle="modal"
                              data-target="#delete-banner-modal"
                            >
                              {" "}
                              <img
                                className="position-absolute"
                                src={CancelPreview}
                                alt="CancelPreview"
                              />{" "}
                            </div>{" "}
                            <img
                              key={index}
                              className="banner-image"
                              src={`${imgBaseUrl}/profile/${banners}`}
                              alt="bannerImage"
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div className="d-flex justify-content-end py-2 px-2">
                    <div
                      className="field-set-link mx-2"
                      role="button"
                      data-toggle="modal"
                      data-target="#banner-modal"
                    >
                      {storeInfo.banner.length > 0 ? 'Change Banner' : 'Add Banner'}
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </>
        )}

        {tab === "Bank Information" && (
          <>
            <div>
              {!storeInfo['isBankVerify']?(<>
                <> <p class="warning-box2 mt-3">
                <b>Note: </b>{'Your Bank information is not verified yet!'}
              </p> </>
              </>): (<></>)}
              <div className="mt-2 d-flex flex-wrap justify-content-between">
                <fieldset className="field-set p-1 address-1 pb-3">
                  <legend className="w-auto ml-2 mr-2 field-set-legend">
                    <div className="ml-2 mr-2">Bank Name</div>
                  </legend>
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex">
                      <div className="mx-3">
                        {" "}
                        <img
                          className="field-set-icon"
                          src={IconBank}
                          alt="IconBank"
                        />{" "}
                      </div>
                      <div className="field-set-text">
                        {" "}
                        {storeInfo?.bankAccount?.bankName}
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset className="field-set p-1 address-1 pb-3">
                  <legend className="w-auto ml-2 mr-2 field-set-legend">
                    <div className="ml-2 mr-2">Account Title</div>
                  </legend>
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex">
                      <div className="mx-3">
                        {" "}
                        <img
                          className="field-set-icon"
                          src={AccountTitle}
                          alt="AccountTitle"
                        />{" "}
                      </div>
                      <div className="field-set-text">
                        {" "}
                        {storeInfo?.bankAccount?.accountTitle}
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="mt-2 d-flex flex-wrap justify-content-between">
                <fieldset className="field-set p-1 address-1 pb-3">
                  <legend className="w-auto ml-2 mr-2 field-set-legend">
                    <div className="ml-2 mr-2">Account Number</div>
                  </legend>
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex">
                      <div className="mx-3">
                        {" "}
                        <img
                          className="field-set-icon"
                          src={AccountNumber}
                          alt="AccountNumber"
                        />
                      </div>
                      <div className="field-set-text">
                        {" "}
                        {storeInfo?.bankAccount?.accountNumber}
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset className="field-set p-1 address-1 pb-3">
                  <legend className="w-auto ml-2 mr-2 field-set-legend">
                    <div className="ml-2 mr-2">IBN Account Number</div>
                  </legend>
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex">
                      <div className="mx-3">
                        {" "}
                        <img
                          className="field-set-icon"
                          src={AccountNumber}
                          alt="AccountNumber"
                        />
                      </div>
                      <div className="field-set-text">
                        {" "}
                        {storeInfo?.bankAccount?.ibanNumber}
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="mt-2 d-flex flex-wrap justify-content-between">
                <fieldset className="field-set p-1 address-1 pb-3">
                  <legend className="w-auto ml-2 mr-2 field-set-legend">
                    <div className="ml-2 mr-2">Branch Code</div>
                  </legend>
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex">
                      <div className="mx-3">
                        {" "}
                        <img
                          className="field-set-icon"
                          src={AccountNumber}
                          alt="AccountNumber"
                        />
                      </div>
                      <div className="field-set-text">
                        {" "}
                        {storeInfo?.bankAccount?.branchCode}
                      </div>
                    </div>
                  </div>
                </fieldset>{" "}
                {/* <fieldset className="field-set p-1 address-1 pb-3">
              <legend className="w-auto ml-2 mr-2 field-set-legend">
                <div className="ml-2 mr-2">Cheque</div>
              </legend>
              <div className="d-flex flex-column justify-content-start">
                <img src={`${imgBaseUrl}/check/${storeInfo.bankAccount.checkImage}`}
                  className="card-preview" alt="idCardPreview" />
              </div>
            </fieldset> */}
              </div>{" "}
              {storeInfo.isBankVerify === false && (
                <div className="mt-5">
                  <div className="d-flex justify-content-end">
                    <div
                      role="button"
                      className="button-status-cancel text-center"
                      data-toggle="modal"
                      data-target="#bank-modal"
                    >
                      {" "}
                      Edit Bank Account{" "}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        {tab === "Identity" && (
          <>
            <div>
              {!storeInfo['isCardVerify'] ?(<>
                <p class="warning-box2 mt-3">
                <b>Note: </b>{'Your identity is not verified yet!'}
              </p>
              </>):(<></>)}
              <div className="mt-4 d-flex flex-wrap justify-content-between">
                <fieldset className="field-set p-1 address-1 pb-3">
                  <legend className="w-auto ml-2 mr-2 field-set-legend">
                    <div className="ml-2 mr-2">Full Name</div>
                  </legend>
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex">
                      <div className="mx-3">
                        {" "}
                        <img
                          className="field-set-icon"
                          src={IconName}
                          alt="IconName"
                        />{" "}
                      </div>
                      <div className="field-set-text">
                        {" "}
                        {storeInfo?.cardIdentification?.firstAndLastName}
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset className="field-set p-1 address-1 pb-3">
                  <legend className="w-auto ml-2 mr-2 field-set-legend">
                    <div className="ml-2 mr-2">CNIC</div>
                  </legend>
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex">
                      <div className="mx-3">
                        {" "}
                        <img
                          className="field-set-icon"
                          src={AccountNumber}
                          alt="AccountNumber"
                        />
                      </div>
                      <div className="field-set-text">
                        {" "}
                        {storeInfo?.cardIdentification?.cnic}
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="mt-2 d-flex flex-wrap justify-content-between">
                <fieldset className="field-set p-1 address-1 pb-3">
                  <legend className="w-auto ml-2 mr-2 field-set-legend">
                    <div className="ml-2 mr-2">ID Card (Front)</div>
                  </legend>
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex">
                      {" "}
                      <img
                        src={`${imgBaseUrl}/identity/${storeInfo?.cardIdentification?.frontImage}`}
                        className="card-preview"
                        alt="idCardPreview"
                      />{" "}
                    </div>
                  </div>
                </fieldset>
                <fieldset className="field-set p-1 address-1 pb-3">
                  <legend className="w-auto ml-2 mr-2 field-set-legend">
                    <div className="ml-2 mr-2">ID Card (Back)</div>
                  </legend>
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex">
                      {" "}
                      <img
                        src={`${imgBaseUrl}/identity/${storeInfo?.cardIdentification?.backImage}`}
                        className="card-preview"
                        alt="idCardPreview"
                      />{" "}
                    </div>
                  </div>
                </fieldset>
              </div>{" "}
              {storeInfo.isCardVerify === false && (
                <div className="mt-3">
                  <div className="d-flex justify-content-end">
                    <div
                      role="button"
                      className="button-status-cancel text-center"
                      data-toggle="modal"
                      data-target="#identity-modal"
                    >
                      {" "}
                      Edit Identity{" "}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        {tab === "Map Location" && (
          <>
            {
              storeInfo['isMapAddress'] ?

                <div className="mt-3">
                  <fieldset className="field-set p-1">
                    <legend className="w-auto ml-2 mr-2 field-set-legend">
                      <div className="ml-2 mr-2">Map Location</div>
                    </legend>
                    <div style={{ width: 'auto', height: 'auto' }}>
                      <MapView lat={storeInfo?.location?.[1]} lng={storeInfo?.location?.[0]} mapAddress={storeInfo['mapAddress']} zoom={15} width={'72vw'} height={'50vh'} />
                    </div>
                    <div className="d-flex justify-content-end py-2 px-2">
                      <div
                        className="field-set-link mx-2"
                        role="button"
                        data-toggle="modal"
                        data-target="#map-location-modal">{storeInfo.isMapAddress ? 'Change Location' : 'Add Location'}</div>
                    </div>
                  </fieldset>
                </div>
                : <>
                  <div className="mt-3">
                    <fieldset className="field-set p-1">
                      <legend className="w-auto ml-2 mr-2 field-set-legend">
                        <div className="ml-2 mr-2">Map Location</div>
                      </legend>
                      <div className="d-flex flex-column justify-content-start">
                        <div className="d-flex justify-content-end py-2 px-2">
                          <div
                            className="field-set-link mx-2"
                            role="button"
                            data-toggle="modal"
                            data-target="#map-location-modal"
                          >
                            {" "}
                            Add Map Location
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </>
            }
          </>
        )}
      </div>{" "}
      {/* Change Profile Modal */}
      <ChangeProfileModal />
      {/* Change Name Modal */}
      <ChangeNameModal />
      {/* Change Email Modal */}
      <ChangeEmailModal />
      {/* Add Email Modal */}
      <AddEmailModal />
      {/* Change Number Modal */}
      <ChangeNumberModal />
      {/* Add Number Modal */}
      <AddPhoneModal />
      {/* Change password Modal */}
      <ChangePasswordModal />
      {/* Change keyWords Modal */}
      <ChangeKeywordsModal />
      {/* Change Address Modal */}
      <ChangeAddressModal index={selectedindex} />

      {/* Change Banner Modal */}
      <ChangeBannerModal />
      {/* Delete Banner Modal */}
      <DeleteBannerModal bannerUrl={deleteBannerUrl} />
      {/* Add new Address Modal */}
      <AddAddressModal />
      {/* Edit Bank details Modal */}
      <EditBankModal />
      {/* Edit Identity details Modal */}
      <EditIdentityModal />
      <ChangeMapLocationModal/>
    </>
  );
};
export default Settings;
