import React from "react";
import IconCancel from "../../../Assets/images/icons/icon-cancel.svg";
import $ from "jquery";

const ChangeKeywordsModal = () => {
  const handleModalClose = () => {
    $("#keywords-modal").modal("hide");
  };

  return (
    <div
      className="modal"
      id="keywords-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="keywordsModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="keywords-modal">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={IconCancel} alt="IconCancel" />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="body-modal">
                <div className="mt-1">
                  <div className="heading-modal">Change KeyWords!</div>
                </div>
                <div className="mt-3">
                  <div className="field-heading">Email</div>
                  <div className="form-group">
                    <input type="email" className="form-control" name="email" />
                  </div>
                </div>
                <div className="mt-1 mb-3">
                  <button type="button" className="btn-sign-in w-100">
                    Save Change
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeKeywordsModal;
