import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

function ProtectedRoute({ component: Component }) {
  const { userToken, storeInfo } = useSelector((state) => state.store);
  const location = useLocation();

  if(userToken && (!storeInfo['isAddress'] || !storeInfo['isCardVerify'] || !storeInfo['isBankVerify'] || storeInfo['status' !== "Active"])){
    return <Navigate to="/settings" state={{ from: location }} replace />
  }

  return userToken ? (
    <Component />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}

export default ProtectedRoute;
