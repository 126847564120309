import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../app/features/store/storeSlice";
import { useState, useEffect } from "react";
import axios from "axios";
import { apiBaseUrl, imgBaseUrl } from "../../config/Config";

import Logo from "../../../Assets/images/logo.svg";
import UserAvatar from "../../../Assets/images/avatar.svg";
import iconDashboard from "../../../Assets/images/icons/dashboard-icons/dashboard.svg";
import iconDashboardActive from "../../../Assets/images/icons/dashboard-icons/dashboardactive.svg";
import iconMyorder from "../../../Assets/images/icons/dashboard-icons/myorder.svg";
import iconMyorderActive from "../../../Assets/images/icons/dashboard-icons/myorderactive.svg";
import iconProducts from "../../../Assets/images/icons/dashboard-icons/products.svg";
import iconProductsActive from "../../../Assets/images/icons/dashboard-icons/productsactive.svg";
import iconSetting from "../../../Assets/images/icons/dashboard-icons/setting.svg";
import iconSettingActive from "../../../Assets/images/icons/dashboard-icons/settingactive.svg";
import iconLogout from "../../../Assets/images/icons/dashboard-icons/logout.svg";
import iconThumb from "../../../Assets/images/icons/dashboard-icons/thumb.svg";
import iconThumbActive from "../../../Assets/images/icons/dashboard-icons/thumb-active.svg";
import HeaderNotification from "../../HeaderNotification/HeaderNotification";
import iconNotification from "../../../Assets/images/chat/iconNotification.png"

function Header() {
  const { storeInfo, userToken } = useSelector((state) => state.store);
  const [isNotification, SetIsNotification] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0)
  const [loading, setLoading] = useState(false);

  const socket = window.globalSocket;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "x-auth-token": userToken,
    },
  };
  let navigate = useNavigate();
  const dispatch = useDispatch();

  let pathname = window.location.pathname;

  const logOut = () => {
    dispatch(logout());
    socket.disconnect();
    navigate("/login");
  };
  
  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/api/notification/store-notification-count`, config)
      .then((res) => {
        if (res) {
          setNotificationCount(res.data.data.notificationCount);
        }
      })
      .catch((error) => {
        setNotificationCount(0);
      });
  }, []);

  socket?.on("recive-notification", (data) => {
    if (data) {
      setNotificationCount((prevCount) => prevCount + 1);
    } else {
      setNotificationCount(0);
    }
  });
  const toggleToast = () => {
    // setShowToast((prevState) => !prevState);
    SetIsNotification(!isNotification)
    axios
      .put(`${apiBaseUrl}/api/notification/store-notification-status`, {}, config)
      .then((res) => {
        setNotificationCount(0);
      })
      .catch((error) => {
        setNotificationCount(notificationCount);
      });
  };

  return (
    <>
      <nav className="navbar navbar-expand navbar-light bg-white topbar fixed-top  shadow">
        <Link className="nav-link logo" to="/dashboard">
          <img src={Logo} alt="Logo" />
        </Link>
        {/* <button
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
        >
          <i className="fa fa-bars"></i>
        </button> */}

        <ul className="navbar-nav ml-auto">
        <li className="mt-2 mr-4 list-unstyled">
          {userToken ?(
        <div role="button" id="liveToastBtn" >

              <div className="position-relative">
                <img
                  className="cart-icon img-profile"
                  src={iconNotification}
                  alt="CartIcon"
                  onClick= {toggleToast}
                />
                {notificationCount > 0 &&
                  (notificationCount > 9 ? (
                    <span className="notification-badge bg-danger rounded-circle text-white">
                      9+
                    </span>
                  ) : (
                    <span className="notification-badge bg-danger rounded-circle text-white">
                      {notificationCount}
                    </span>
                  ))}{" "}
                    {isNotification === true ? (
                  <HeaderNotification isNotification= {isNotification} />
                ) : (
                  <></>
                )}
              </div>
            </div>):(<></>)
}

            </li>

          <li className="nav-item dropdown no-arrow">
            <Link
              className="nav-link dropdown-toggle"
              to="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {storeInfo.logo === null ? (
                <>
                  {/* <>
                <HeaderNotification/>
                </> */}
                  <img
                    className="img-profile rounded-circle"
                    src={UserAvatar}
                    width="30"
                    height="30"
                    alt="userprofile"
                  />
                </>
              ) : (
                <>
                  <img
                    className="img-profile rounded-circle"
                    src={`${imgBaseUrl}/profile/avatar_${storeInfo.logo}`}
                    width="30"
                    height="30"
                    alt="userprofile"
                  />
                </>
              )}
            </Link>
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              <Link className="dropdown-item mr-4" to="/dashboard">
                {pathname.match("/dashboard") ? (
                  <>
                    <img
                      className="mr-3"
                      src={iconDashboardActive}
                      alt="iconDashboardActive"
                    />
                  </>
                ) : (
                  <>
                    <img
                      className="mr-3"
                      src={iconDashboard}
                      alt="iconDashboard"
                    />
                  </>
                )}
                <span
                  className={`${pathname.match("/dashboard")
                      ? "sidebar-span-active"
                      : "sidebar-span"
                    }`}
                >
                  Dashboard
                </span>
              </Link>
              <Link className="dropdown-item mr-4" to="/orders">
                {pathname.match("/orders") ? (
                  <>
                    <img
                      className="mr-3"
                      src={iconMyorderActive}
                      alt="iconMyorderActive"
                    />
                  </>
                ) : (
                  <>
                    <img className="mr-3" src={iconMyorder} alt="iconMyorder" />
                  </>
                )}
                <span
                  className={`${pathname.match("/orders")
                      ? "sidebar-span-active"
                      : "sidebar-span"
                    }`}
                >
                  My Orders
                </span>
              </Link>
              <Link className="dropdown-item mr-4" to="/menu">
                {pathname.match("/menu") ? (
                  <>
                    <img
                      className="mr-3"
                      src={iconProductsActive}
                      alt="iconProductsActive"
                    />
                  </>
                ) : (
                  <>
                    <img
                      className="mr-3"
                      src={iconProducts}
                      alt="iconProducts"
                    />
                  </>
                )}
                <span
                  className={`${pathname.match("/menu")
                      ? "sidebar-span-active"
                      : "sidebar-span"
                    }`}
                >
                  Products
                </span>
              </Link>
              <Link className="dropdown-item mr-4" to="/reviews">
                {pathname.match("/reviews") ? (
                  <>
                    <img
                      className="mr-3"
                      src={iconThumbActive}
                      alt="iconThumbActive"
                    />
                  </>
                ) : (
                  <>
                    <img className="mr-3" src={iconThumb} alt="iconThumb" />
                  </>
                )}
                <span
                  className={`${pathname.match("/reviews")
                      ? "sidebar-span-active"
                      : "sidebar-span"
                    }`}
                >
                  Product Reviews
                </span>
              </Link>

              <Link className="dropdown-item mr-4" to="/settings">
                {pathname.match("/settings") ? (
                  <>
                    <img
                      className="mr-3"
                      src={iconSettingActive}
                      alt="iconSettingActive"
                    />
                  </>
                ) : (
                  <>
                    <img className="mr-3" src={iconSetting} alt="iconSetting" />
                  </>
                )}
                <span
                  className={`${pathname.match("/settings")
                      ? "sidebar-span-active"
                      : "sidebar-span"
                    }`}
                >
                  Accout Setting
                </span>
              </Link>
              <div className="dropdown-divider"></div>
              <div
                className="dropdown-item mr-4"
                role="button"
                onClick={(e) => {
                  e.preventDefault();
                  logOut();
                }}
              >
                <img className="mr-2" src={iconLogout} alt="iconLogout" />
                <span className="sidebar-span">Logout</span>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Header;
