import React, { useState } from "react";
import IconCancel from "../../../Assets/images/icons/icon-cancel.svg";
import { useSelector } from "react-redux";
import axios from "axios";
import $ from "jquery";
import { apiBaseUrl } from "../../config/Config";

const ShipOrderModal = ({ ordervalue, orderId, setOrdervalue }) => {
  const { userToken } = useSelector((state) => state.store);

  const [trackId, setTrackId] = useState("");

  const handleInputOnChange = (e) => {
    setTrackId(e.target.value);
  };

  const handleModalClose = () => {
    $("#ship-order-modal").modal("hide");
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const data = {
      orderId: orderId,
      trackId: trackId,
    };

    const config = {
      headers: {
        "x-auth-token": userToken,
      },
    };
    axios
      .put(`${apiBaseUrl}/api/order/store-shipped-order`, data, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          let filtereData = ordervalue.map((value) => {
            if (value.order._id !== res.data.data.order.order._id) {
              return value;
            } else {
              return res.data.data.order;
            }
          });
          setOrdervalue(filtereData);
          handleModalClose();
        }
      })
      .catch((err) => {
        console.log("error >>>", err.response);
      });
  };

  return (
    <div
      className="modal"
      id="ship-order-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="shipordermodal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="ship-order-modal">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <div className="heading-modal">Product Shipping!</div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={IconCancel} alt="IconCancel" />
                </span>
              </button>
            </div>
            <div className="modal-body pt-0 review-order-modal-body">
              <form onSubmit={handleOnSubmit}>
                <div className="form-group">
                  <div className="field-heading">Tracking Id</div>
                  <input
                    type="text"
                    className="form-control"
                    value={trackId || ""}
                    name="trackId"
                    onChange={handleInputOnChange}
                  />
                </div>
                <div className="mt-1 mb-3">
                  <button type="submit" className="btn-sign-in w-100">
                    Done
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipOrderModal;
