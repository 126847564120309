import React from "react";
import IconCancel from "../../../Assets/images/icons/icon-cancel.svg";

const ViewReasonModal = ({ reasonMessage }) => {
  return (
    <div
      className="modal"
      id="view-reason-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="viewreasonmodal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="view-reason-modal">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <div className="heading-modal">Rejected Reason!</div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={IconCancel} alt="IconCancel" />
                </span>
              </button>
            </div>
            <div className="modal-body pt-0 review-order-modal-body">
              <div className="m-3">
                <div className="reason-message">{reasonMessage}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewReasonModal;
