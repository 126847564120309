import React from "react";
import IconCancel from "../../../Assets/images/icons/icon-cancel.svg";
import { useSelector, useDispatch } from "react-redux";
import { deleteListingByID } from "../../../app/features/store/storeSlice";
import axios from "axios";
import { toast } from "react-toastify";
import $ from "jquery";
import { apiBaseUrl } from "../../config/Config";

const DeleteListingModal = (props) => {
  const { userToken } = useSelector((state) => state.store);
  const dispatch = useDispatch();

  const handleModalClose = () => {
    $("#delete-listing-modal").modal("hide");
  };

  const deleteItem = (itemId) => {
    const config = {
      headers: {
        "x-auth-token": userToken,
      },
    };
    axios
      .delete(`${apiBaseUrl}/api/listing/product/${itemId}`, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          dispatch(deleteListingByID(res.data.data.listItem));
          handleModalClose();
        }
      })
      .catch((err) => {
        toast("Internal server error.");
      });
  };
  return (
    <div
      className="modal"
      id="delete-listing-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="deleteListingModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="delete-listing-modal">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={IconCancel} alt="IconCancel" />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="body-modal">
                <div className="mt-1">
                  <div className="heading-modal">
                    Are you sure you want to delete?
                  </div>
                </div>

                <div className="mt-1 mb-3 w-100 m-auto d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-cancel"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="btn-delete ml-3"
                    onClick={() => deleteItem(props.itemId)}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteListingModal;
