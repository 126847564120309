import React, { useRef } from "react";
import icon from "../../Assets/images/chat/icon.svg";
import attachfile from "../../Assets/images/chat/attachfile.svg";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apiBaseUrl, imgBaseUrl } from "../config/Config";
import { error } from "jquery";
import { v4 as uuidv4 } from 'uuid';
import Loader from "../Loader/Loader";


const ChatMessages = ({
  conversationId,
  itemId,
  userId,
  setMessageStatusId,
}) => {
  const [messageList, setMessageList] = useState([]);
  const [recentMessage, setRecentMessage] = useState(null);
  const [loadData, setLoadData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [isLoad, setIsLoad] = useState(true);
  const [loadMore, isLoadMore] = useState(false)
  const { userToken, storeInfo } = useSelector((state) => state.store);
  const ScrolRef = useRef();
  const socket = window.globalSocket;
  const config = {
    headers: {
      "x-auth-token": userToken,
    },
  };
  const validationSchema = Yup.object({
    textdata: Yup.string(),
  });

  const scrollToBottom = () => {
    if (ScrolRef.current) {
      ScrolRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const apiUrl = `${apiBaseUrl}/api/message/store-message-list/${conversationId}?page=${page}`;
    axios.get(apiUrl, config).then((res) => {
      setIsLoading(false);
      // setMessageList([...messageList, ...res?.data?.data.messageData]);
      setMessageList(res?.data?.data.messageData);
      setLoading(false);
      if (res.data.meta.page === page) {
        setIsLoad(false);
      } else {
        setPage(res.data.meta.page);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId, loadData]);

  useEffect(() => {
    socket?.on("recive-message", (data) => {
      if (conversationId === data.conversationId) {
        setRecentMessage(data);
      } else {
        if (data.conversationId) {
          setMessageStatusId(data.conversationId);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  useEffect(() => {
    if (recentMessage) {
      setMessageList((messageList) => [recentMessage, ...messageList]);
      scrollToBottom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentMessage]);

  useEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageList]);
  const formik = useFormik({
    initialValues: {
      textdata: "",
      fileInput: [],
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      let imagesArray = [];
      if (values.fileInput.length > 0 && values.fileInput.length <= 5) {
        const formData = new FormData();
        for (const key of Object.keys(values.fileInput)) {
          formData.append("images", values.fileInput[key]);
        }
        const response = await axios.post(
          `${apiBaseUrl}/api/message/storeMessage-file-upload`,
          formData,
          config
        );
        imagesArray = response.data.images;
      } else if (values.fileInput.length > 5) {
        alert("Length greater, cannot upload more than five images");
        return false;
      }
      if (values.fileInput.length === 0 && values.textdata.trim() === "") {
        alert("Text or files cannot be empty");
        return false;
      }
      const timestamp = new Date();
      const message = {
        message: values.textdata,
        senderId: storeInfo._id,
        to: userId,
        itemid: itemId,
        type: imagesArray.length > 0 ? "image" : "text",
        images: imagesArray,
        timestamp: timestamp,
      };
      setMessageList((messageList) => [message, ...messageList]);
      const messagedata = {
        conversationId: conversationId,
        itemid: itemId,
        message: values.textdata,
        images: imagesArray,
        type: imagesArray.length > 0 ? "image" : "text",
        to: userId,
        timestamp: timestamp,
      };

      socket.emit("send-message-to-user", messagedata);
      resetForm();
      scrollToBottom();
      socket.off("send-message-to-user");
    },
  });

  return (
    <div className="chat-conversation">
      <div>
        <div className="chat-conversation-content">
          {isLoading ? (
            // Display a loading indicator
            <Loader/>
          ) : // Render messages when not loading
            <>
              {messageList.length > 0 ? <>
                {
                  messageList.toReversed().map((message, index) => {
                    const isSender = message.senderId === storeInfo._id;
                    return (
                      <div>
                        <div
                          key={index}
                          className={`w-100 d-flex justify-content-${isSender ? "end" : "start"
                            }`}
                        >
                          <div
                            className={`${isSender ? "chat-sender-lable" : "chat-response-lable"
                              } p-1 m-1`}
                          >
                            {message.type === "text" ? (<label className="p-1 d-flex">{message.message}</label>) :
                              <div className="chat-sender-images mr-1">
                                <div className="image-container d-block">
                                  <div className="image-group mt-1 d-flex justify-content-start align-items-center">
                                    {message?.images.slice(0, 2).map((file, index) => (
                                      <div key={index} className="image-wrapper">
                                        <img
                                          className="img-tagss"
                                          src={`${imgBaseUrl}/messages/small_${file}`}
                                          alt={`tag-pic-${index}`}
                                          loading="lazy"
                                        />
                                      </div>
                                    ))}
                                  </div>
                                  {message?.images.slice(2, 4).length > 0 && (
                                    <div className="image-group  d-flex justify-content-center align-items-center">
                                      {message?.images.slice(2, 4).map((file, index) => (
                                        <div key={index} className="image-wrapper ">
                                          <img
                                            className="img-tagss"
                                            src={`${imgBaseUrl}/messages/small_${file}`}
                                            alt={`tag-pic-${index + 2}`}
                                            loading="lazy"
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                  {message.images.length > 4 && (
                                    <div className="overlay-text">{message.images.length - 4}+</div>
                                  )}
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    );
                  })
                }</>
                : (
                  <p>No messages to show</p>
                )}
            </>}
          <div ref={ScrolRef} />
        </div>
        <div className="pl-3 mt-3 d-flex  flex-column flex-md-row">
          <form onSubmit={formik.handleSubmit} className="w-100">
            <div className="form-row align-items-center d-flex flex-row flex-nowrap">
              <div className=" textarea-width">
                <textarea
                  className="w-75 ml-2 display-1 form-control input-with-shadow"
                  placeholder="Message..."
                  name="textdata"
                  value={formik.values.textdata}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  rows="1"
                  cols="17"
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      formik.handleSubmit();
                    }
                  }}
                />
              </div>
              <div className="ml-3 mr-3">
                <input
                  name="fileInput"
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onClick={(event) => { event.currentTarget.value = null; }}
                  onChange = {(event) => {
                    const files = Array.from(event.currentTarget.files);
                    formik.setFieldValue("fileInput", files);
                  }}
                  onBlur={formik.handleBlur}
                  multiple
                />
                <div className="d-flex">
                  <label htmlFor="fileInput" className="custom-file-upload mt-2">
                    <img src={attachfile} alt="attach_file" width="24px" height="40px" />
                  </label>
                </div>
              </div>
              <div className="sender-button w-25  mr-3  mt-md-0 ">
                <button type="submit" className="btn">
                  <img src={icon} alt="" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChatMessages;
