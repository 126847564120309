import React, { useEffect, useState } from "react";
import IconCancel from "../../../Assets/images/icons/icon-cancel.svg";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl } from "../../config/Config";
import { useSelector, useDispatch } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import MapView from "../../MapView/MapView";
import { toast } from "react-toastify";
import { addMapAddress } from "../../../app/features/store/storeSlice";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import currentLocations from "../../../Assets/images/icons/currentLocation.svg"
import { GoogleApiWrapper } from 'google-maps-react';
const ChangeAddressModal = ({ index }) => {
    const { storeInfo, userToken } = useSelector((state) => state.store);
    const [address, setAddress] = useState('');
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [searchLocation, setSearchLocation] = useState({});
    const [mapAddress, setMapAddress] = useState("");
    const { adressError, setAddressError } = useState(false);
    const [addressErrorMessage, setAddressErrorMessage] = useState("");

    const handleModalClose = () => {
        $("#map-location-modal").modal("hide");
      }
    const getCurrentLocation = async (e) => {
        e.preventDefault();
        setAddress('');
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    setSearchLocation({ lat: latitude, lng: longitude });
                    try {
                        const response = await fetch(
                            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyA5dpHg91sA_-H5ZYv_4aavdYIDHBLDTZU`
                        );
                        if (!response.ok) {
                            throw new Error('Error fetching current location address');
                        }
                        const data = await response.json();
                        const currentAddress = data.results[0]?.formatted_address;
                        setMapAddress(currentAddress)
                        setAddress(currentAddress);
                    } catch (error) {
                    }
                },
                (error) => {
                    setAddress('Error getting current location');
                }
            );
        }
    };
    const dispatch = useDispatch();

    const handlePlaceSelect = async (address, placeId) => {
        setAddress(address);
        try {
            const results = await geocodeByAddress(address);
            const latLng = await getLatLng(results?.[0]);
            setSearchLocation(latLng);
            setSelectedPlace(results?.[0]);
            setMapAddress(results[0]?.formatted_address);
        } catch (error) {
            console.error('Error selecting place', error);
        }
    };

     const handleOnSubmit = (e) => {
        e.preventDefault();
        if (searchLocation['lat'] && searchLocation['lng'] && mapAddress !== "") {
            const config = {
                headers: {
                    "x-auth-token": userToken 
                },};
            const locationDetails= { mapAddress: mapAddress, mapLocation:{ type: "Point", coordinates:[ searchLocation['lng'],searchLocation['lat']] }}
            axios
                .put(`${apiBaseUrl}/api/store/location`, locationDetails, config)
                .then((res) => {
                    if (res.data.meta.statusCode === 200) {
                           if(res.data.data){
                          dispatch(addMapAddress(res.data.data))
                           }
                           handleModalClose();
                            }
                })
                .catch((err) => {
                    if (err.response.data.meta.statusCode === 400) {
                        setAddressError(true);
                        setAddressErrorMessage(err.response.data.meta.message);
                        setTimeout(() => {
                            setAddressError(false);
                            setAddressErrorMessage("");
                        }, 5000);
                    }

                    if (err.response.data.meta.statusCode === 401) {
                        setAddressError(true);
                        setAddressErrorMessage(err.response.data.meta.message);
                        setTimeout(() => {
                            setAddressError(false);
                            setAddressErrorMessage("");
                        }, 5000);
                    }
                })
        }
        else {
            toast.error("Please select address location");
        }
    };

    useEffect(()=>{
        if(storeInfo.isMapAddress){
            setSearchLocation({lat: storeInfo?.location?.[1],  lng: storeInfo?.location?.[0]});
            setAddress(storeInfo.mapAddress)
            setMapAddress(storeInfo.mapAddress)
        }
    },[])

    return (
        <div
            className="modal"
            id="map-location-modal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="AddressModal"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="map-location-modal">
                    <div className="modal-content border-0">
                        <div className="modal-header border-0">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">
                                    <img src={IconCancel} alt="IconCancel" />
                                </span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="body-modal">
                                <div className="mt-1">
                                    <div className="heading-modal">{storeInfo.isMapAddress ? 'Change Location' : 'Add Location'}</div>
                                </div>
                                <form onSubmit={handleOnSubmit}>
                                    <div className="map-current-location-card">
                                        <PlacesAutocomplete
                                            value={address}
                                            onChange={setAddress}
                                            onSelect={handlePlaceSelect}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <div className='d-flex justify-content-start'>
                                                        <div className="map-location-search-container m-1">
                                                            <input
                                                                {...getInputProps({
                                                                    placeholder: 'Search  location',
                                                                    className: 'location-search-input',
                                                                })}
                                                            />
                                                        </div>
                                                        <div>
                                                            <div className="clear-button mt-2" onClick={(e) => getCurrentLocation(e)}>
                                                                <img src={currentLocations} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map((suggestion) => (
                                                            <div {...getSuggestionItemProps(suggestion)}>
                                                                {suggestion.description}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                        <div className="map-height">
                                            <MapView lat={searchLocation?.lat} lng={searchLocation?.lng} mapAddress={mapAddress} zoom={15} width={'59vw'} height={'55vh'} />
                                        </div>
                                    </div>
                                    <div className="mt-1 mb-3 d-flex justify-content-center">
                                        <button type="submit" className="btn-sign-in w-100">
                                            {storeInfo.isMapAddress?'Update':'Save'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyA5dpHg91sA_-H5ZYv_4aavdYIDHBLDTZU',
    libraries: ['places'],
})(ChangeAddressModal);
