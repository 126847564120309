import React, { useState } from "react";
import IconCancel from "../../../Assets/images/icons/icon-cancel.svg";
import ItemPreview from "../../../Assets/images/icons/dashboard-icons/item-preview-modal.svg";
import ItemPreviewadd from "../../../Assets/images/icons/dashboard-icons/item-preview-modal-add.svg";
import CancelPreview from "../../../Assets/images/icons/dashboard-icons/icon-cancel-preview.svg";
import { updateBanner } from "../../../app/features/store/storeSlice";
import $ from "jquery";
import { apiBaseUrl } from "../../config/Config";
import axios from "axios"
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from "react-redux";

const MAX_LENGTH = 5;

const ChangeBannerModal = () => {
  const [selectedFile, setSelectedFile] = useState([]);
  const [previewFile, setPreviewFile] = useState([]);
  const [BannnerError, setBannerError] = useState(false);
  const [bannerErrorMessage, setBannerErrorMessage] = useState("");
  const { userToken, storeInfo} = useSelector((state) => state.store);
  const dispatch = useDispatch();

  const handleModalClose = () => {
    $("#banner-modal").modal("hide");
  };
  const onSelectFile = (e) => {
    if (e.target.files) {
      const fileArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      if (fileArray.length > MAX_LENGTH) {
        e.preventDefault();
        return;
      }
      setSelectedFile(e.target.files);
      setPreviewFile((prevImages) => prevImages.concat(fileArray));
      Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));
    }
  };

  const submitBannerForm = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "x-auth-token": userToken,
      },
    };
    try {
      const formData = new FormData();
      for (const key of Object.keys(selectedFile)) {
        formData.append("banner", selectedFile[key]);
      };
      axios
        .put(`${apiBaseUrl}/api/store/upload-banner`, formData, config)
        .then((res) => {
          if (res.data.meta.statusCode === 200) {
            dispatch(updateBanner(res.data.data.banner))
            setSelectedFile([])
            setPreviewFile([])
            handleModalClose();
          }
        })
        .catch((err) => {
          if (err.response.data.meta.statusCode === 400) {
            setBannerError(true);
            setBannerErrorMessage(err.response.data.meta.message);
            setTimeout(() => {
              setBannerError(false);
              setBannerErrorMessage("");
            }, 5000);
          }

          if (err.response.data.meta.statusCode === 401) {
            setBannerError(true);
            setBannerErrorMessage(err.response.data.meta.message);
            setTimeout(() => {
              setBannerError(false);
              setBannerErrorMessage("");
            }, 5000);
          }
        });
    } catch (err) {
      return err;
    }
  };

  const handleSubmit= (e)=>{
    e.preventDefault();
    let bannerCount = selectedFile.length + storeInfo.banner.length;
  if(bannerCount > 0  && bannerCount  <= 5){
    submitBannerForm(e);
  }else{
     toast.error("Banner can not more then 5!")
   }}

  const deleteHandler = (e, image, index) => {
    e.preventDefault();
    setPreviewFile(previewFile.filter((e) => e !== image));
    URL.revokeObjectURL(image);
    let dummyFiles = [];
      for (let i = 0; i < selectedFile.length; i++) {
        if(i !== index){
          dummyFiles.push(selectedFile[i]);
        }
      }
    setSelectedFile(dummyFiles)
  };

  const renderPhotos = (source) => {
    return source.map((photo, index) => {
      return (
        <>
          <div className="position-relative">
            <div
              role="button"
              className="mr-1 d-flex justify-content-end"
              onClick={(e) => deleteHandler(e, photo, index)}
            >
              <img
                className="position-absolute"
                src={CancelPreview}
                alt="CancelPreview"
              />
            </div>
            <img
              className="itemPreview mr-1"
              src={photo}
              key={photo}
              alt="itemPreview"
            />
          </div>
        </>
      );
    });
  };

  return (
    <div
      className="modal"
      id="banner-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="bannerModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="banner-modal">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={IconCancel} alt="IconCancel" />
                </span>
              </button>
            </div>
            <div className="modal-body">
            <form onSubmit={(e)=>handleSubmit(e)}>
              <div className="body-modal">
                <div className="mt-1">
                  <div className="heading-modal">{storeInfo.banner.length>0?'Change Banner!':'Add Banner!'}</div>
                </div>
                <div className="mt-3">
                  <div className="w-100 d-flex flex-row flex-wrap">
                    {selectedFile.length > 0 ? (
                      <>{renderPhotos(previewFile)}</>
                    ) : (
                      <>
                        <img
                          className="itemPreview mr-2 mb-2"
                          src={ItemPreview}
                          alt="ItemPreview"
                        />
                        <img
                          className="itemPreview mr-2 mb-2"
                          src={ItemPreview}
                          alt="ItemPreview"
                        />
                        <img
                          className="itemPreview mr-2 mb-2"
                          src={ItemPreview}
                          alt="ItemPreview"
                        />
                        <img
                          className="itemPreview mr-2 mb-2"
                          src={ItemPreview}
                          alt="ItemPreview"
                        />
                        <img
                          className="itemPreview mr-2 mb-2"
                          src={ItemPreview}
                          alt="ItemPreview"
                        />
                      </>
                    )}
                    <label htmlFor="uploadProductImages">
                      <div role="button">
                        <img
                          className="itemPreview mr-2 mb-2"
                          src={ItemPreviewadd}
                          alt="ItemPreviewadd"
                        />
                      </div>
                      <input
                        type="file"
                        id="uploadProductImages"
                        multiple
                        hidden
                        accept="image/png, image/jpeg"
                        onChange={onSelectFile}
                      />
                    </label>
                  </div>
                </div>
                <div className="mt-4 mb-3">
                  <button type="submit" className="btn-sign-in w-100" disabled={selectedFile.length>0?false:true}>
                    {storeInfo.banner.length>0?'Save':'Add'}
                  </button>
                </div>
              </div>
            </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeBannerModal;
