import React, { useState } from "react";
import IconCancel from "../../../Assets/images/icons/icon-cancel.svg";
import { useSelector, useDispatch } from "react-redux";
import { updateEmail } from "../../../app/features/store/storeSlice";
import Joi from "joi";
import axios from "axios";
import OtpInput from "react-otp-input";
import $ from "jquery";
import { apiBaseUrl } from "../../config/Config";
import { emailRegex } from "../../../shared/validators";

const AddEmailModal = () => {
  const { userToken } = useSelector((state) => state.store);
  const dispatch = useDispatch();

  const [storeEmail, setStoreEmail] = useState({
    storeEmail: "",
  });
  const [errors, setErrors] = useState({
    storeEmail: "",
  });
  const [storeEmailError, setStoreEmailError] = useState(false);
  const [storeEmailErrorMessage, setStoreEmailErrorMessage] = useState("");

  const [storeEmailChangeOtp, setStoreEmailChangeOtp] = useState(false);
  const [OTP, setOTP] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const [otpToken, setOtpToken] = useState(null);

  const handleChange = (OTP) => {
    setOTP(OTP);
  };

  const handleModalClose = () => {
    $("#add-email-modal").modal("hide");
  };
  const handleInputOnChange = (e) => {
    const { name, value } = e.target;
    setStoreEmail({
      ...storeEmail,
      [name]: value,
    });
    setErrors({});
  };

  var schema = Joi.object({
    storeEmail: Joi.string().regex(emailRegex).required().label("storeEmail").messages({
      "string.empty": "Email cannot be empty",
      "string.pattern.base": "Please enter valid Email"
    })
  });
  
  const formValidation = () => {
    const result = schema.validate(storeEmail)
    if (!result.error) return null;
    let errors = {};
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formErrors = formValidation();
    if (formErrors) {
      setErrors({ ...formErrors });
    } else {
      setErrors({});
      submitStoreEmail(storeEmail.storeEmail);
    }
  };

  const submitStoreEmail = (email) => {
    const config = {
      headers: {
        "x-auth-token": userToken,
      },
    };
    axios
      .put(`${apiBaseUrl}/api/store/change-email`, { email: email }, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          setStoreEmailChangeOtp(true);
          setOtpToken(res.data.data.token);
          setStoreEmail({});
        }
      })
      .catch((err) => {
        if (err.response.data.meta.statusCode === 400) {
          setStoreEmailError(true);
          setStoreEmailErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setStoreEmailError(false);
            setStoreEmailErrorMessage("");
          }, 5000);
        }
        if (err.response.data.meta.statusCode === 401) {
          setStoreEmailError(true);
          setStoreEmailErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setStoreEmailError(false);
            setStoreEmailErrorMessage("");
          }, 5000);
        }
      });
  };

  const handleOnSubmitOtp = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "x-auth-otp": otpToken,
      },
    };
    const otpParse = Number(OTP);

    axios
      .put(`${apiBaseUrl}/api/store/verify-change-email`, { otp: otpParse }, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          dispatch(updateEmail(res.data.data.email));
          setStoreEmailChangeOtp(false);
          setOTP("");
          handleModalClose();
        }
      })
      .catch((err) => {
        if (err.response.data.meta.statusCode === 400) {
          setOtpError(true);
          setOtpErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setOtpError(false);
            setOtpErrorMessage("");
          }, 5000);
        }
        if (err.response.data.meta.statusCode === 401) {
          setOtpError(true);
          setOtpErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setOtpError(false);
            setOtpErrorMessage("");
          }, 5000);
        }
      });
  };

  return (
    <div
      className="modal"
      id="add-email-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="addEmailModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="add-email-modal">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={IconCancel} alt="IconCancel" />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="body-modal">
                {storeEmailChangeOtp === false && (
                  <>
                    <form onSubmit={handleOnSubmit}>
                      <div className="mt-1">
                        <div className="heading-modal">Change Email!</div>
                      </div>
                      {storeEmailError && (
                        <div
                          className="error-text text-center alert-danger"
                          role="alert"
                        >
                          {storeEmailErrorMessage}
                        </div>
                      )}
                      <div className="mt-3">
                        <div className="field-heading">Email</div>
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            value={storeEmail.storeEmail || ""}
                            name="storeEmail"
                            onChange={handleInputOnChange}
                          />
                          {errors.storeEmail && (
                            <div className="error-text">
                              {errors.storeEmail}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mt-1 mb-3">
                        <button type="submit" className="btn-sign-in w-100">
                          Save Change
                        </button>
                      </div>
                    </form>
                  </>
                )}
                {storeEmailChangeOtp === true && (
                  <>
                    <form onSubmit={handleOnSubmitOtp}>
                      <div className="mt-1">
                        <div className="heading-modal">
                          Otp sent on {storeEmail.storeEmail}!
                        </div>
                      </div>
                      {otpError && (
                        <div
                          className="error-text text-center alert-danger"
                          role="alert"
                        >
                          {otpErrorMessage}
                        </div>
                      )}
                      <div className="mt-3">
                        <div className="field-heading">OTP</div>
                        <div className="mt-1">
                          <OtpInput
                            onChange={handleChange}
                            value={OTP}
                            inputStyle="otpinputStyle"
                            numInputs={6}
                            separator={<span></span>}
                          />
                        </div>
                        <div className="d-flex justify-content-between mt-2 mb-3">
                          <div className="otp-resend">Didn't receive OTP</div>
                          <div role="button" className="otp-resend-link">
                            Resend
                          </div>
                        </div>
                      </div>
                      <div className="mt-1 mb-3">
                        <button type="submit" className="btn-sign-in w-100">
                          Save Change
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEmailModal;
