import React, { useEffect, useState } from "react";
import IconCancel from "../../../Assets/images/icons/icon-cancel.svg";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl } from "../../config/Config";
import { addAddress } from "../../../app/features/store/storeSlice";
import { useSelector, useDispatch } from "react-redux";
import Joi from "joi";
import { completeaddressregex ,postalzipcdoregex, landmarkregex, cityregex } from "../../../shared/validators";
const ChangeAddressModal = ({index}) => {
  const {storeInfo,userToken} = useSelector ((state)=>state.store);
  const [addressError, setAddressError] = useState(false);
  const [addressErrorMessage, setAddressErrorMessage] = useState("");
  const [ isSetData, setIsSetData ] = useState(false);
  const [errors, setErrors] = useState({
    province: "",
    city: "",
    postalOrZip:"",
    location: "",
    storeAddress: "",
  });
  const [values, setValues] = useState ({
       province:storeInfo.address[index]?.province,
       city:storeInfo.address[index]?.city,
       postalOrZip: storeInfo.address[index]?.postalOrZip,
       location:storeInfo.address[index]?.location,
       storeAddress:storeInfo.address[index]?.storeAddress,
     })
    
  const handleModalClose = () => {
    $("#address-modal").modal("hide");
  };
  const dispatch = useDispatch();
  const handleInputOnChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  var schema = Joi.object({
    province: Joi.string().required().label("province").messages({
      "string.empty": "Province cannot be empty",
    }),
    city: Joi.string().regex(cityregex).min(3).max(40).required().label("city").messages({
      "string.empty": "City name cannot be empty",
      "string.pattern.base": "Please enter valid City name",
      "string.min": "City name must be at least 3 characters",
      "string.max": "City name must be no more than 40 characters"
    }),
    postalOrZip: Joi.string().regex(postalzipcdoregex).min(2).max(8).required().label("postalOrZip").messages({
      "string.empty": "Postal code cannot be empty",
      "string.pattern.base": "Please enter valid Postal code",
      "string.min": "Postal code must be at least 3 characters",
      "string.max": "Postal code must be no more than 10 characters"
    }),
    location: Joi.string().min(3).max(200).regex(landmarkregex).required().label("location").messages({
      "string.empty": "Location cannot be empty",
      "string.pattern.base": "Please enter valid location",
      "string.min": "Location must be at least 3 characters",
      "string.max": "Location must be no more than 200 characters"
    }),
    storeAddress: Joi.string().min(3).max(200).regex(completeaddressregex).required().label("storeAddress").messages({
      "string.empty": "Store Address cannot be empty",
      "string.pattern.base": "Please enter valid Store address",
      "string.min": "Store Address must be at least 3 characters",
      "string.max": "Store Address must be no more than 200 characters"
    }),
  });
  const formValidation = () => {
    const result = schema.validate(values);
    if (!result.error) return null;
    let errors = {};
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  }
  useEffect(()=>{
    setValues({
      province:storeInfo.address[index]?.province,
      city:storeInfo.address[index]?.city,
      postalOrZip: storeInfo.address[index]?.postalOrZip,
      location:storeInfo.address[index]?.location,storeAddress:storeInfo.address[index]?.storeAddress,
    })
  },[index])
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formErrors = formValidation();
    if(formErrors){
      setErrors({ ...formErrors });
    }
     else{
    let { province, city, postalOrZip, location, storeAddress } = values;
    let indexNumber = index;

    submitAddress({
      province,
      city,
      postalOrZip,
      location,
      storeAddress,
      indexNumber,
    });
   }
  }
  const submitAddress = (values) => {
    const config = {
      headers: {
        "x-auth-token": userToken,
      },
    };
    axios
      .put(`${apiBaseUrl}/api/store/changeaddress-specific-index`, values, config)
      .then((res) => {
        if (res?.data?.meta?.statusCode === 200) {
          dispatch(addAddress(res.data.data.address));
          setIsSetData(!isSetData)
          handleModalClose();
        }
      })
      .catch((err) => {
        if (err.response.data.meta.statusCode === 400) {
          setAddressError(true);
          setAddressErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setAddressError(false);
            setAddressErrorMessage("");
          }, 5000);
        }

        if (err.response.data.meta.statusCode === 401) {
          setAddressError(true);
          setAddressErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setAddressError(false);
            setAddressErrorMessage("");
          }, 5000);
        }
      });
  };
  return (
    <div
      className="modal"
      id="address-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="AddressModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="address-modal">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={IconCancel} alt="IconCancel" />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="body-modal">
                <div className="mt-1">
                  <div className="heading-modal">Change Address!</div>
                </div>
                <form onSubmit={handleOnSubmit}>
                {addressError && (
                    <div
                      className="error-text text-center alert-danger"
                      role="alert"
                    >
                      {addressErrorMessage}
                    </div>
                  )}
                <div className="d-flex justify-content-between mt-2">
                  <div className="mt-2 w-100 mr-1">
                    <div className="field-heading">Province</div>
                    <div className="form-group">
                       <select
                          className="custom-select form-control h-100"
                          id="inputGroupSelect01"
                          value={values.province || ""}
                          name="province"
                          onChange={handleInputOnChange}
                        >
                          <option value="">Select Province</option>
                          <option value="Sindh">Sindh</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Balochistan">Balochistan</option>
                          <option value="Islamabad">
                            Islambad Capital Territory
                          </option>
                          <option value="Khyber-Pakhtunkhwa">
                            Khyber Pakhtunkhwa
                          </option>
                          <option value="Gilgit-Baltistan">
                            Gilgit Baltistan
                          </option>
                          <option value="Azad-Kashmir">Azad Kashmir</option>
                        </select>
                        {errors.province && (
                          <div className="error-text">
                            {errors.province}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="mt-2 w-100 mr-1">
                    <div className="field-heading">City</div>
                    <div className="form-group">
                      <input
                        type="name"
                        className="form-control"
                        placeholder="Please Select Your City"
                        name="city"
                        value = {values.city}
                        onChange={handleInputOnChange}
                      />
                    </div>
                    {errors.city && (
                          <div className="error-text">{ errors.city }</div>
                        )}
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <div className="w-100 mr-1">
                    <div className="field-heading">Postal/Zip Code</div>
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="zipcode like 1000"
                        name = "postalOrZip"
                        value = {values.postalOrZip}
                        onChange={handleInputOnChange}
                        
                      />
                    </div>
                    {errors.postalOrZip && (
                          <div className="error-text">
                            { errors.postalOrZip }
                          </div>
                        )}
                  </div>
                  <div className="w-100 mr-1">
                    <div className="field-heading">Landmark</div>
                    <div className="form-group">
                      <input
                        type="name"
                        className="form-control"
                        placeholder="Near Train Station"
                        name= "location"
                        value={values.location}
                        onChange={handleInputOnChange}
                      />
                    </div>
                    {errors.location && (
                          <div className="error-text">
                            {errors.location}
                          </div>
                        )}
                  </div>
                </div>

                <div className="mt-2">
                  <div className="field-heading">Store Address</div>
                  <div className="form-group">
                    <textarea
                      className="form-control-textarea"
                      name = "storeAddress"
                      placeholder="House Number, Building , Area, Street"
                      rows={3}
                      defaultValue={""}
                      value={values.storeAddress}
                      onChange={handleInputOnChange}
                    />
                  </div>
                  {errors.storeAddress && (
                          <div className="error-text">
                            {errors.storeAddress}
                          </div>
                        )}
                </div>
                <div className="mt-1 mb-3 d-flex justify-content-center">
                  <button type="submit" className="btn-sign-in w-50">
                    Save
                  </button>
                </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeAddressModal;
