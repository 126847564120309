import React, { useEffect, useState } from "react";
import Joi from "joi";
import idCardPreview from "../../Assets/images/card-preview.png";
import axios from "axios";
import { apiBaseUrl } from "../config/Config";
import { cnicRegex,fullname } from "../../shared/validators";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
 
const IdentityForm = ({ stepCount, setStepCount }) => {
  const [values, setValues] = useState({
    firstAndLastName: "",
    cnic: "",
  });
  const [errors, setErrors] = useState({
    firstAndLastName: "",
    cnic: "",
  });
  const [identityError, setIdentityError] = useState(false);
  const [identityErrorMessage, setIdentityErrorMessage] = useState("");
  const [cardImages, setCardImages] = useState([]);

  const [IdFront, setIdFront] = useState("");
  const [previewFront, setPreviewFront] = useState();
  const [IdBack, setIdBack] = useState("");
  const [previewBack, setPreviewBack] = useState();

  const handleInputOnChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setErrors({});
  };

  useEffect(() => {
    if (!IdFront) {
      setPreviewFront(undefined);
      return;
    }
    const objectUrlFront = URL.createObjectURL(IdFront);
    setPreviewFront(objectUrlFront);
    return () => URL.revokeObjectURL(objectUrlFront);
  }, [IdFront]);

  useEffect(() => {
    if (!IdBack) {
      setPreviewBack(undefined);
      return;
    }
    const objectUrlBack = URL.createObjectURL(IdBack);
    setPreviewBack(objectUrlBack);
    return () => URL.revokeObjectURL(objectUrlBack);
  }, [IdBack]);
  const onSelectFront = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setIdFront(undefined);
      return;
    }
    setIdFront(e.target.files[0]);
    setCardImages([...cardImages, e.target.files[0]]);
  };

  const onSelectBack = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setIdBack(undefined);
      return;
    }
    setIdBack(e.target.files[0]);
    setCardImages([...cardImages, e.target.files[0]]);
  };
  var schema = Joi.object({
    firstAndLastName: Joi.string().regex(fullname).min(3).max(50).required().label("firstAndLastName").messages({
      "string.empty": "Name cannot be empty",
      "string.pattern.base": "Please enter correct Name",
      "string.min": "Name must be at least 3 characters",
      "string.max":"Name  must be no more than 50 characters"
    }),
    cnic: Joi.string().regex(cnicRegex).length(13).required().label("cnic").messages({
      "string.empty": "CNIC number cannot be empty",
      "string.pattern.base": "Please enter correct CNIC",
      "string.length": "CNIC number must be 13 digits"
    }),
  });
  const formValidation = () => {
    const result = schema.validate(values);
    if (!result.error) return null;
    let errors = {};
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formErrors = formValidation();
    if (formErrors) {
      setErrors({ ...formErrors });
      console.log(formErrors);
    }  else if (IdFront === "" || IdBack === "") {
        toast.error("ID card images are required")
      } else {
      setErrors({});
      submitIdentityForm({
        ...values,
      });
    }
  };
  const submitIdentityForm = (item) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "x-auth-token": sessionStorage.getItem("token"),
      },
    };
    try {
      const formData = new FormData();
      for (const key of Object.keys(cardImages)) {
        formData.append("cardImages", cardImages[key]);
      }
      formData.append("firstAndLastName", values.firstAndLastName);
      formData.append("cnic", values.cnic);
      axios
        .put(`${apiBaseUrl}/api/store/card-identity`, formData, config)
        .then((res) => {
          if (res.data.meta.statusCode === 200) {
            setStepCount(stepCount + 1);
          }
        })
        .catch((err) => {
          if (err.response.data.meta.statusCode === 400) {
            setIdentityError(true);
            toast.error(err.response.data.meta.message)
            setTimeout(() => {
              setIdentityError(false);
              setIdentityErrorMessage("");
            }, 5000);
          }

          if (err.response.data.meta.statusCode === 401) {
            setIdentityError(true);
            setIdentityErrorMessage(err.response.data.meta.message);
            setTimeout(() => {
              setIdentityError(false);
              setIdentityErrorMessage("");
            }, 5000);
          }
        });
    } catch (err) {
      return err;
    }
  };
  return (
    <form onSubmit={handleOnSubmit}>
      {identityError && (
        <div className="mt-2 error-text text-center alert-danger" role="alert">
          {identityErrorMessage}
        </div>
      )}
      <div className="d-flex justify-content-between direction_on_small mt-4">
        <div className="mt-2 w-100 mr-4">
          <div className="field-heading">First And Last Name</div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="First And Last Name"
              value={values.firstAndLastName || ""}
              name="firstAndLastName"
              onChange={handleInputOnChange}
            />
            {errors.firstAndLastName && (
              <div className="error-text">{errors.firstAndLastName}</div>
            )}
          </div>
        </div>
        <div className="mt-2 w-100 margins">
          <div className="field-heading">CNIC Number</div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="CNIC Number (_____-_______-_)"
              value={values.cnic || ""}
              name="cnic"
              onChange={handleInputOnChange}
            />
            {errors.cnic && <div className="error-text">{errors.cnic}</div>}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-2">
        <div className="mt-2 w-100 mr-4">
          <div className="field-heading mb-3">CNIC Front Side</div>
          <div className="form-group">
            {IdFront ? (
              <img
                src={previewFront}
                className="card-preview"
                alt="idCardPreview"
              />
            ) : (
              <img
                src={idCardPreview}
                className="card-preview"
                alt="idCardPreview"
              />
            )}
          </div>
          <div className="d-flex justify-content-end">
            <label htmlFor="uploadFront" className="select-file-button">
              <div role="button">Select File</div>
              <input
                type="file"
                id="uploadFront"
                hidden
                accept="image/png, image/jpeg"
                onChange={onSelectFront}
              />
            </label>
          </div>
        </div>
        <div className="mt-2 w-100 ml-4">
          <div className="field-heading mb-3">CNIC Back Side </div>
          <div className="form-group">
            {IdBack ? (
              <img
                src={previewBack}
                className="card-preview"
                alt="idCardPreview"
              />
            ) : (
              <img
                src={idCardPreview}
                className="card-preview"
                alt="idCardPreview"
              />
            )}
          </div>
          <div className="d-flex justify-content-end">
            <label htmlFor="uploadBack" className="select-file-button">
              <div role="button">Select File</div>
              <input
                type="file"
                id="uploadBack"
                hidden
                accept="image/png, image/jpeg"
                onChange={onSelectBack}
              />
            </label>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div className="w-100 mr-2">
          <button
            type="button"
            className="btn-back w-100"
            onClick={() => setStepCount(stepCount - 1)}
          >
            Back
          </button>
        </div>
        <div className="w-100 ml-2">
          <button type="submit" className="btn-next w-100">
            Next
          </button>
        </div>
      </div>
    </form>
  );
};

export default IdentityForm;
