import "./Assets/scss/sb-admin-2.scss";
import "bootstrap";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Seller/Dashboard";
import AddProduct from "./Pages/Seller/AddProduct";
import PageLayout from "./Components/DashboardComponents/SellerDashboard/PageLayout";
import RegisterSeller from "./Pages/RegisterSeller";
import Menu from "./Pages/Seller/Menu";
import Order from "./Pages/Seller/Order";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./Components/protectedroute/ProtectedRoute";
import ForgetPassword from "./Pages/ForgetPassword";
import Settings from "./Pages/Seller/Settings";
import EditProduct from "./Pages/Seller/EditProduct";
import Unauthorized from "./Pages/Unauthorized";
import PublicRoute from "./Components/publicroute/PublicRoute";
import RegisterOtp from "./Pages/RegisterOtp";
import RegisterDetails from "./Pages/RegisterDetails";
import Otp from "./Pages/Otp";
import ResetPassword from "./Pages/ResetPassword";
import ProductPage from "./Pages/Seller/ProductPage";
import ForgetOtp from "./Pages/ForgetOtp";
import Reviews from "./Pages/Seller/Reviews";
import Chat from "./Pages/Seller/Chat";
import React, { useState ,useEffect} from "react";
import socketConn from "./Pages/Seller/Socket"
import { useSelector } from "react-redux";
import SettingRoute from "./Components/settingroute/SettingRoute";
import axios from "axios"
import { apiBaseUrl } from "./Components/config/Config";
import { storeData } from "./app/features/store/storeActions";

function App() {
  const { userToken } = useSelector((state) => state.store);
  const [socket, setSocket] = useState(()=>socketConn(userToken))

  useEffect(()=>{
    if(userToken){
      setSocket(()=>socketConn(userToken));
      window.globalSocket = socket;
      socket?.connect()
    }
  },[userToken])
  const config = {
    headers: {
      "x-auth-token": userToken,
    },
  };
  useEffect(()=>{
    if(userToken){
      axios.get(`${apiBaseUrl}/api/store/refresh-token`,config)
  .then(response => {
      console.log("Response:", response);
      if (response.data.meta.status) {
       storeData(response.data.data);
 } else {
         // setError(response.data.meta.message);
         
      }
  })
  .catch(error => {
      //errror
  });
    }
  },[])

  useEffect(() => {
   axios.get(`${apiBaseUrl}/api/store/refresh-token`, {
           config,
     })
     .then(response => {
         console.log("Response:", response);
         if (response.data.meta.status) {
          storeData(response.data.data.store);
    } else {
            // setError(response.data.meta.message);
         }
     })
     .catch(error => {
        
     });
 }, []);

  return (
    <div>
      <ToastContainer />

      <Router>
        <div>
          <Routes>
            <Route exect path="/" element={<Login />} />
            <Route exect path="/login" element={<Login />} />
            <Route
              exect
              path="/otp"
              element={<PublicRoute component={Otp} />}
            />
            <Route exect path="/forgot-password" element={<ForgetPassword />} />
                        <Route
              exect
              path="/forgot-otp"
              element={<PublicRoute component={ForgetOtp} />}
            />
            <Route
              exect
              path="/reset-password"
              element={<PublicRoute component={ResetPassword} />}
            />
            <Route exect path="/register-seller" element={<RegisterSeller />} />
            <Route
              exect
              path="/register-otp"
              element={<PublicRoute component={RegisterOtp} />}
            />
            <Route
              exect
              path="/register-details"
              element={<PublicRoute component={RegisterDetails} />}
            />
            <Route
              exect
              path="/dashboard"
              element={
                <PageLayout>
                  <ProtectedRoute component={Dashboard} />
                </PageLayout>
              }
            />
            <Route
              exact
              path="/menu"
              element={
                <PageLayout>
                  <ProtectedRoute component={Menu} />
                </PageLayout>
              }
            />
            <Route
              exact
              path="/reviews"
              element={
                <PageLayout>
                  <ProtectedRoute component={Reviews} />
                </PageLayout>
              }
            />
              <Route
              exact
              path="/chat"
              element={
                <PageLayout>
                  <ProtectedRoute component={Chat} />
                </PageLayout>
              }
            />

            <Route
              exact
              path="/orders"
              element={
                <PageLayout>
                  <ProtectedRoute component={Order} />
                </PageLayout>
              }
            />

            <Route
              exact
              path="/addproduct"
              element={
                <PageLayout>
                  <ProtectedRoute component={AddProduct} />
                </PageLayout>
              }
            />
            <Route
              exact
              path="/product/:id"
              element={
                <PageLayout>
                  <ProtectedRoute component={ProductPage} />
                </PageLayout>
              }
            />
            <Route
              exact
              path="/editproduct/:id"
              element={
                <PageLayout>
                  <ProtectedRoute component={EditProduct} />
                </PageLayout>
              }
            />
            <Route
              exact
              path="/settings"
              element={
                <PageLayout>
                  <SettingRoute component={Settings} />
                </PageLayout>
              }
            />
            <Route
              exect
              path="/page-not-found"
              element={
                <PageLayout>
                  <ProtectedRoute component={Unauthorized} />
                </PageLayout>
              }
            />
            <Route
              exect
              path="*"
              element={<PublicRoute component={Unauthorized} />}
            />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
