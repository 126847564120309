import React, { useState } from "react";
import Joi from "joi";
import axios from "axios";
import { apiBaseUrl } from "../config/Config";
import { arearegex, cityregex, locationregex, addressregex ,postalzipcdoregex} from "../../shared/validators";
const AddressForm = ({ stepCount, setStepCount }) => {
  const [address, setAddress] = useState({
    province: "",
    city: "",
    postalOrZip: "",
    location: "",
    storeAddress: "",
  });
  const [errors, setErrors] = useState({
    province: "",
    city: "",
    postalOrZip: "",
    location: "",
    storeAddress: "",
  });
  const [addressError, setAddressError] = useState(false);
  const [addressErrorMessage, setAddressErrorMessage] = useState("");

  const handleInputOnChange = (e) => {
    const { name, value } = e.target;
    setAddress({
      ...address,
      [name]: value,
    });
    setErrors({});
  };
  var schema = Joi.object({
    province: Joi.string().required().label("province").messages({
      "string.empty": "Province cannot be empty"}),
    city: Joi.string().required().regex(cityregex).min(3).max(40).label("city").messages({
      "string.empty": "City name cannot be empty",
      "string.pattern.base": "Please enter valid City name",
      "string.min": "City name must be at least 3 characters",
      "string.max": "City name must be no more than 40 characters"
    }),
    postalOrZip: Joi.string().min(3).max(10).regex(postalzipcdoregex).required().label("postalOrZip").messages({
      "string.empty": "Postal code cannot be empty",
      "string.pattern.base": "Please enter valid Postal code",
      "string.min": "Postal code must be at least 3 characters",
      "string.max": "Postal code must be no more than 10 characters"
    }),
    location: Joi.string().min(3).max(200).regex(locationregex).required().label("location").messages({
      "string.empty": "Location cannot be empty",
      "string.pattern.base": "Please enter valid location",
      "string.min": "Location must be at least 3 characters",
      "string.max": "Location must be no more than 200 characters"
    }),
    storeAddress: Joi.string().min(3).max(200).regex(addressregex).required().label("storeAddress").messages({
      "string.empty": "Store Address cannot be empty",
      "string.pattern.base": "Please enter valid Store address",
      "string.min": "Store Address must be at least 3 characters",
      "string.max": "Store Address must be no more than 200 characters"
    }),
  });
  const formValidation = () => {
    const result = schema.validate(address);
    if (!result.error) return null;
    let errors = {};
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const fromErrors = formValidation();
    if (fromErrors) {
      setErrors({ ...fromErrors });
    } else {
      setErrors({});
      submitAddress({
        ...address,
      });
    }
  };
  const submitAddress = (address) => {
    const config = {
      headers: {
        "x-auth-token": sessionStorage.getItem("token"),
      },
    };
    axios
      .put(`${apiBaseUrl}/api/store/address`, address, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          setStepCount(stepCount + 1);
        }
      })
      .catch((err) => {
        if (err.response.data.meta.statusCode === 400) {
          setAddressError(true);
          setAddressErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setAddressError(false);
            setAddressErrorMessage("");
          }, 5000);
        }

        if (err.response.data.meta.statusCode === 401) {
          setAddressError(true);
          setAddressErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setAddressError(false);
            setAddressErrorMessage("");
          }, 5000);
        }
      });
  };

  return (
    <form onSubmit={handleOnSubmit}>
      {addressError && (
        <div className="error-text text-center alert-danger" role="alert">
          {addressErrorMessage}
        </div>
      )}
      <div className="d-flex justify-content-between mt-4 direction_on_small">
        <div className="mt-2 w-100 mr-4 margins">
          <div className="field-heading">Province</div>
          <div className="form-group">
            <select
              className="custom-select form-control h-100"
              id="inputGroupSelect01"
              value={address.province || ""}
              name="province"
              onChange={handleInputOnChange}
            >
              <option value="">Select Province</option>
              <option value="Sindh">Sindh</option>
              <option value="Punjab">Punjab</option>
              <option value="Balochistan">Balochistan</option>
              <option value="Islamabad">Islambad Capital Territory</option>
              <option value="Khyber-Pakhtunkhwa">Khyber Pakhtunkhwa</option>
              <option value="Gilgit-Baltistan">Gilgit Baltistan</option>
              <option value="Azad-Kashmir">Azad Kashmir</option>
            </select>
            {errors.province && (
              <div className="error-text">{errors.province}</div>
            )}
          </div>
        </div>
        <div className="mt-2 w-100 margins ">
          <div className="field-heading">City</div>
          <div className="form-group">
            <input
              type="text"
              name="city"
              className="form-control"
              placeholder="City"
              value={address.city || ""}
              onChange={handleInputOnChange}
            />
            {errors.city && (
              <div className="error-text">{errors.city}</div>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between direction_on_small mt-2">
        <div className="mt-2 w-100 mr-4">
          <div className="field-heading"> PostalOrZip</div>
          <div className="form-group">
            <input
              type="text"
              name= "postalOrZip"
              className="form-control"
              placeholder=" postalOrZip,"
              value={address. postalOrZip || ""}
              onChange={handleInputOnChange}
            />
            {errors.postalOrZip && (
              <div className="error-text">{errors.postalOrZip}</div>
            )}
          </div>
        </div>
        <div className="mt-2 w-100 ">
          <div className="field-heading">Location</div>
          <div className="form-group">
            <input
              type="text"
              name="location"
              className="form-control"
              placeholder="Location"
              value={address.location || ""}
              onChange={handleInputOnChange}
            />
            {errors.location && (
              <div className="error-text">{errors.location}</div>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-2 pb-5">
        <div className="mt-2 w-100">
          <div className="field-heading">Store Address</div>
          <div className="form-group">
            <textarea
              className="form-control-textarea"
              name = "storeAddress"
              placeholder="House Number, Building , Area, Street"
              rows={3}
              defaultValue={""}
              value={address.storeAddress}
              onChange={handleInputOnChange}
            />
            {errors.storeAddress && (
              <div className="error-text">
                {errors.storeAddress}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-2 w-100">
        <button type="submit" className="btn-next w-100">
          Next
        </button>
      </div>
    </form>
  );
};

export default AddressForm;
