import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import rightArrow from "../../Assets/images/icons/arrow-circle-right.svg";
import leftArrow from "../../Assets/images/icons/arrow-circle-left.svg";
import StarFilled from "../../Assets/images/icons/icon-star-filled.svg";
import StarEmpty from "../../Assets/images/icons/icon-star-empty.svg";
import BackArrow from "../../Assets/images/icons/dashboard-icons/arrow-back-detail.svg";
import { apiBaseUrl, imgBaseUrl } from "../../Components/config/Config";
import { useSelector } from "react-redux";


function ProductPage() {
  const { storeInfo } = useSelector((state) => state.store);

  const { id } = useParams();
  const [item, setItem] = useState({});
  const [imgIndex, setImageIndex] = useState(0);
  const [colorIndex, setColorIndex] = useState(0);
  const [qtyLimit, setQtyLimit] = useState(0);
  const [subPropertyType, setSubPropertyType] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    axios.get(`${apiBaseUrl}/api/listing/product/${id}`).then((res) => {
      setItem(res.data.data.listing);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTab = (index) => {
    setImageIndex(index);
  };
  const handleColorIndex = (index, color) => {
    setColorIndex(index);
  };
  const handleSubPropertySize = (subQuantity, subType) => {
    setQtyLimit(subQuantity);
    setSubPropertyType(subType);
  };
  const navigateTo = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    navigate("/menu");
  };

  return (
    <>
      <div id="productdetail">
        <section className="productdetailpage">
          <div className="scrollable">
            <div className="details-app">
              <div
                role="button"
                className="back-arrow"
                onClick={() => {
                  navigateTo();
                }}
              >
                <img src={BackArrow} alt="BackArrow" />
              </div>
              {item ? (
                <>
                  <div className="details d-flex justify-content-start">
                    <div className="d-flex flex-column mr-3">
                      <div className="big-img border">
                        {item && item?.images ? (
                          <img
                            className="img-tag"
                            src={`${imgBaseUrl}/listing/medium_${item.images[imgIndex]}`}
                            alt="tag-pic"
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="mt-3">
                        <div className="thumb d-flex">
                          <img src={leftArrow} alt="leftArrow" />
                          {item && item?.images ? (
                            item.images.map((img, index) => (
                              <img
                                key={index}
                                className={
                                  imgIndex === index
                                    ? "active-thumb"
                                    : "thumb-img ml-2"
                                }
                                src={`${imgBaseUrl}/listing/small_${item.images[index]}`}
                                alt="thumb-img"
                                onClick={() => handleTab(index)}
                              />
                            ))
                          ) : (
                            <></>
                          )}
                          <img src={rightArrow} alt="leftArrow" />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex flex-column w-75">
                      <div className="">
                        <div className="d-flex justify-content-between align-items-center">

                          <h2 className="product-title p-0">{item.title}</h2>
                        </div>
                        <div className="d-flex justify-content-start align-item-center">
                          {item.averageRating ? (
                            <>
                              {item.averageRating >= 1 ? (
                                <img
                                  className="mr-1"
                                  src={StarFilled}
                                  alt="StarFilled"
                                />
                              ) : (
                                <>
                                  <img
                                    className="mr-1"
                                    src={StarEmpty}
                                    alt="StarEmpty"
                                  />
                                </>
                              )}
                              {item.averageRating >= 2 ? (
                                <img
                                  className="mr-1"
                                  src={StarFilled}
                                  alt="StarFilled"
                                />
                              ) : (
                                <>
                                  <img
                                    className="mr-1"
                                    src={StarEmpty}
                                    alt="StarEmpty"
                                  />
                                </>
                              )}
                              {item.averageRating >= 3 ? (
                                <img
                                  className="mr-1"
                                  src={StarFilled}
                                  alt="StarFilled"
                                />
                              ) : (
                                <>
                                  <img
                                    className="mr-1"
                                    src={StarEmpty}
                                    alt="StarEmpty"
                                  />
                                </>
                              )}
                              {item.averageRating >= 5 ? (
                                <img
                                  className="mr-1"
                                  src={StarFilled}
                                  alt="StarFilled"
                                />
                              ) : (
                                <>
                                  <img
                                    className="mr-1"
                                    src={StarEmpty}
                                    alt="StarEmpty"
                                  />
                                </>
                              )}
                              {item.averageRating === 5 ? (
                                <img
                                  className="mr-1"
                                  src={StarFilled}
                                  alt="StarFilled"
                                />
                              ) : (
                                <>
                                  <img
                                    className="mr-1"
                                    src={StarEmpty}
                                    alt="StarEmpty"
                                  />
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          <div className="ml-2 mt-1 sold-items">
                            ({item.sold} Sold)
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center ">
                          <span className="p-0 ">
                            <span className="text-store">Store : </span>
                            <span className="link brand-link">
                              {storeInfo.storeName}
                            </span>
                          </span>
                        </div>
                        <div className="mt-2 d-flex justify-content-start align-items-center ">
                          <span className="product-price w-25">
                            RS. {item.price}
                          </span>
                          {item.isSale === true && (
                              <h5 className="product-discount-price ml-2 mt-1">
                                {Math.ceil(
                                  item.price -
                                    (item.price * item.salePercent) / 100
                                )}
                              </h5>
                            )}
                        </div>
                        <div className="mt-2 d-flex justify-content-between align-items-center ">
                          <span className="p-0 text-store">Colors</span>
                        </div>
                        <div className="colors">
                          {item && item?.properties ? (
                            JSON.parse(item.properties).map((color, index) => (
                              <button
                                key={index}
                                className={
                                  colorIndex === index
                                    ? "active-color"
                                    : "colors-button"
                                }
                                style={{ background: `${color.color}` }}
                                onClick={() =>
                                  handleColorIndex(index, color.color)
                                }
                              ></button>
                            ))
                          ) : (
                            <></>
                          )}
                        </div>
                        <div>
                          {colorIndex >= 0 ? (
                            <>
                              <div className="mt-1 d-flex justify-content-between align-items-center ">
                                <span className="text-store">Sizes</span>
                              </div>
                              <div>
                                {item && item?.properties ? (
                                  JSON.parse(item.properties).map(
                                    (properties, indexI) => {
                                      return (
                                        <div
                                          key={indexI}
                                          className="d-flex flex-row"
                                        >
                                          {colorIndex === indexI ? (
                                            <>
                                              {properties["subProperty"].map(
                                                (subProperty, indexJ) => {
                                                  return (
                                                    <div
                                                      key={indexJ}
                                                      className="mr-2"
                                                    >
                                                      <button
                                                        className={
                                                          subPropertyType ===
                                                          subProperty.type
                                                            ? "btn-size-active"
                                                            : "btn-size"
                                                        }
                                                        // className="btn-size"
                                                        type="button"
                                                        onClick={() =>
                                                          handleSubPropertySize(
                                                            subProperty.quantity,
                                                            subProperty.type
                                                          )
                                                        }
                                                      >
                                                        <div className="text-sizes">
                                                          {subProperty.type}
                                                        </div>
                                                      </button>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ) : (
                            <>please select color first</>
                          )}
                        </div>
                        <div className="">
                          <div className="mt-1 d-flex justify-content-between align-items-center ">
                            <span className="text-store">Quantity</span>
                          </div>
                          <div className="row justify-content-between text-left">
                            <div className="col-sm-6 col-md-12">
                              <div className="input-group">
                                {qtyLimit === 0 ? (
                                  <>
                                    <p className="text-store mt-1">
                                      Select Sizes to view quantity
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <div className="quantity">{qtyLimit}</div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="details d-flex justify-content-start">
                    <div className="w-100 mr-4">
                      <fieldset className="card-store h-100">
                        <legend className="w-auto ml-2 mr-2 text-dim">
                          <div className="ml-2 mr-2">Description</div>
                        </legend>
                        <div className="">
                          {item ? (
                            <>
                              <div
                                className="about-product"
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              ></div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ProductPage;
