import React, { useEffect, useState } from "react";
import Joi from "joi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { apiBaseUrl } from "../config/Config";
import { clearOtpStorage } from "../../shared/clearOtpStorage"
import idCardPreview from "../../Assets/images/card-preview.png";
import { IBANRegex,accountTitleregex, banknameregex ,bankBranchCodeRegex} from "../../shared/validators";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const BankDetailForm = ({ stepCount, setStepCount }) => {
  let navigate = useNavigate();
  const [values, setValues] = useState({
    bankName: "",
    accountTitle: "",
    accountNumber: "",
    ibanNumber: "",
    branchCode: "",
  });
  const [errors, setErrors] = useState({
    bankName: "",
    accountTitle: "",
    accountNumber: "",
    ibanNumber: "",
    branchCode: "",
  });
  const [bankError, setBankError] = useState(false);
  const [bankErrorMessage, setBankErrorMessage] = useState("");

  const [cheque, setCheque] = useState("");
  const [previewCheque, setPreviewCheque] = useState();

  useEffect(() => {
    if (!cheque) {
      setPreviewCheque(undefined);
      return;
    }
    const objectUrlCheque = URL.createObjectURL(cheque);
    setPreviewCheque(objectUrlCheque);
    return () => URL.revokeObjectURL(objectUrlCheque);
  }, [cheque]);

  const handleInputOnChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setErrors({});
  };

  const onSelectCheque = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setCheque(undefined);
      return;
    }
    setCheque(e.target.files[0]);
  };

  var schema = Joi.object({
    bankName: Joi.string().required().regex(banknameregex).min(3).max(50).label("bankName").messages({
      "string.empty": "Bank Name cannot be empty",
      "string.pattern.base": "Please enter correct Bank Name",
      "string.min": "Bank Name must be at least 3 characters",
      "string.max":"Bank Name  must be no more than 50 characters"
    }),
   accountTitle: Joi.string().required().regex(accountTitleregex).min(3).max(60).label("accountTitle").messages({
      "string.empty": "Account Title cannot be empty",
      "string.pattern.base": "Please enter correct Account Title i.e, Debit Card",
      "string.min": "Account Title must be at least 3 characters",
      "string.max":"Account Title  must be no more than 60 characters"
    }),
    accountNumber: Joi.string().alphanum().required().min(9).max(20).label("accountNumber").messages({
      "string.empty": "Account Number Title cannot be empty",
      "string.pattern.base": "Please enter correct Account Number",
      "string.min": "Account Number must be at least 9 digits",
      "string.max":"Account Number  must be no more than 20 digits"
    }),
    ibanNumber: Joi.string().required().regex(IBANRegex).min(13).max(34).label("ibanNumber").messages({
      "string.empty": "IBAN Number  cannot be empty",
      "string.pattern.base": "Please enter correct IABN Numbers",
      "string.min": "IBAN Number must be at least 13 digits ",
      "string.max":"IBAN Number  must be no more than 34 digits"
    }),
    branchCode: Joi.string().required().regex(bankBranchCodeRegex).min(3).max(7).label("branchCode").messages({
      "string.empty": "Branch Code Title cannot be empty",
      "string.pattern.base": "Please enter correct Branch Code",
      "string.min": "Branch Code must be at least 3 digits",
      "string.max":"Branch Code must be no more than 7 digits"
    }),
  });
  const formValidation = () => {
    const result = schema.validate(values);
    if (!result.error) return null;
    let errors = {};
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formErrors = formValidation();
    if (formErrors) {
      setErrors({ ...formErrors });
      console.log(formErrors);
    }
    else if(cheque === ""){
       toast.error("Cheque copy should not be empty")
    } else {
      setErrors({});
      submitBankDetailForm({
        ...values,
      });
    }
  };

  const submitBankDetailForm = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "x-auth-token": sessionStorage.getItem("token"),
      },
    };
    try {
      const formData = new FormData();
      formData.append("bankName", values.bankName);
      formData.append("accountTitle", values.accountTitle);
      formData.append("accountNumber", values.accountNumber);
      formData.append("ibanNumber", values.ibanNumber);
      formData.append("branchCode", values.branchCode);
      formData.append("checkImage", cheque);

      axios
        .put(`${apiBaseUrl}/api/store/bank-detail`, formData, config)
        .then((res) => {
          if (res.data.meta.statusCode === 200) {
            clearOtpStorage();
            navigate("/login");
          }
        })
        .catch((err) => {
          if (err.response.data.meta.statusCode === 400) {
            setBankError(true);
            setBankErrorMessage(err.response.data.meta.message);
            setTimeout(() => {
              setBankError(false);
              setBankErrorMessage("");
            }, 5000);
          }

          if (err.response.data.meta.statusCode === 401) {
            setBankError(true);
            setBankErrorMessage(err.response.data.meta.message);
            setTimeout(() => {
              setBankError(false);
              setBankErrorMessage("");
            }, 5000);
          }
        });
    } catch (err) {
      return err;
    }
  };

  return (
    <form onSubmit={handleOnSubmit}>
      {bankError && (
        <div className="mt-2 error-text text-center alert-danger" role="alert">
          {bankErrorMessage}
        </div>
      )}
      <div className="d-flex justify-content-between direction_on_small mt-4">
        <div className="w-100 mr-4">
          <div className="field-heading">Bank Name</div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Bank Name"
              value={values.bankName || ""}
              name="bankName"
              onChange={handleInputOnChange}
            />
            {errors.bankName && (
              <div className="error-text">{errors.bankName}</div>
            )}
          </div>
        </div>
        <div className="w-100 margins">
          <div className="field-heading">Account Title</div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Account Title"
              value={values.accountTitle || ""}
              name="accountTitle"
              onChange={handleInputOnChange}
            />
            {errors.accountTitle && (
              <div className="error-text">
               {errors.accountTitle}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between direction_on_small mt-2">
        <div className="w-100 mr-4">
          <div className="field-heading">Acoount Number</div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Acoount Number"
              value={values.accountNumber || ""}
              name="accountNumber"
              onChange={handleInputOnChange}
            />
            {errors.accountNumber && (
              <div className="error-text">{errors.accountNumber}</div>
            )}
          </div>
        </div>
        <div className="w-100 margins">
          <div className="field-heading">IBAN Number</div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="IBAN Number"
              value={values.ibanNumber || ""}
              name="ibanNumber"
              onChange={handleInputOnChange}
            />
            {errors.ibanNumber && (
              <div className="error-text">{errors.ibanNumber}</div>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between direction_on_small mt-2">
        <div className="w-100 mr-4">
          <div className="field-heading">Bank Code</div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Bank Code"
              value={values.branchCode || ""}
              name="branchCode"
              onChange={handleInputOnChange}
            />
            {errors.branchCode && (
              <div className="error-text">{errors.branchCode}</div>
            )}
          </div>
        </div>
        <div className="w-100 margins">
          <div className="field-heading mb-1">Upload Cheque Copy</div>
          <div className="form-group">
            {cheque ? (
              <img
                src={previewCheque}
                className="cheque-preview"
                alt="idCardPreview"
              />
            ) : (
              <img
                src={idCardPreview}
                className="cheque-preview"
                alt="idCardPreview"
              />
            )}
          </div>
          <div className="d-flex justify-content-end">
            <label htmlFor="uploadCheque" className="select-file-button">
              <div role="button">Select File</div>
              <input
                type="file"
                id="uploadCheque"
                hidden
                accept="image/png, image/jpeg"
                onChange={onSelectCheque}
              />
            </label>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div className="w-100 mr-2">
          <button
            type="button"
            className="btn-back w-100"
            onClick={() => setStepCount(stepCount - 1)}
          >
            Back
          </button>
        </div>
        <div className="w-100 ml-2">
          <button type="submit" className="btn-next w-100">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default BankDetailForm;
