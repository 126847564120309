import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoginImage from "../Assets/images/login-img.svg";
import IconWave from "../Assets/images/icons/icon-wave.svg";
import Logo from "../Assets/images/logo-white.svg";
import { apiBaseUrl } from "../Components/config/Config";

function ForgetPassword() {
  let navigate = useNavigate();
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [inputType, setInputType] = useState("email");
  const [isEmail, setIsEmail] = useState(true);
  
  const [emailValues, setEmailValues] = useState({
    email: "",
  });

  const [contactValues, setContactValues] = useState({
    contact: "",
  });

  const onChangeInputType = (e) => {
    let value = e.target.value;
    if (value && value.includes("@")) {
      setInputType("email");
      setIsEmail(true);
      setEmailValues({
        ...emailValues,
        email: value,
      });
    } else {
      setInputType("contact");
      setIsEmail(false);
      setContactValues({
        ...contactValues,
        contact: value,
      });
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let { email } = emailValues;
    let { contact } = contactValues;
    if (isEmail === true) {
      logIn({
        isEmail,
        email,
      });
    } else if (isEmail === false) {
      logIn({
        isEmail,
        contact,
      });
    }
  };

  const logIn = (user) => {
    axios
      .post(`${apiBaseUrl}/api/store/forget-password`, { ...user })
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          sessionStorage.setItem("token", res.data.data.token);
          navigate("/forgot-otp");
        }
      })
      .catch((err) => {
        if (err.response.data.meta.statusCode === 400) {
          setLoginError(true);
          setLoginErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setLoginError(false);
            setLoginErrorMessage("");
          }, 5000);
        }

        if (err.response.data.meta.statusCode === 401) {
          setLoginError(true);
          setLoginErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setLoginError(false);
            setLoginErrorMessage("");
          }, 5000);
        }
      });
  };

  return (
    <div id="forgetpassword">
      <div className="login-container">
        <Link to="/">
          <div className="header-logo">
            <img src={Logo} alt="Logo" />
          </div>
        </Link>
        <div className="d-flex login-full justify-content-md-center justify-content-between">
          <div className="d-flex left flex-column">
            <div className="heading-login">Amazing day.</div>
            <div className="description-login mt-3">
              Maybe some text here will help me see it better. Oh God. Oke,
              let’s do it then.
            </div>
            <img
              className="login-image-section"
              src={LoginImage}
              alt="LoginImage"
            />
          </div>
          <div className="right d-flex">
            <div className="login-form ">
              <img src={IconWave} alt="IconCancel" />
              <div className="mt-2">
                <div className="heading-form">Reset Password!</div>
                <div className="text-para mt-2">
                  Please Enter Registered Email or Phone Number
                </div>
                {loginError && (
                  <div
                    className="error-text text-center alert-danger"
                    role="alert"
                  >
                    {loginErrorMessage}
                  </div>
                )}
              </div>
              <form className="pb-5" onSubmit={handleOnSubmit}>
                <div className="mt-4">
                  <div className="field-heading">E-mail or phone number</div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Type your e-mail or phone number"
                      name={inputType}
                      type={inputType}
                      onChange={(e) => onChangeInputType(e)}
                      required
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <button type="submit" className="btn-sign-in w-100">
                    Next
                  </button>
                </div>
              </form>
              <div className="mt-5 ">
                <div className="login-footer mx-3">
                  Back to{" "}
                  <span className="login-footer-span">
                    <Link to="/login">Login</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
