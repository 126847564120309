import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { apiBaseUrl, imgBaseUrl } from "../../Components/config/Config";

const ChatConversations = ({ setConversationId, setItemId, setUserId, messageStatusId, setMessageStatusId, setConversationStatus }) => {
  const [conversationList, setConversationList] = useState([]);
  const [selectedConversationIndex, setSelectedConversationIndex] = useState(0);

  const { userToken, storeInfo } = useSelector((state) => state.store);
  const config = {
    headers: {
      "x-auth-token": userToken,
    },
  };

  const updateMessageStatus = (id, unRead) => {
    if (id!=null && id!=undefined && unRead != 0) {
      const apiUrl = `${apiBaseUrl}/api/message/store-message-status/${id}`;

      axios.put(apiUrl, {}, config).then((res) => {
        if (res) {
          const updatedConversationList = conversationList.map(
            (conversation, index) => {
              if (conversationList._id === res.data.conversationId) {
                return {
                  ...conversation,
                  unRead: 0,
                };
              }

              return conversation;
            }
          );
          setConversationList(updatedConversationList);
        }
      });
    }
  };

  const socketMessageStatus = (id) => {
    if(id!=null && id!=undefined){
      const updatedConversationList = conversationList.map((conversation, index)=>{
        if (conversation.id === id) {
          return{
            ...conversation,
            unRead: conversation['unRead']+1
          }
        }
        return conversation
      })
      setConversationList(updatedConversationList);
    }
    setMessageStatusId(null)
  };

useEffect(()=>{
  if(messageStatusId!=null){
    socketMessageStatus(messageStatusId)
  }
}, [messageStatusId])

  useEffect(() => {
    const page = 0;
    const apiUrl = `${apiBaseUrl}/api/conversion/store-conversation-list?page=${page}`;
    axios.get(apiUrl, config).then((res) => {
      setConversationList(res?.data?.data?.conversationList);
      if(res?.data?.data?.conversationList.length===0){
        setConversationStatus(false)
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="chat-contact-list ">
      <div>
        {conversationList?.map((data, index) => (
          <div
            className={`contact-first border-bottom p-2  text-white d-flex p-2 ${
              selectedConversationIndex === index ? "selected" : ""
            }`}
            key={index}
            onClick={() => {
              updateMessageStatus(data?.id, data.unRead);
              setSelectedConversationIndex(index);
              setConversationId(data?.id);
              setItemId(data.itemId);
              setUserId(data.userId);
            }}
          >
            <div>
              <img
                className="rounded rounded-circle"
                src={`${imgBaseUrl}/listing/small_${data?.data.images[0]}`}
                alt="thumb-img"
                loading="lazy"
              />
            </div>
            <span className="d-flex">
              <p className="p-2">{data?.data?.title}</p>
              {data.unRead ? (
                <>
                  {data.unRead > 9 ? (
                    <p className="unread-message">9+</p>
                  ) : (
                    <p className="unread-message">{data.unRead}</p>
                  )}
                </>
              ) : (
                <></>
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatConversations;
