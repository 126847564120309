import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";

import axios from "axios";
import { apiBaseUrl } from "../Components/config/Config";
import Joi from "joi";
import IconWave from "../Assets/images/icons/icon-wave.svg";
import { emailRegex,storenameregex, passwordregex, confirmpasswordregex, contactRegex } from "../shared/validators";

export default function RegisterSeller() {
  let navigate = useNavigate();
  const [registerError, setRegisterError] = useState(false);
  const [registerErrorMessage, setRegisterErrorMessage] = useState("");
  const [inputType, setInputType] = useState("email");
  const [isEmail, setIsEmail] = useState(true);
  const [emailValues, setEmailValues] = useState({
    email: "",
  });
  const [contactValues, setContactValues] = useState({
    contact: "",
  });
  const[error , setError] = useState("");
  const [tags, setTags] = useState(["E-commerce"]);
  const [values, setValues] = useState({
    storeName: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    storeName: "",
    keyWords: "",
    password: "",
    confirmPassword: "",
  });

  const onChangeInputType = (e) => {
    let value = e.target.value;
    if (value && value.includes("@")) {
      setError("");
      setInputType("email");
      setIsEmail(true);
      setEmailValues({
        ...emailValues,
        email: value,
      });
    } else {
      setInputType("contact");
      setError("");
      setIsEmail(false);
      setContactValues({
        ...contactValues,
        contact: value,
      });
    }
  };

  const handleInputOnChange = (e) => {
    setErrors("");
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  
  var schema = Joi.object({
    storeName: Joi.string().required().regex(storenameregex).min(3).max(35).label("Store Name").messages({
      "string.empty": "Store name cannot be empty",
      "string.pattern.base": "Please enter correct Store name",
      "string.min": "Store name must be at least 3 characters long",
      "string.max": "Store name must be no more than 35 characters long"
    }),
    password: Joi.string().regex(passwordregex).min(8).max(100).required().label("password").messages({
      "string.empty": "Password cannot be empty",
      "string.pattern.base": "Please enter the correct format for the password",
      "string.min": "Password must be at least 8 characters long",
      "string.max": "Password must be no more than 100 characters long"
    }),
    confirmPassword: Joi.string().required().valid(Joi.ref('password')).label("confirmPassword").messages({
      "any.only": "Passwords do not match",
      "any.required": "Confirm password is required"
    }),
  });
  const formValidation = () => {
    const result = schema.validate(values);
    if (!result.error) return null;

    let errors = {};
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };
  const validateEmail = (email)=>{
    return emailRegex.test(email);
  }
  const validateContact = (contact) => {
    return contactRegex.test(contact);
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    let { storeName, password } = values;
    let keyWords = JSON.stringify(tags);
    let { email } = emailValues;
    let { contact } = contactValues;

    const eerrors = formValidation();
    if (eerrors) {
      setErrors({ ...eerrors });
    } else {
      setErrors({});
      if (isEmail === true) {
        if(validateEmail(email)){
        registerAccount({
          isEmail,
          storeName,
          keyWords,
          email,
          password,
        });
      }
      else{
        setError("Email is not valid!")
      }
      } else if (isEmail === false) {
        if(validateContact(contact)){
        registerAccount({
          isEmail,
          storeName,
          keyWords,
          contact,
          password,
        });
      }
      else{
        setError("Contact is not valid!")
      }
    }
    }
  };
  const registerAccount = (values) => {
    axios
      .post(`${apiBaseUrl}/api/store/signup`, { ...values })
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          sessionStorage.setItem("token", res.data.data.token);
          sessionStorage.setItem("isEmail", res.data.data.isEmail);
          if(res.data.data.isEmail){
            sessionStorage.setItem("email", res.data.data.email);
          }else{
            sessionStorage.setItem("contact", res.data.data.contact);
          }
          sessionStorage.setItem("password", res.data.data.password);
          navigate("/register-otp");
        }
      })
      .catch((err) => {
        if (err.response.data.meta.statusCode === 400) {
          setRegisterError(true);
          setRegisterErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setRegisterError(false);
            setRegisterErrorMessage("");
          }, 5000);
        }
        if (err.response.data.meta.statusCode === 401) {
          setRegisterError(true);
          setRegisterErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setRegisterError(false);
            setRegisterErrorMessage("");
          }, 5000);
        }
      });
  };
  return (
    <>
      <div id="registerseller">
        <div className="card-center">
        <div className="register-card">
            <img src={IconWave} alt="IconCancel" />
            <div className="heading-form mt-3">Become a Seller!</div>
            {registerError && (
              <div className="error-text text-center alert-danger" role="alert">
                {registerErrorMessage}
              </div>
            )}
            <form onSubmit={handleOnSubmit}>
              <div className="mt-3">
                <div className="field-heading">Store Name</div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Store Name"
                    name="storeName"
                    value={values.storeName || ""}
                    onChange={handleInputOnChange}
                  />
                </div>
                {errors.storeName && (
                  <div className="error-text pb-1">
                    {errors.storeName}
                  </div>
                )}
              </div>
              <div className="mt-1">
                <div className="field-heading">Keywords for Store</div>
                <div className="form-group">
                  <TagsInput
                    classNames={{
                      tag: "button-keyword",
                      input: "search-control w-50",
                    }}
                    value={tags}
                    onChange={setTags}
                    name="tags"
                    placeHolder="enter keywords or tags"
                  />
                </div>
              </div>
              <div className="mt-1">
                <div className="field-heading">Email Address / Contact</div>
                <div className="form-group">
                  <input
                    className="form-control"
                    placeholder="Type your e-mail or phone number"
                    name={inputType}
                    // type={inputType}
                    onChange={(e) => onChangeInputType(e)}
                    required
                  />
                </div>
                {error}
              </div>
              <div className="mt-1">
                <div className="field-heading">Password</div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="********"
                    name="password"                    
                    value={values.password || ""}
                    onChange={handleInputOnChange}
                  />
                </div>
                {errors.password && (
                  <div className="error-text pb-1">
                   {errors.password}
                  </div>
                )}
              </div>
              <div className="mt-1 pb-3">
                <div className="field-heading">Confirm Password</div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="********"
                    value={values.confirmPassword || ""}
                    name="confirmPassword"
                    onChange={handleInputOnChange}
                  />
                </div>
                {errors.confirmPassword && (
                  <div className="error-text pb-1">{errors.confirmPassword}</div>
                )}
              </div>
              <div className="m-auto">
                <button type="submit" className="btn-sign-in w-100">
                  Sign Up
                </button>
              </div>
            </form>
            <div className="mt-3">
              <div className="login-footer mx-1">
                Already have an account?{" "}
                <span className="login-footer-span">
                  <Link to="/login">Login</Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
