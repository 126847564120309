import { React, useState, useEffect, useRef } from "react";
import no_data from "../../Assets/images/chat/no_data.svg";
import { useSelector } from "react-redux";
import "./HeaderNotification.css";
import axios from "axios";
import moment from "moment";
import Loader from "../Loader/Loader";
import { apiBaseUrl } from "../config/Config";

const HeaderNotification = ({ isNotification }) => {
  const { storeInfo, userToken } = useSelector((state) => state.store);
  const [showToast, setShowToast] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const notificationRef = useRef(null);
  const [page, setPage] = useState(0);
  const [isLoad, setIsLoad] = useState(true);
  const [loadData, setLoadData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const socket = window.globalSocket;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "x-auth-token": userToken,
    },
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${apiBaseUrl}/api/notification/store-notification?page=${page}`, config)
      .then((res) => {
        setIsLoading(false);
        setNotifications([...notifications, ...res.data.data.notificationData]);
        if (res.data.meta.page === page) {
          setIsLoad(false);
        } else {
          setPage(res.data.meta.page);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  useEffect(() => {
    setShowToast(isNotification)
  }, [isNotification]);

  useEffect(() => {
    const closeNotificationOnClickOutside = (e) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(e.target)
      ) {
        setShowToast(false);
      }
    };
    if (showToast) {
      document.addEventListener("click", closeNotificationOnClickOutside);
    }
    return () => {
      document.removeEventListener("click", closeNotificationOnClickOutside);
    };
  }, [showToast]);

  // useEffect(() => {
  //   axios
  //     .get(`${apiBaseUrl}/api/notification/store-notification-count`, config)
  //     .then((res) => {
  //       if (res) {
  //         setNotificationCount(res.data.data.notificationCount);
  //       }
  //     })
  //     .catch((error) => {
  //       setNotificationCount(0);
  //     });
  // }, []);

  // useEffect(() => {
  //   axios
  //     .get(`${apiBaseUrl}/api/notification/store-notification`, config)
  //     .then((res) => {
  //       if (res) {
  //         setNotifications(res.data.data.notificationData);
  //       }
  //     })
  //     .catch((error) => {
  //       setNotifications([]);
  //     });
  // }, []);



  return (
    <ul>
      <li className="notification-pop-margin  list-unstyled">
        {storeInfo ? (
          <>

            <div className="rounded position-fixed top-0 start-0 p-3 notification-popup">
              <div
                ref={notificationRef}
                id="liveToast"
                className={`toast ${showToast ? "show" : "d-none"}`}
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
              >
                <div className="  d-flex rounded-top  notification-container">
                  <div className="triangle"></div>
                  <div className="text-white fw-bold fs notification-text">
                    <h6>Notifications</h6>
                  </div>
                </div>
                <div className=" bg-white d-flex notification-data flex-column w-100 h-100 overflow-auto">
                  {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                      <p><Loader/></p>
                    </div>
                  ) : notifications && notifications.length > 0 ? <>{notifications?.map((data, index) => (
                    <div className="notification-body d-flex p-2">
                      <img src={data.imageUrl} alt="img" />
                      <div className="pl-2 flex-grow-1">
                        <div className="d-flex justify-content-between">
                          <strong className="text-dark">{data.title}</strong>
                          <span className="ml-1 mt-1">
                            {moment(data.createdAt).format("DD MMM YYYY")}
                          </span>
                        </div>
                        <p>{data.message}</p>
                      </div>
                    </div>
                  ))
                  }  {isLoad ? (
                    <div
                      role="button"
                      className="text-center fw-bold text-dark mb-3"
                      onClick={() => setLoadData(!loadData)}
                    >
                      <p>
                        <strong>Load more</strong>
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}
                  </>
                    : (
                      <div className="d-flex justify-content-center align-items-center w-100 h-100">
                        <img src={no_data} alt="no data" className="w-50 h-50 " />
                      </div>
                    )}
                </div>

              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </li>
    </ul>
  );
};

export default HeaderNotification;
