import React, { useState } from "react";
import AllOrders from "../../Components/DashboardComponents/SellerOrder/AllOrders";
import OrderWithStatus from "../../Components/DashboardComponents/SellerOrder/OrderWithStatus";

function Order() {
  const [statusFilter, setStatusFilter] = useState("All");

  const filterStatus = (categitem) => {
    setStatusFilter(categitem);
  };

  return (
    <>
      <div id="sellerorder" className="w-100 mt-4">
        <div className="row-scroller d-flex">
          <div className="d-flex mt-4  align-items-center justify-content-start">
            <div className="d-flex  align-item-center justify-content-center ">
              <div
                role="button"
                className={
                  statusFilter === "All"
                    ? "filter-button-selected mr-3 text-center"
                    : "filter-button mr-3 text-center"
                }
                onClick={(e) => {
                  filterStatus("All");
                }}
              >
                All
              </div>
              <div
                role="button"
                className={
                  statusFilter === "Accepted"
                    ? "filter-button-selected mr-3 text-center"
                    : "filter-button mr-3 text-center"
                }
                onClick={(e) => {
                  filterStatus("Accepted");
                }}
              >
                Accepted
              </div>
              <div
                role="button"
                className={
                  statusFilter === "Pending"
                    ? "filter-button-selected mr-3 text-center"
                    : "filter-button mr-3 text-center"
                }
                onClick={(e) => {
                  filterStatus("Pending");
                }}
              >
                New Order
              </div>
              <div
                role="button"
                className={
                  statusFilter === "Rejected"
                    ? "filter-button-selected mr-3 text-center"
                    : "filter-button mr-3 text-center"
                }
                onClick={(e) => {
                  filterStatus("Rejected");
                }}
              >
                Rejected
              </div>
              <div
                role="button"
                className={
                  statusFilter === "Shipped"
                    ? "filter-button-selected mr-3 text-center"
                    : "filter-button mr-3 text-center"
                }
                onClick={(e) => {
                  filterStatus("Shipped");
                }}
              >
                Shipped
              </div>
              <div
                role="button"
                className={
                  statusFilter === "Delivered"
                    ? "filter-button-selected mr-3 text-center"
                    : "filter-button mr-3 text-center"
                }
                onClick={(e) => {
                  filterStatus("Delivered");
                }}
              >
                Delivered
              </div>
              <div
                role="button"
                className={
                  statusFilter === "Returned"
                    ? "filter-button-selected mr-3 text-center"
                    : "filter-button mr-3 text-center"
                }
                onClick={(e) => {
                  filterStatus("Returned");
                }}
              >
                Returned
              </div>
              <div
                role="button"
                className={
                  statusFilter === "Cancelled"
                    ? "filter-button-selected mr-3 text-center"
                    : "filter-button mr-3 text-center"
                }
                onClick={(e) => {
                  filterStatus("Cancelled");
                }}
              >
                Cancelled
              </div>
            </div>
          </div>
        </div>
        {statusFilter === "All" && <AllOrders />}
        {statusFilter === "Accepted" && (
          <OrderWithStatus status={statusFilter} />
        )}
        {statusFilter === "Pending" && (
          <OrderWithStatus status={statusFilter} />
        )}
        {statusFilter === "Rejected" && (
          <OrderWithStatus status={statusFilter} />
        )}
        {statusFilter === "Shipped" && (
          <OrderWithStatus status={statusFilter} />
        )}
        {statusFilter === "Delivered" && (
          <OrderWithStatus status={statusFilter} />
        )}
        {statusFilter === "Returned" && (
          <OrderWithStatus status={statusFilter} />
        )}
        {statusFilter === "Cancelled" && (
          <OrderWithStatus status={statusFilter} />
        )}
      </div>{" "}
    </>
  );
}

export default Order;
