
import React, { useState } from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';

const MapView = ({ lat, lng, mapAddress, zoom, width, height }) => {
  const [activeMarker, setActiveMarker] = useState(false)
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyA5dpHg91sA_-H5ZYv_4aavdYIDHBLDTZU',
    libraries:['places'],
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <div>
      <GoogleMap
        mapContainerStyle={{
          width: width,
          height: height,
        }}
        zoom={zoom}
        center={{ lat, lng }}
        options={{ zoomControl: true }}
      >
        {activeMarker && (
          <InfoWindow
            position={{ lat, lng }}
            onCloseClick={() => setActiveMarker(false)}
          >
            <div>{mapAddress}</div>
          </InfoWindow>
        )}
        <Marker
          onMouseOver={() => setActiveMarker(true)}
          position={{ lat, lng }}
        />
      </GoogleMap>
    </div>
  );
};

export default MapView