import React, { useEffect, useState } from "react";
import moment from "moment";
import UserAvatar from "../../Assets/images/avatar.svg";
import StarFilled from "../../Assets/images/icons/icon-star-filled.svg";
import { useSelector } from "react-redux";
import axios from "axios";
import { apiBaseUrl, imgBaseUrl } from "../../Components/config/Config";

const Reviews = () => {
  const { userToken } = useSelector((state) => state.store);
  const [productReviews, setProductReviews] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoad, setIsLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [loadData, setLoadData] = useState(false);

  const getProductReviews = () => {
    const config = {
      headers: {
        "x-auth-token": userToken,
      },
    };

    axios
      .get(
        `${apiBaseUrl}/api/reviews/get-store-product-review?page=${page}`,
        config
      )
      .then((res) => {
        setIsLoading(false);
        if (res.data.meta.statusCode === 200) {
          setProductReviews([...productReviews, ...res.data.data.itemReviews]);
        }
        if (res.data.meta.statusCode === 206) {
          setProductReviews([]);
        }
        if (res.data.meta.page === page) {
          setIsLoad(false);
        } else {
          setPage(res.data.meta.page);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getProductReviews();
  }, [loadData]);

  return (
    <div id="reviews">
            {productReviews.map((reviews) => (
              <div className="review-card p-2 mb-3">
              <div className="mb-3">
              <div className="d-flex flex-column p-3">
                <div>
                  {reviews.userInfo.image === null ? (
                    <img
                      className="profile-pic-review"
                      src={UserAvatar}
                      alt="profile-pic-review"
                    />
                  ) : (
                    <img
                      className="profile-pic-review"
                      src={`${imgBaseUrl}/profile/avatar_${reviews.userInfo.image}`}
                      alt="profile-pic-review"
                    />
                  )}
                </div>
                <div className="d-flex flex-column w-75">
                  <div className="user-name">{reviews.userInfo.name}</div>
                  <div className="d-flex ">
                    {reviews?.itemReviews.ratingNumber ? (
                      <>
                        {reviews.itemReviews.ratingNumber >= 1 ? (
                          <img
                            className="mr-1"
                            src={StarFilled}
                            alt="StarFilled"
                          />
                        ) : (
                          <></>
                        )}

                        {reviews.itemReviews.ratingNumber >= 2 ? (
                          <img
                            className="mr-1"
                            src={StarFilled}
                            alt="StarFilled"
                          />
                        ) : (
                          <></>
                        )}
                        {reviews.itemReviews.ratingNumber >= 3 ? (
                          <img
                            className="mr-1"
                            src={StarFilled}
                            alt="StarFilled"
                          />
                        ) : (
                          <></>
                        )}
                        {reviews.itemReviews.ratingNumber >= 4 ? (
                          <img
                            className="mr-1"
                            src={StarFilled}
                            alt="StarFilled"
                          />
                        ) : (
                          <></>
                        )}
                        {reviews.itemReviews.ratingNumber >= 5 ? (
                          <img
                            className="mr-1"
                            src={StarFilled}
                            alt="StarFilled"
                          />
                        ) : (
                          <></>
                        )}
                        {/* Repeat similar blocks for other rating numbers */}
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="ml-2">
                      {reviews.itemReviews.ratingNumber}
                    </div>
                  </div>
                  <p className="content-review mt-3">
                    {reviews.itemReviews.message}
                  </p>
                  <div className="d-flex">
                    {reviews.itemReviews.images.map((images, index) => (
                      <img
                        key={index}
                        className="pic-review"
                        src={`${imgBaseUrl}/review/small_${images}`}
                        alt={`product-${index + 1}`}
                      />
                    ))}
                  </div>
                  <div className="ml-auto">
                    <p className="review-date">
                      {moment(reviews.itemReviews.createdAt).format(
                        "DD  MMMM  YYYY"
                      )}
                    </p>
                  </div>
                </div>
              </div>
              </div>
              </div>
            ))}
        {isLoad ? (
          <div
            role="button"
            className="d-flex justify-content-center load-more pb-2"
            onClick={() => setLoadData(!loadData)}
          >
            Load more
          </div>
        ) : (
          <></>
        )}
    </div>
  );
};

export default Reviews;
