import React from "react";
import { Link } from "react-router-dom";
import iconDashboard from "../../../Assets/images/icons/dashboard-icons/dashboard.svg";
import iconDashboardActive from "../../../Assets/images/icons/dashboard-icons/dashboardactive.svg";
import iconMyorder from "../../../Assets/images/icons/dashboard-icons/myorder.svg";
import iconMyorderActive from "../../../Assets/images/icons/dashboard-icons/myorderactive.svg";
import iconProducts from "../../../Assets/images/icons/dashboard-icons/products.svg";
import iconProductsActive from "../../../Assets/images/icons/dashboard-icons/productsactive.svg";
import iconSetting from "../../../Assets/images/icons/dashboard-icons/setting.svg";
import iconSettingActive from "../../../Assets/images/icons/dashboard-icons/settingactive.svg";
import iconThumb from "../../../Assets/images/icons/dashboard-icons/thumb.svg";
import chat from "../../../Assets/images/icons/chat.svg"
import chatActive from "../../../Assets/images/icons/chatActive.svg"

import iconThumbActive from "../../../Assets/images/icons/dashboard-icons/thumb-active.svg";

function SideBar() {
  let pathname = window.location.pathname;

  return (
    <ul className="navbar-nav sidebar accordion mt-5" id="accordionSidebar">
      <li className="nav-item">
        <Link className="nav-link" to="/dashboard">
          {pathname.match("/dashboard") ? (
            <>
              <img
                className="mr-3"
                src={iconDashboardActive}
                alt="iconDashboardActive"
              />
            </>
          ) : (
            <>
              <img className="mr-3" src={iconDashboard} alt="iconDashboard" />
            </>
          )}

          <span
            className={`${
              pathname.match("/dashboard")
                ? "sidebar-span-active"
                : "sidebar-span"
            }`}
          >
            Dashboard
          </span>
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/orders">
          {pathname.match("/orders") ? (
            <>
              <img
                className="mr-3"
                src={iconMyorderActive}
                alt="iconMyorderActive"
              />
            </>
          ) : (
            <>
              <img className="mr-3" src={iconMyorder} alt="iconMyorder" />
            </>
          )}
          <span
            className={`${
              pathname.match("/orders") ? "sidebar-span-active" : "sidebar-span"
            }`}
          >
            My Orders
          </span>
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/menu">
          {pathname.match("/menu") ? (
            <>
              <img
                className="mr-3"
                src={iconProductsActive}
                alt="iconProductsActive"
              />
            </>
          ) : (
            <>
              <img className="mr-3" src={iconProducts} alt="iconProducts" />
            </>
          )}
          <span
            className={`${
              pathname.match("/menu") ? "sidebar-span-active" : "sidebar-span"
            }`}
          >
            Products
          </span>
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/reviews">
          {pathname.match("/reviews") ? (
            <>
              <img
                className="mr-3"
                src={iconThumbActive}
                alt="iconThumbActive"
              />
            </>
          ) : (
            <>
              <img className="mr-3" src={iconThumb} alt="iconThumb" />
            </>
          )}
          <span
            className={`${
              pathname.match("/reviews")
                ? "sidebar-span-active"
                : "sidebar-span"
            }`}
          >
            Product Reviews
          </span>
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/chat">
          {pathname.match("/chat") ? (
            <>
              <img
                className="mr-3"
                src={chatActive}
                alt="iconThumbActive"
              />
            </>
          ) : (
            <>
              <img className="mr-3" src={chat} alt="iconThumb" />
            </>
          )}
          <span
            className={`${
              pathname.match("/chat")
                ? "sidebar-span-active"
                : "sidebar-span"
            }`}
          >
            Chat
          </span>
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/settings">
          {pathname.match("/settings") ? (
            <>
              <img
                className="mr-3"
                src={iconSettingActive}
                alt="iconSettingActive"
              />
            </>
          ) : (
            <>
              <img className="mr-3" src={iconSetting} alt="iconSetting" />
            </>
          )}
          <span
            className={`${
              pathname.match("/settings")
                ? "sidebar-span-active"
                : "sidebar-span"
            }`}
          >
            Account Setting
          </span>
        </Link>
      </li>
    </ul>
  );
}

export default SideBar;
