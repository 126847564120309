import React, { useState } from "react";
import StepperLocation from "../Assets/images/icons/stepper-location.svg";
import StepperId from "../Assets/images/icons/stepper-idcard.svg";
import StepperIdFilled from "../Assets/images/icons/stepper-idcard-filled.svg";
import StepperBank from "../Assets/images/icons/stepper-bank.svg";
import StepperBankFilled from "../Assets/images/icons/stepper-bank-filled.svg";
// import location from "../Assets/images/icons/location.svg";
import AddressForm from "../Components/RegisterDetails/AddressForm";
import IdentityForm from "../Components/RegisterDetails/IdentityForm";
import BankDetailForm from "../Components/RegisterDetails/BankDetailForm";
import locationfilled from "../Assets/images/locationfilled.svg"
import location11 from "../Assets/images/icons/location11.svg"
import Maplocation from "../Components/RegisterDetails/Maplocation";

const RegisterDetails = () => {
  const [stepCount, setStepCount] = useState(1);

  return (
    <div id="registerdetails">
      <div className="card-center">
        <div className="details-card">
          <div className="d-flex justify-content-between register-progressbar-track">
            <div
              role="button"
              className="track-progressbar text-center"
              onClick={() => setStepCount(1)}
            >
              <img src={StepperLocation} alt="StepperLocation" />
              <div className="mt-2 stepper-text-active">Address</div>
              {stepCount >= 2 && <div id="tracker-register-active"></div>}
            </div>
            <div
              role="button"
              className="track-progressbar text-center"
              onClick={() => setStepCount(2)}
            >
              {stepCount >= 2 ? (
                <>
                  <img src={StepperIdFilled} alt="StepperIdFilled" />
                  <div className="stepper-text-active mt-2">ID Card</div>
                </>
              ) : (
                <>
                  <img src={StepperId} alt="StepperId" />
                  <div className="stepper-text mt-2">ID Card</div>
                </>
              )}

              {stepCount >= 3 && <div id="tracker-register-active"></div>}
            </div>
            <div
              role="button"
              className="track-progressbar text-center"
              onClick={() => setStepCount(3)}
            >
              {stepCount >= 3 ? (
                <> 
                  <img src={locationfilled} alt="location" />
                  <div className="stepper-text-active ">location</div>
                </>
              ) : (
                <>
                  <img src={location11} alt="StepperId" className="mt-2" />
                  <div className="stepper-text mt-2">location</div>
                </>
              )}

              {stepCount >=  4 && <div id="tracker-register-active"></div>}
            </div>
            
            <div
              role="button"
              className="track-progressbar text-center"
              onClick={() => setStepCount(4)}
            >
              {stepCount >= 4 ? (
                <>
                  <img src={StepperBankFilled} alt="StepperBankFilled" />
                  <div className="stepper-text-active mt-2">Bank Details</div>
                </>
              ) : (
                <>
                  <img src={StepperBank} alt="StepperBank" />
                  <div className="stepper-text mt-2">Bank Details</div>
                </>
              )}
            </div>
            <div id="tracker-register"></div>
          </div>
          {stepCount === 1 ? (
            <>
              <AddressForm stepCount={stepCount} setStepCount={setStepCount} />
            </>
          ) : (
            <></>
          )}
          {stepCount === 2 ? (
            <>
              <IdentityForm stepCount={stepCount} setStepCount={setStepCount} />
            </>
          ) : (
            <></>
          )}
          {stepCount === 3 ? (
            <>
              <Maplocation  stepCount={stepCount} setStepCount={setStepCount}
              />
            </>
          ) : (
            <></>
          )}
           {stepCount === 4 ? (
            <>
            <BankDetailForm  stepCount={stepCount} setStepCount={setStepCount}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegisterDetails;
