import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiBaseUrl } from "../../../Components/config/Config"

export const storeData = createAsyncThunk(
  "register/store",
  async (user, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`${apiBaseUrl}/api/store/signin`,{...user});
      return data;
    } catch (error) {
      if (error.response && error.response.data.meta.message) {
        return rejectWithValue(error.response.data.meta.message);
      } else {
        return rejectWithValue(error.response.data.meta.message);
      }
    }
  }
);
