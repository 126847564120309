import React, { useEffect, useState } from "react";
import IconCancel from "../../../Assets/images/icons/icon-cancel.svg";
import UserAvatar from "../../../Assets/images/avatar.svg";

import { useSelector, useDispatch } from "react-redux";
import { updateLogo } from "../../../app/features/store/storeSlice";
import axios from "axios";
import $ from "jquery";
import { apiBaseUrl, imgBaseUrl } from "../../config/Config";

const ChangeProfileModal = () => {
  const { storeInfo, userToken } = useSelector((state) => state.store);
  const dispatch = useDispatch();

  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  const [storeImageError, setStoreImageError] = useState(false);
  const [storeImageErrorMessage, setStoreImageErrorMessage] = useState("");

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const handleModalClose = () => {
    $("#profile-modal").modal("hide");
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "x-auth-token": userToken,
      },
    };
    try {
      const formData = new FormData();
      formData.append("logo", selectedFile);
      formData.append("logoPath", storeInfo.logo);
      axios
        .put(`${apiBaseUrl}/api/store/change-logo`, formData, config)
        .then((res) => {
          if (res.data.meta.statusCode === 200) {
            dispatch(updateLogo(res.data.data.logo));
            handleModalClose();
          }
        })
        .catch((err) => {
          if (err.response.data.meta.statusCode === 400) {
            setStoreImageError(true);
            setStoreImageErrorMessage(err.response.data.meta.message);
            setTimeout(() => {
              setStoreImageError(false);
              setStoreImageErrorMessage("");
            }, 5000);
          }
          if (err.response.data.meta.statusCode === 401) {
            setStoreImageError(true);
            setStoreImageErrorMessage(err.response.data.meta.message);
            setTimeout(() => {
              setStoreImageError(false);
              setStoreImageErrorMessage("");
            }, 5000);
          }
        });
    } catch (err) {
      return err;
    }
  };

  return (
    <div
      className="modal"
      id="profile-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="emailModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="profile-modal">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={IconCancel} alt="IconCancel" />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="body-modal">
                <div className="mt-1">
                  <div className="heading-modal">Logo Update!</div>
                </div>
                {storeImageError && (
                  <div
                    className="error-text text-center alert-danger"
                    role="alert"
                  >
                    {storeImageErrorMessage}
                  </div>
                )}
                <div className="mt-3">
                  <form onSubmit={handleOnSubmit}>
                    <div className="d-flex justify-content-between">
                      {selectedFile ? (
                        <img
                          src={preview}
                          className="image-user"
                          alt="UserImage"
                        />
                      ) : (
                        <>
                          {storeInfo.logo === null ? (
                            <>
                              <img
                                src={UserAvatar}
                                className="image-user"
                                alt="UserAvatar"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={`${imgBaseUrl}/profile/avatar_${storeInfo.logo}`}
                                className="image-user"
                                alt="UserImage"
                              />
                            </>
                          )}
                        </>
                      )}
                      <label
                        htmlFor="upload"
                        role="button"
                        className="button-status-cancel text-center m-auto"
                      >
                        <div className=" d-none d-sm-block">Add Image</div>
                        <i className="fa fa-upload d-block d-sm-none"></i>
                        <input
                          type="file"
                          id="upload"
                          hidden
                          accept="image/png, image/jpeg"
                          onChange={onSelectFile}
                        />
                      </label>
                    </div>
                    {selectedFile === undefined ? (
                      <></>
                    ) : (
                      <>
                        <div className="mt-5 mb-3">
                          <button type="submit" className="btn-sign-in w-100">
                            Save Change
                          </button>
                        </div>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeProfileModal;
