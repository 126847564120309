import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import StarFilled from "../../Assets/images/icons/icon-star-filled.svg";
import NoProductYet from "../../Assets/images/icons/dashboard-icons/no-product-yet.svg";
import IconEdit from "../../Assets/images/icons/dashboard-icons/icon-edit.svg";
import EditIcon from "../../Assets/images/icons/dashboard-icons/product-edit-icon.svg";
import DeleteIcon from "../../Assets/images/icons/dashboard-icons/product-delete-icon.svg";
import { apiBaseUrl, imgBaseUrl } from "../../Components/config/Config";
import { useSelector, useDispatch } from "react-redux";
import { addListing, clearListing } from "../../app/features/store/storeSlice";
import DeleteListingModal from "../../Components/DashboardComponents/Modals/DeleteListingModal";

function Menu() {
  const navigate = useNavigate();
  const { userToken, storeListing } = useSelector((state) => state.store);
  const [status, setStatus] = useState("All");
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [isLoad, setIsLoad] = useState(false);
  const [loading, setLoading] = useState(true);
  const [itemId, setItemId] = useState(null);
  const [listedData, setListedData] = useState(false);

  useEffect(() => {
    setPage(0)
    dispatch(clearListing());
    getProductListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const getProductListing = () => {
    const config = {
      headers: {
        "x-auth-token": userToken,
      },
    };
    axios
      .get(`${apiBaseUrl}/api/listing/store-product-listing/${status}?page=${page}`, config)
      .then((res) => {
        if (res.data.meta.statusCode === 206) {
          setLoading(false);
          setListedData(true);
        }
        if (res.data.meta.statusCode === 200) {
          dispatch(addListing(res.data.data.listing));
          setLoading(false);
          setListedData(false);
          if (res.data.meta.page === page) {
            setIsLoad(false);
          } else {
            setPage(res.data.meta.page);
          }
        }
      });
  };

  const deleteItem = (itemId) => {
    setItemId(itemId);
  };

  function navigateTo() {
    navigate("/addproduct");
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
           <div id="menu" className="container">
                <div className="d-flex justify-content-between">
                  <div className="heading-menu">Listed Products</div>
                  <div
                    role="button"
                    className="button-status-cancel text-center"
                    onClick={navigateTo}
                  >
                    List New Product
                  </div>
                </div>
                <div id="sellerorder" className="w-100 mt-2">
                  <div className="row-scroller d-flex">
                    <div className="d-flex mt-4  align-items-center justify-content-start">
                      <div className="d-flex  align-item-center justify-content-center ">
                        <div
                          role="button"
                          className={
                            status === "All"
                              ? "filter-button-selected mr-3 text-center"
                              : "filter-button mr-3 text-center"
                          }
                          onClick={(e) => {
                            setStatus("All");
                          }}
                        >
                          All
                        </div>
                        <div
                          role="button"
                          className={
                            status === "Active"
                              ? "filter-button-selected mr-3 text-center"
                              : "filter-button mr-3 text-center"
                          }
                          onClick={(e) => {
                            setStatus("Active");
                          }}
                        >
                          Active
                        </div>
                        <div
                          role="button"
                          className={
                            status === "Draft"
                              ? "filter-button-selected mr-3 text-center"
                              : "filter-button mr-3 text-center"
                          }
                          onClick={(e) => {
                            setStatus("Draft");
                          }}
                        >
                          Draft
                        </div>
                        <div
                          role="button"
                          className={
                            status === "Rejected"
                              ? "filter-button-selected mr-3 text-center"
                              : "filter-button mr-3 text-center"
                          }
                          onClick={(e) => {
                            setStatus("Rejected");
                          }}
                        >
                          Rejected
                        </div>
                        <div
                          role="button"
                          className={
                            status === "Warning"
                              ? "filter-button-selected mr-3 text-center"
                              : "filter-button mr-3 text-center"
                          }
                          onClick={(e) => {
                            setStatus("Warning");
                          }}
                        >
                          Warning
                        </div>
                        <div
                          role="button"
                          className={
                            status === "Blocked"
                              ? "filter-button-selected mr-3 text-center"
                              : "filter-button mr-3 text-center"
                          }
                          onClick={(e) => {
                            setStatus("Blocked");
                          }}
                        >
                          Blocked
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-3">
                  {
                    storeListing.length>0?
                  <div
                  id="product-card"
                  className="d-flex flex-wrap justify-content-start"
                >
                  {storeListing.map((item, index) => {
                    return (
                      <div key={index} className="products-card h-100">
                        <div className="position-relative">
                          <div className="d-flex align-item-center p-1 w-100 justify-content-between position-absolute">
                            {item.isSale === true ? (
                              <>
                                <div className="products-card-percentage">
                                  <div className="percentage">
                                    {item.salePercent} %
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div></div>
                              </>
                            )}
                            <ul className="navbar-nav ml-auto products-card-fav-icon">
                              <li className="nav-item dropdown no-arrow position-absolute edit-option">
                                <Link
                                  className="nav-link dropdown-toggle"
                                  to="#"
                                  id="userDropdown"
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src={IconEdit} alt="IconEdit" />
                                </Link>
                                <div
                                  className="dropdown-menu dropdown-menu-right shadow"
                                  aria-labelledby="userDropdown"
                                >
                                  <Link to={`/editproduct/${item._id}`}>
                                    <div className="dropdown-item">
                                      <img src={EditIcon} alt="EditIcon" />
                                      <div className="btn"> Edit </div>
                                    </div>
                                  </Link>
                                  <div className="dropdown-divider"></div>
                                  <div
                                    className="dropdown-item"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#delete-listing-modal"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      deleteItem(item._id);
                                    }}
                                  >
                                    <img src={DeleteIcon} alt="DeleteIcon" />
                                    <div className="btn"> Delete </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <Link to={`/product/${item._id}`}>
                            <img
                              className="products-card-image"
                              src={`${imgBaseUrl }/listing/small_${item.images[0]}`}
                              alt="product"
                              loading="lazy"
                            />
                          </Link>
                        </div>
                        <h1>{item.title}</h1>
                        <div className="products-card-price d-flex">
                          <h5 className="card-price">
                            {item.currency} {item.price}
                          </h5>

                          {item.isSale === true && (
                            <h5 className="card-discount-price ml-2">
                              {Math.ceil(
                                item.price -
                                (item.price * item.salePercent) / 100
                              )}
                            </h5>
                          )}
                        </div>
                        <div className="d-flex align-item-center justify-content-between mt-2">
                          <div className="d-flex ">
                            <img
                              className="star-icon"
                              src={StarFilled}
                              alt="StarFilled"
                            />
                            <div className="star-count ml-2">
                              {item.averageRating}
                            </div>
                            <div className="products-card-sold ml-2">
                              ({item.sold} Sold)
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  </div>:<div className="h-100 d-flex align-items-center justify-content-center nodata_img">
                      <img src={NoProductYet} alt="NoProductYet" />
                    </div>
                  }
                </div>
                {isLoad === true && (
                  <div className="mt-2">
                    {isLoad ? (
                      <div className="d-flex justify-content-center">
                        <button
                          className="btn btn-primary"
                          onClick={() => getProductListing()}
                        >
                          Load More
                        </button>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <button className="btn btn-secondary" disabled>
                          No More
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
          {itemId ? <DeleteListingModal itemId={itemId} /> : <></>}
        </>
      )}
    </>

  );
}

export default Menu;
