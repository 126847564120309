import React, { useEffect, useState } from "react";

import IconCancel from "../../../Assets/images/icons/icon-cancel.svg";
import idCardPreview from "../../../Assets/images/card-preview.png";
import $ from "jquery";

const EditIdentityModal = () => {
  const [cardImages, setCardImages] = useState([]);
  const [IdFront, setIdFront] = useState();
  const [previewFront, setPreviewFront] = useState();
  const [IdBack, setIdBack] = useState();
  const [previewBack, setPreviewBack] = useState();

  useEffect(() => {
    if (!IdFront) {
      setPreviewFront(undefined);
      return;
    }
    const objectUrlFront = URL.createObjectURL(IdFront);
    setPreviewFront(objectUrlFront);
    return () => URL.revokeObjectURL(objectUrlFront);
  }, [IdFront]);

  useEffect(() => {
    if (!IdBack) {
      setPreviewBack(undefined);
      return;
    }
    const objectUrlBack = URL.createObjectURL(IdBack);
    setPreviewBack(objectUrlBack);
    return () => URL.revokeObjectURL(objectUrlBack);
  }, [IdBack]);
  const onSelectFront = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setIdFront(undefined);
      return;
    }
    setIdFront(e.target.files[0]);
    setCardImages([...cardImages, e.target.files[0]]);
  };

  const onSelectBack = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setIdBack(undefined);
      return;
    }
    setIdBack(e.target.files[0]);
    setCardImages([...cardImages, e.target.files[0]]);
  };
  const handleModalClose = () => {
    $("#identity-modal").modal("hide");
  };

  return (
    <div
      className="modal"
      id="identity-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="ientityModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="identity-modal">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={IconCancel} alt="IconCancel" />
                </span>
              </button>
            </div>
            <div className="modal-body pt-0">
              <div className="body-modal">
                <div className="mt-1">
                  <div className="heading-modal">Edit Identity Details!</div>
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <div className="w-100 mr-1">
                    <div className="field-heading">First and Last Name</div>
                    <div className="form-group">
                      <input
                        type="name"
                        className="form-control"
                        placeholder="First and Last Name"
                        name="provine"
                      />
                    </div>
                  </div>
                  <div className="w-100 mr-1">
                    <div className="field-heading">CNIC Number</div>
                    <div className="form-group">
                      <input
                        type="name"
                        className="form-control"
                        placeholder="CNIC Number"
                        name="city"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <div className="w-100 mr-1">
                    <div className="field-heading">Upload Front Side</div>
                    <div className="form-group mt-2">
                      {IdFront ? (
                        <img
                          src={previewFront}
                          className="card-preview"
                          alt="idCardPreview"
                        />
                      ) : (
                        <img
                          src={idCardPreview}
                          className="card-preview"
                          alt="idCardPreview"
                        />
                      )}
                    </div>
                    <div className="d-flex justify-content-end">
                      <label
                        htmlFor="uploadFront"
                        className="select-file-button"
                      >
                        <div role="button">Select File</div>
                        <input
                          type="file"
                          id="uploadFront"
                          hidden
                          accept="image/png, image/jpeg"
                          onChange={onSelectFront}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="w-100 mr-1">
                    <div className="field-heading">Upload Back Side</div>
                    <div className="form-group mt-2">
                      {IdBack ? (
                        <img
                          src={previewBack}
                          className="card-preview"
                          alt="idCardPreview"
                        />
                      ) : (
                        <img
                          src={idCardPreview}
                          className="card-preview"
                          alt="idCardPreview"
                        />
                      )}
                    </div>
                    <div className="d-flex justify-content-end">
                      <label
                        htmlFor="uploadBack"
                        className="select-file-button"
                      >
                        <div role="button">Select File</div>
                        <input
                          type="file"
                          id="uploadBack"
                          hidden
                          accept="image/png, image/jpeg"
                          onChange={onSelectBack}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mt-1 d-flex justify-content-center">
                  <button type="button" className="btn-sign-in w-50">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditIdentityModal;
