import React, { useEffect, useState } from "react";
import IconCancel from "../../../Assets/images/icons/icon-cancel.svg";
import idCardPreview from "../../../Assets/images/card-preview.png";
import $ from "jquery";
import { useSelector } from "react-redux";

const EditBankModal = () => {
  const { userToken, storeInfo} = useSelector((state) => state.store);
  const [cheque, setCheque] = useState();
  const [previewCheque, setPreviewCheque] = useState();
  const [error, setErrors] = useState("");
  const [values, setValues] = useState({
    bankName:storeInfo.bankAccount.bankName?storeInfo.bankAccount.bankName:"",
    accountTitle: storeInfo.bankAccount.accountTitle?storeInfo.bankAccount.accountTitle:"",
    accountNumber:storeInfo.bankAccount.accountNumber?storeInfo.bankAccount.accountNumber:"",
    IBNnumber: storeInfo.bankAccount.ibanNumber?storeInfo.bankAccount.ibanNumber:"",
    branchCode:storeInfo.bankAccount.branchCode?storeInfo.bankAccount.branchCode:""
  });

  const handleInputOnChange = (e) => {
    setErrors("");
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  useEffect(() => {
    if (!cheque) {
      setPreviewCheque(undefined);
      return;
    }
    const objectUrlCheque = URL.createObjectURL(cheque);
    setPreviewCheque(objectUrlCheque);
    return () => URL.revokeObjectURL(objectUrlCheque);
  }, [cheque]);

  const onSelectCheque = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setCheque(undefined);
      return;
    }
    setCheque(e.target.files[0]);
  };
  const handleModalClose = () => {
    $("#bank-modal").modal("hide");
  };

  return (
    <div
      className="modal"
      id="bank-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="BankModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="bank-modal">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={IconCancel} alt="IconCancel" />
                </span>
              </button>
            </div>
            <div className="modal-body pt-0">
              <div className="body-modal">
                <div className="mt-1">
                  <div className="heading-modal">Edit Bank Details!</div>
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <div className="w-100 mr-1">
                    <div className="field-heading">Bank Name</div>
                    <div className="form-group">
                      <input
                        type="name"
                        className="form-control"
                        placeholder="Bank Name"
                        value = {values.bankName}
                        onChange={handleInputOnChange}
                        name="bankName"
                      />
                    </div>
                  </div>
                  <div className="w-100 mr-1">
                    <div className="field-heading">Account Title</div>
                    <div className="form-group">
                      <input
                        type="name"
                        className="form-control"
                        placeholder="Account Title"
                        value = {values.accountTitle}
                        onChange={handleInputOnChange}
                        name="accountTitle"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <div className="w-100 mr-1">
                    <div className="field-heading">Acoount Number</div>
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Acoount Number"
                        value = {values.accountNumber}
                        onChange={handleInputOnChange}
                        name="accountNumber"
                      />
                    </div>
                  </div>
                  <div className="w-100 mr-1">
                    <div className="field-heading">IBAN Number</div>
                    <div className="form-group">
                      <input
                        type="name"
                        className="form-control"
                        placeholder="IBAN Number"
                        value= {values.IBNnumber}
                        onChange={handleInputOnChange}
                        name="IBNnumber"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <div className="w-100 mr-1">
                    <div className="field-heading">Bank Code</div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Bank Code"
                        onChange={handleInputOnChange}
                        value = {values.branchCode}
                        name = "branchCode"
                      />
                    </div>
                  </div>
                  <div className="w-100 ml-1">
                    <div className="field-heading mb-1">Upload Cheque Copy</div>
                    <div className="form-group">
                      {cheque ? (
                        <img
                          src={previewCheque}
                          className="cheque-preview"
                          alt="idCardPreview"
                        />
                      ) : (
                        <img
                          src={idCardPreview}
                          className="cheque-preview"
                          alt="idCardPreview"
                        />
                      )}
                    </div>
                    <div className="d-flex justify-content-end">
                      <label
                        htmlFor="uploadCheque"
                        className="select-file-button"
                      >
                        <div role="button">Select File</div>
                        <input
                          type="file"
                          id="uploadCheque"
                          hidden
                          accept="image/png, image/jpeg"
                          onChange={onSelectCheque}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mt-1 d-flex justify-content-center">
                  <button type="button" className="btn-sign-in w-50">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBankModal;
