import React, { useState } from "react"

import ChatConversations from "../../Components/Chat/ChatConversations"
import ChatMessages from "../../Components/Chat/ChatMessages"
import noConversation  from "../../Assets/images/chat/no_conversation.svg"
import noChat  from "../../Assets/images/chat/no_chat.svg"

const Chat = () => {
  const [conversationId, setConversationId] = useState(null);
  const [conversationStatus, setConversationStatus] = useState(true);
  const [itemId, setItemId] = useState("");
  const [userId, setUserId] = useState("");
  const [messageStatusId, setMessageStatusId] = useState(null);

  return (
    <div class="chat-main mt-5 mb-2">
      <div class="chat-parent">
        <div class="chat-header d-flex justify-content-between p-3">
          <div class="live-chat">
            <h6>LIVE CHAT</h6>
          </div>
        </div>
        <div className="body d-flex">
          {
            conversationStatus?<>
                    <ChatConversations setConversationId={setConversationId} setItemId={setItemId} setUserId={setUserId} messageStatusId={messageStatusId} setMessageStatusId={setMessageStatusId} setConversationStatus={setConversationStatus}/>
                    {conversationId?<ChatMessages conversationId = {conversationId} itemId = {itemId} userId = {userId} setMessageStatusId={setMessageStatusId}/>:<img className="no-chat" src ={noChat} alt ="No chat"/>}
            </>:<>
            <img  className="no-conversation" src ={noConversation} alt ="no conversations"/></>
          }
        </div>
      </div>
    </div>
  )
}

export default Chat
