import React, { useState } from "react";
import InputColor from "react-input-color";
import axios from "axios";
import Joi from "joi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import { useSelector } from "react-redux";
import { productnameregex } from "../../shared/validators";
import Step1 from "../../Assets/images/icons/dashboard-icons/step1-active.svg";
import Step2 from "../../Assets/images/icons/dashboard-icons/step2.svg";
import Step2active from "../../Assets/images/icons/dashboard-icons/step2-active.svg";
import Step3 from "../../Assets/images/icons/dashboard-icons/step3.svg";
import Step3active from "../../Assets/images/icons/dashboard-icons/step3-active.svg";
import Step4 from "../../Assets/images/icons/dashboard-icons/step4.svg";
import Step4active from "../../Assets/images/icons/dashboard-icons/step4-active.svg";
import PropertiesAdd from "../../Assets/images/icons/dashboard-icons/properties-add.svg";
import PropertiesCancel from "../../Assets/images/icons/dashboard-icons/properties-cancel.svg";
import SubPropertiesAdd from "../../Assets/images/icons/dashboard-icons/sub-properties-add.svg";
import SubPropertiesCancel from "../../Assets/images/icons/dashboard-icons/sub-properties-cancel.svg";
import CancelPreview from "../../Assets/images/icons/dashboard-icons/icon-cancel-preview.svg";
import listingPreview from "../../Assets/images/chat/listingPreview.png";
import ItemPreview from "../../Assets/images/icons/dashboard-icons/item-preview.svg";
import ItemPreviewadd from "../../Assets/images/icons/dashboard-icons/ItemPreviewadd.svg";
import { apiBaseUrl } from "../../Components/config/Config";
import rightArrow from "../../Assets/images/icons/arrow-circle-right.svg";
import leftArrow from "../../Assets/images/icons/arrow-circle-left.svg";
import StarFilled from "../../Assets/images/icons/icon-star-filled.svg";

// For draft addition
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const MAX_LENGTH = 5;

function AddProduct() {
  let navigate = useNavigate();
  const { userToken, storeInfo } = useSelector((state) => state.store);

  const [selectedFile, setSelectedFile] = useState([]);
  const [previewFile, setPreviewFile] = useState([]);
  const [convertedText, setConvertedText] = useState("");
  const [stepCount, setStepCount] = useState(1);
  const [isSale, setIsSale] = useState(false);
  const [salePercent, setSalePercent] = useState(0);
  const [isDelivery, setIsDelivery] = useState(false);
  const [deliveryCharges, setDeliveryCharges] = useState(0);

  const [tags, setTags] = useState(["Smart Watch"]);

  const [values, setValues] = useState({
    title: "",
    price: "",
  });

  const [inputProperties, setInputProperties] = useState([
    { subProperty: [{ type: "S", quantity: "1" }], color: "#5e72e4" },
  ]);
  const [qtyLimit, setQtyLimit] = useState(0);

  const [colorIndex, setColorIndex] = useState(0);
  const [subPropertyType, setSubPropertyType] = useState("");

  const handleInputChange = (e, i, j) => {
    const { name, value } = e.target;
    const list = [...inputProperties];
    list[i]["subProperty"][j][name] = value;
    setInputProperties(list);
  };

  const handleColorInputChange = (e, index) => {
    const list = [...inputProperties];
    list[index]["color"] = e.hex;
  };
  const handleRemoveClick = (index) => {
    const list = [...inputProperties];
    list.splice(index, 1);
    setInputProperties(list);
  };
  const handleAddClick = () => {
    setInputProperties([
      ...inputProperties,
      { subProperty: [{ type: "", quantity: "" }], color: "#5e72e4" },
    ]);
  };
  const handleAddSubProperty = (i, j) => {
    const list = [...inputProperties];
    list[i]["subProperty"].push({ type: "", quantity: "" });
    setInputProperties(list);
  };
  const handleRemoveSubProperty = (i, j) => {
    const list = [...inputProperties];
    list[i]["subProperty"].splice(j, 1);
    setInputProperties(list);
  };

  const [loagging, setLoagging] = useState(false);
  const [errors, setErrors] = useState({
    title: "",
    price: "",
  });

  const handleIsSaleCheck = (e) => {
    if (e.target.checked) {
      setIsSale(true);
    } else {
      setIsSale(false);
    }
  };
  const handleIsDeliveryCheck = (e) => {
    if (e.target.checked) {
      setIsDelivery(true);
    } else {
      setIsDelivery(false);
    }
  };
  const handleInputOnChangeInput = (e) => {
    e.preventDefault();
    let discountPrcentage = e.target.value;
    setSalePercent(discountPrcentage);
  };
  const handleDeliveryCharges = (e) => {
    e.preventDefault();
    setDeliveryCharges(e.target.value);
  };
  const handleInputOnChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  var schema = Joi.object({
    title: Joi.string()
      .required()
      .regex(productnameregex)
      .min(8)
      .max(100)
      .label("Title")
      .messages({
        "string.empty": "Title cannot be empty",
        "string.min": "Title must be at least 8 characters long",
        "string.max": "Title must be no more than 100 characters long",
      }),
    price: Joi.number().required().min(1).label("Unit price").messages({
      "number.empty": "Price cannot be empty",
    }),
  });
  const formValidation = () => {
    const result = schema.validate(values);
    if (!result.error) return null;

    let errors = {};
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const handleOnSubmit = (e, status) => {
    e.preventDefault();
    const eerrors = formValidation();

    if (eerrors) {
      setErrors({ ...eerrors });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      console.log(eerrors);
    } else {
      setErrors({});
      setLoagging(!loagging);

      addMenuItem(
        {
          ...values,
        },
        status
      );
      // clearErrors();
    }
  };

  const addMenuItem = (item, status) => {
    let keyWords = JSON.stringify(tags);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "x-auth-token": userToken,
      },
    };
    try {
      const totalQuantity = inputProperties.reduce((allTotal, currentValue) => {
        const sum = currentValue.subProperty.reduce((total, sub) => {
          return parseFloat(total) + parseFloat(sub.quantity);
        }, 0);
        return allTotal + sum;
      }, 0);
      const formData = new FormData();
      for (const key of Object.keys(selectedFile)) {
        formData.append("images", selectedFile[key]);
      }
      formData.append("title", values.title);
      formData.append("keyWords", keyWords);
      formData.append("price", values.price);
      formData.append("isSale", isSale);
      formData.append("salePercent", salePercent);
      formData.append("isDelivery", isDelivery);
      formData.append("deliveryCharges", deliveryCharges);
      formData.append("description", convertedText);
      formData.append("totalQuantity", totalQuantity);
      formData.append("properties", JSON.stringify(inputProperties));
      formData.append("status", status);

      axios
        .post(`${apiBaseUrl}/api/listing/product`, formData, config)
        .then((q) => {
          resetProductForm();
          toast("Product Added!", { type: "success" });
          navigate("/menu");
        })
        .catch((err) => {
          toast("Internal server error.");
        });
    } catch (err) {
      return err;
    }
  };
  const resetProductForm = () => {
    setSelectedFile([]);
    setPreviewFile([]);
    setValues({
      title: "",
      price: "",
    });
    setErrors({
      title: "",
      price: "",
    });
  };

  const onSelectFile = (e) => {
    if (e.target.files) {
      const fileArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      if (fileArray.length > MAX_LENGTH) {
        e.preventDefault();
        toast(`Cannot upload files more than ${MAX_LENGTH}`);
        return;
      }
      setSelectedFile(e.target.files);
      setPreviewFile((prevImages) => prevImages.concat(fileArray));
      Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));
    }
  };
  const deleteHandler = (e, image) => {
    e.preventDefault();
    setPreviewFile(previewFile.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  };
  const renderPhotos = (source) => {
    return source.map((photo) => {
      return (
        <>
          <div className="position-relative">
            <div
              role="button"
              className="mr-1 d-flex justify-content-end"
              onClick={(e) => deleteHandler(e, photo)}
            >
              <img
                className="position-absolute"
                src={CancelPreview}
                alt="CancelPreview"
              />
            </div>
            <img
              className="itemPreview mr-1"
              src={photo}
              key={photo}
              alt="itemPreview"
            />
          </div>
        </>
      );
    });
  };

  const handleColorIndex = (index) => {
    setColorIndex(index);
  };
  const handleSubPropertySize = (subQuantity, subType) => {
    setQtyLimit(subQuantity);
    setSubPropertyType(subType);
  };
  return (
    <div id="addproduct" className="">
      <div className="card-container">
        <div className="d-flex justify-content-between register-progressbar-track">
          <div
            role="button"
            className="track-progressbar text-center"
            onClick={() => setStepCount(1)}
          >
            <img src={Step1} alt="Step1" />
            <div className="mt-2 stepper-text-active">Introduction</div>
            {stepCount >= 2 && <div id="tracker-register-active"></div>}
          </div>
          <div
            role="button"
            className="track-progressbar text-center"
            onClick={() => setStepCount(2)}
          >
            {stepCount >= 2 ? (
              <>
                <img src={Step2active} alt="Step2active" />
                <div className="stepper-text-active mt-2">
                  Brief Description
                </div>
              </>
            ) : (
              <>
                <img src={Step2} alt="Step2" />
                <div className="stepper-text mt-2 p-0 m-0">
                  Brief Description
                </div>
              </>
            )}

            {stepCount >= 3 && <div id="tracker-register-active"></div>}
          </div>
          <div
            role="button"
            className="track-progressbar text-center"
            onClick={() => setStepCount(3)}
          >
            {stepCount >= 3 ? (
              <>
                <img src={Step3active} alt="Step3active" />
                <div className="stepper-text-active mt-2">Properties</div>
                {stepCount >= 4 && <div id="tracker-register-active"></div>}
              </>
            ) : (
              <>
                <img src={Step3} alt="Step3" />
                <div className="stepper-text mt-2">Properties</div>
              </>
            )}
          </div>
          <div
            role="button"
            className="track-progressbar text-center"
            onClick={() => setStepCount(4)}
          >
            {stepCount >= 4 ? (
              <>
                <img src={Step4active} alt="Step4active" />
                <div className="stepper-text-active mt-2">Preview</div>
              </>
            ) : (
              <>
                <img src={Step4} alt="Step4" />
                <div className="stepper-text mt-2">Preview</div>
              </>
            )}
          </div>
          <div id="tracker-register"></div>
        </div>
      </div>
      <form className="card-container-form mt-2 p-">
        <div className="h-100 d-flex flex-column">
          {stepCount === 1 ? (
            <>
              <div className=" h-100">
                <div className="mt-1">
                  <div className="field-heading">Title of Product</div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Title of your product"
                      value={values.title || ""}
                      name="title"
                      onChange={handleInputOnChange}
                    />
                    {errors.title && (
                      <div className="error-text pb-1">{errors.title}</div>
                    )}
                  </div>
                </div>
                <div className="mt-1">
                  <div className="field-heading">Keywords</div>
                  <div className="form-group">
                    <TagsInput
                      classNames={{
                        tag: "button-keyword",
                        input: "form-control w-25",
                      }}
                      value={tags}
                      onChange={setTags}
                      name="tags"
                      placeHolder="enter keywords or tags"
                    />
                  </div>
                </div>
                <div className="mt-1 d-flex justify-content-between w-100">
                  <div className="w-50 mr-4">
                    <div className="field-heading">Product Price</div>
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Product Price"
                        value={values.price || ""}
                        name="price"
                        min={0}
                        onChange={handleInputOnChange}
                      />
                      {errors.price && (
                        <div className="error-text pb-1">{errors.price}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-1 d-flex">
                  <div className="w-50">
                    <div className="d-flex">
                      <input
                        className="m-0"
                        type="checkbox"
                        name="sale"
                        id="sale"
                        onChange={handleIsSaleCheck}
                      />
                      <div className="field-heading ml-2">On Sale</div>
                    </div>
                    {isSale && (
                      <div className="form-group d-flex">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="1 - 95"
                          min="1"
                          max="95"
                          name="precentage"
                          onChange={handleInputOnChangeInput}
                        />
                        <div className="ml-3 mr-3 mt-2 sale-percentage">%</div>
                      </div>
                    )}
                  </div>

                  <div className="ml-2 w-50">
                    <div className="d-flex">
                      <input
                        className="m-0"
                        type="checkbox"
                        name="sale"
                        id="sale"
                        onChange={handleIsDeliveryCheck}
                      />
                      <div className="field-heading ml-2">Delivery Charges</div>
                    </div>
                    {isDelivery && (
                      <div className="form-group d-flex">
                        <input
                          type="number"
                          min={1}
                          className="form-control"
                          placeholder="Delivery Charges"
                          name="deliveryCharges"
                          onChange={handleDeliveryCharges}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {stepCount === 2 ? (
            <>
              <div className="col-scroller">
                <div className="d-flex justify-content-start full-set h-100">
                  <div className="left-set justify-content-star d-flex flex-wrap">
                    {selectedFile.length > 0 ? (
                      <>{renderPhotos(previewFile)}</>
                    ) : (
                      <>
                        <img
                          className="itemPreview mr-1"
                          src={ItemPreview}
                          alt="ItemPreview"
                        />
                        <img
                          className="itemPreview mr-1"
                          src={ItemPreview}
                          alt="ItemPreview"
                        />
                        <img
                          className="itemPreview mr-1"
                          src={ItemPreview}
                          alt="ItemPreview"
                        />
                        <img
                          className="itemPreview mr-1"
                          src={ItemPreview}
                          alt="ItemPreview"
                        />
                        <img
                          className="itemPreview mr-1"
                          src={ItemPreview}
                          alt="ItemPreview"
                        />
                      </>
                    )}
                    <label htmlFor="uploadProductImages">
                      <div role="button">
                        <img
                          className="itemPreview mr-1"
                          src={ItemPreviewadd}
                          alt="ItemPreviewadd"
                        />
                      </div>
                      <input
                        type="file"
                        id="uploadProductImages"
                        multiple
                        hidden
                        accept="image/png, image/jpeg"
                        onChange={onSelectFile}
                      />
                    </label>
                  </div>
                  <div className="right-set">
                    <div className="field-heading">Product Description</div>
                    <ReactQuill
                      theme="snow"
                      placeholder="Relevant description e.g Add some details about your item"
                      className="editor-wrapper mt-2"
                      value={convertedText}
                      onChange={setConvertedText}
                    >
                      {/* <div className="editing-area"></div> */}
                    </ReactQuill>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {stepCount === 3 ? (
            <>
              <div className="h-100 properties">
                {inputProperties?.map((val, i) => {
                  return (
                    <div key={i} className="row col-12 mt-2">
                      <div className="col-10 border-properties">
                        <div className="row col-12">
                          <div className="col-1"></div>
                          <div className=" col-4 field-heading">Size</div>
                          <div className=" col-4 field-heading">Quantity</div>
                          <div className=" col-2 field-heading">Color</div>
                        </div>
                        {val["subProperty"].map((item, index) => {
                          return (
                            <div key={index}>
                              <div className="row col-12">
                                <div className="col-1 m-auto">
                                  {val["subProperty"].length !== 1 && (
                                    <div
                                      role="button"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleRemoveSubProperty(i, index);
                                      }}
                                    >
                                      <img
                                        src={SubPropertiesCancel}
                                        alt="SubPropertiesCancel"
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="col-4">
                                  <div className="form-group">
                                    <select
                                      name="type"
                                      className="form-control h-100 "
                                      value={item.type}
                                      onChange={(e) => {
                                        handleInputChange(e, i, index);
                                      }}
                                    >
                                      <option>Select Type</option>
                                      <option>cm</option>
                                      <option>feet</option>
                                      <option>S</option>
                                      <option>M</option>
                                      <option>L</option>
                                      <option>XL</option>
                                      <option>XXL</option>
                                      <option>XXXL</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control h-100"
                                      type="number"
                                      min="1"
                                      placeholder="Quantity"
                                      name="quantity"
                                      value={item.quantity}
                                      onChange={(e) => {
                                        handleInputChange(e, i, index);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-2">
                                  {val["subProperty"].length - 1 === index && (
                                    <div
                                      data-toggle="tooltip"
                                      title="Select Color!"
                                      className="form-group mt-2 w-100"
                                    >
                                      <InputColor
                                        className="form-control p-2"
                                        initialValue={val.color}
                                        onChange={(e) =>
                                          handleColorInputChange(e, i)
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="col-1 m-auto">
                                  <div className="form-group">
                                    {val["subProperty"].length - 1 ===
                                      index && (
                                      <div
                                        role="button"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleAddSubProperty(i, index);
                                        }}
                                      >
                                        <img
                                          src={SubPropertiesAdd}
                                          alt="SubPropertiesAdd"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="col-1 text-center m-auto">
                        {inputProperties.length !== 1 && (
                          <div
                            role="button"
                            className="img-wid "
                            onClick={(e) => {
                              e.preventDefault();
                              handleRemoveClick(i);
                            }}
                          >
                            <img
                              src={PropertiesCancel}
                              alt="PropertiesCancel"
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-1 img-wid text-center m-auto">
                        {inputProperties.length - 1 === i && (
                          <div
                            role="button"
                            className="img-wid "
                            onClick={handleAddClick}
                          >
                            <img src={PropertiesAdd} alt="PropertiesAdd" />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <></>
          )}
          {stepCount === 4 ? (
            <>
              <div id="productpreview" className="h-100 properties">
                <div className="details flex-lg-row flex-md-column flex-sm-column d-flex justify-content-start">
                  <div className="d-flex  flex-column mr-3">
                    <div className="big-img border">
                      {previewFile.length > 0 ? (
                        <img
                          className="img-tag"
                          src={previewFile[0]}
                          alt="tag-pic"
                        />
                      ) : (
                        <div className="carousel-item active carts-bay-store">
                          <div className="d-flex flex-column-reverse  justify-content-center align-items-center">
                            <div className="text-center">
                              <h2 className="text-warning">Carts Bay</h2>
                              <h5 className="text-dark">Listing</h5>
                            </div>
                            <div className="ml-2">
                              <img
                                src={listingPreview}
                                className="img-fluid"
                                alt="First slide"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mt-3">
                      <div className="thumb d-flex">
                        <img src={leftArrow} alt="leftArrow" />
                        {previewFile.map((photo, index) => {
                          return (
                            <>
                              <img
                                key={index}
                                className="thumb-img ml-2"
                                src={photo}
                                alt="preview-img"
                              />
                            </>
                          );
                        })}
                        <img src={rightArrow} alt="leftArrow" />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-xl-column flex-lg-column flex-md-row flex-sm-row">
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="product-title p-0">{values.title}</h2>
                    </div>
                    <div className="d-flex flex-xl-row flex-lg-row flex-md-column flex-sm-column justify-content-start align-item-center">
                      <div className="">
                        <img
                          className="mr-1 star"
                          src={StarFilled}
                          alt="StarFilled"
                        />
                        <img
                          className="mr-1 star"
                          src={StarFilled}
                          alt="StarFilled"
                        />
                        <img
                          className="mr-1 star"
                          src={StarFilled}
                          alt="StarFilled"
                        />
                        <img
                          className="mr-1 star"
                          src={StarFilled}
                          alt="StarFilled"
                        />
                        <img
                          className="mr-1 star"
                          src={StarFilled}
                          alt="StarFilled"
                        />
                      </div>
                      <div className="ml-2 mt-1 sold-items">(100 Sold)</div>
                    </div>

                    <div className="d-flex  justify-content-between align-items-center ">
                      <span className="p-0">
                        <span className="text-store">Store : </span>
                        <span className="link brand-link">
                          {storeInfo.storeName}
                        </span>
                      </span>
                    </div>
                    <div className="mt-2 d-flex  justify-content-between align-items-center ">
                      <span className="product-price">RS. {values.price}</span>
                    </div>
                    <div className="for-setting">
                      <div className="mt-1 d-flex justify-content-between align-items-center ">
                        <span className="p-0 text-store">Colors</span>
                      </div>
                      <div className="mt-1 colors d-flex">
                        {inputProperties.map((item, index) => {
                          return (
                            <div
                              key={index}
                              // className="colors-button"
                              className={
                                colorIndex === index
                                  ? "active-color"
                                  : "colors-button"
                              }
                              style={{ background: `${item.color}` }}
                              onClick={() => handleColorIndex(index)}
                            ></div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="for-setting">
                      <div className="mt-1 d-flex justify-content-between align-items-center ">
                        <span className="text-store">Sizes</span>
                      </div>
                      <div className="">
                        <div className="d-flex flex-row">
                          {inputProperties.map((item, index) => {
                            return (
                              <div key={index}>
                                {colorIndex === index ? (
                                  <>
                                    {item["subProperty"].map((sub, i) => {
                                      return (
                                        <button
                                          key={i}
                                          className={
                                            subPropertyType === sub.type
                                              ? "btn-size-active mr-2"
                                              : "btn-size mr-2"
                                          }
                                          type="button"
                                          onClick={() =>
                                            handleSubPropertySize(
                                              sub.quantity,
                                              sub.type
                                            )
                                          }
                                        >
                                          <div className="text-sizes">
                                            {sub.type}
                                          </div>
                                        </button>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="">
                      {qtyLimit === 0 ? (
                        <></>
                      ) : (
                        <>
                          <div className="mt-1 d-flex justify-content-between align-items-center ">
                            <span className="text-store">Quantity</span>
                          </div>
                          <div className="row justify-content-between text-left">
                            <div className="col-sm-6 col-md-12">
                              <div className="input-group">
                                <div className="quantity">{qtyLimit}</div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="details d-flex justify-content-start">
                  <div className="w-100 mr-4">
                    <fieldset className="card-description h-100">
                      <legend className="w-auto ml-2 mr-2 text-dim">
                        <div className="ml-2 mr-2">Description</div>
                      </legend>
                      <div className="description">
                        <div
                          className="about-product"
                          dangerouslySetInnerHTML={{
                            __html: convertedText,
                          }}
                        ></div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="d-flex justify-content-end">
            {stepCount > 1 ? (
              <div className="mt-2 mr-4">
                <button
                  type="button"
                  className="btn-back w-100"
                  onClick={() => setStepCount(stepCount - 1)}
                >
                  Back
                </button>
              </div>
            ) : (
              <></>
            )}

            <div className="mt-2">
              {stepCount <= 3 ? (
                <button
                  type="button"
                  className="btn-next"
                  onClick={() => setStepCount(stepCount + 1)}
                >
                  Next
                </button>
              ) : (
                <div className="d-flex">
                  <button
                    className="btn-draft mr-4"
                    onClick={(e) => {
                      handleOnSubmit(e, "Draft");
                    }}
                  >
                    Draft
                  </button>
                  <button
                    className="btn-next"
                    onClick={(e) => {
                      handleOnSubmit(e, "Pending");
                    }}
                  >
                    Publish
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddProduct;
