import React, { useState } from "react";
import IconCancel from "../../../Assets/images/icons/icon-cancel.svg";
import { useSelector, useDispatch } from "react-redux";
import { updateContact } from "../../../app/features/store/storeSlice";
import Joi from "joi";
import axios from "axios";
import OtpInput from "react-otp-input";
import $ from "jquery";
import { apiBaseUrl } from "../../config/Config";
import { contactRegex } from "../../../shared/validators";

const ChangeNumberModal = () => {
  const { userToken , storeInfo} = useSelector((state) => state.store);
  const dispatch = useDispatch();

  const [storeContact, setStoreContact] = useState({
    storeContact: storeInfo.contact?storeInfo.contact:""
  });
  const [errors, setErrors] = useState({
    storeContact: "",
  });
  const [storeContactError, setStoreContactError] = useState(false);
  const [storeContactErrorMessage, setStoreContactErrorMessage] = useState("");

  const [storeContactChangeOtp, setStoreContactChangeOtp] = useState(false);
  const [OTP, setOTP] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const [otpToken, setOtpToken] = useState(null);

  const handleChange = (OTP) => {
    setOTP(OTP);
  };

  const handleModalClose = () => {
    $("#number-modal").modal("hide");
  };

  const handleInputOnChange = (e) => {
    const { name, value } = e.target;
    setStoreContact({
      ...storeContact,
      [name]: value,
    });
    setErrors({});
  };

  var schema = Joi.object({
    storeContact: Joi.string().required().regex(contactRegex).min(6).max(15).label("storeContact").messages({
      "string.empty": "Contact cannot be empty",
      "string.pattern.base": "Please enter correct Contact",
      "string.min": "Contact must be at least 6 digits",
      "string.max":"Contact  must be no more than 15 digits"
    }),
  });

  const formValidation = () => {
    const result = schema.validate(storeContact)
    if (!result.error) return null;
    let errors = {};
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formErrors = formValidation();
    if (formErrors) {
      setErrors({ ...formErrors });
    } else {
      setErrors({});
      submitStoreContact(storeContact.storeContact);
    }
  };

  const submitStoreContact = (contact) => {
    const config = {
      headers: {
        "x-auth-token": userToken,
      },
    };
    axios
      .put(`${apiBaseUrl}/api/store/change-contact`, { contact: contact }, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          setStoreContactChangeOtp(true);
          setOtpToken(res.data.data.token);
        }
      })
      .catch((err) => {
        if (err.response.data.meta.statusCode === 400) {
          setStoreContactError(true);
          setStoreContactErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setStoreContactError(false);
            setStoreContactErrorMessage("");
          }, 5000);
        }
        if (err.response.data.meta.statusCode === 401) {
          setStoreContactError(true);
          setStoreContactErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setStoreContactError(false);
            setStoreContactErrorMessage("");
          }, 5000);
        }
      });
  };

  const handleOnSubmitOtp = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "x-auth-otp": otpToken,
      },
    };
    const otpParse = Number(OTP);

    axios
      .put(`${apiBaseUrl}/api/store/verify-change-contact`, { otp: otpParse }, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          dispatch(updateContact(res.data.data.contact));
          setStoreContactChangeOtp(false);
          setOTP("");
          handleModalClose();
        }
      })
      .catch((err) => {
        if (err.response.data.meta.statusCode === 400) {
          setOtpError(true);
          setOtpErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setOtpError(false);
            setOtpErrorMessage("");
          }, 5000);
        }
        if (err.response.data.meta.statusCode === 401) {
          setOtpError(true);
          setOtpErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setOtpError(false);
            setOtpErrorMessage("");
          }, 5000);
        }
      });
  };

  return (
    <div
      className="modal"
      id="number-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="numberModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="number-modal">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={IconCancel} alt="IconCancel" />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="body-modal">
                {storeContactChangeOtp === false && (
                  <>
                    <form onSubmit={handleOnSubmit}>
                      <div className="mt-1">
                        <div className="heading-modal">
                          Change Phone Number!
                        </div>
                      </div>
                      {storeContactError && (
                        <div
                          className="error-text text-center alert-danger"
                          role="alert"
                        >
                          {storeContactErrorMessage}
                        </div>
                      )}
                      <div className="mt-3">
                        <div className="field-heading">Phone</div>
                        <div className="form-group">
                          <input
                            type="contact"
                            className="form-control"
                            value={storeContact.storeContact || ""}
                            name="storeContact"
                            onChange={handleInputOnChange}
                          />
                          {errors.storeContact && (
                            <div className="error-text">
                              {errors.storeContact}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mt-1 mb-3">
                        <button type="submit" className="btn-sign-in w-100">
                          Save Change
                        </button>
                      </div>
                    </form>
                  </>
                )}
                {storeContactChangeOtp === true && (
                  <>
                    <form onSubmit={handleOnSubmitOtp}>
                      <div className="mt-1">
                        <div className="heading-modal">
                          Otp sent on {storeContact.storeContact}!
                        </div>
                      </div>
                      {otpError && (
                        <div
                          className="error-text text-center alert-danger"
                          role="alert"
                        >
                          {otpErrorMessage}
                        </div>
                      )}
                      <div className="mt-3">
                        <div className="field-heading">OTP</div>
                        <div className="mt-1">
                          <OtpInput
                            onChange={handleChange}
                            value={OTP}
                            inputStyle="otpinputStyle"
                            numInputs={6}
                            separator={<span></span>}
                          />
                        </div>
                        <div className="d-flex justify-content-between mt-2 mb-3">
                          <div className="otp-resend">Didn't receive OTP</div>
                          <div role="button" className="otp-resend-link">
                            Resend
                          </div>
                        </div>
                      </div>
                      <div className="mt-1 mb-3">
                        <button type="submit" className="btn-sign-in w-100">
                          Save Change
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeNumberModal;
