export const storenameregex = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>]+$/;
export const cnicRegex = /^\d{5}\d{7}\d{1}$/;
export const  emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const  contactRegex = /^03\d{9}$/
export const  passwordregex =(/^[^\s]+$/);
export const arearegex = /^[a-zA-Z0-9\s\-.,()+*&^%$#@!]+$/
export const locationregex = /^[a-zA-Z0-9\-.,()+*&^%$#@!]+(\s[a-zA-Z0-9\-.,()+*&^%$#@!]+)*$/;
export const postalzipcoderegex = /^[0-9A-Za-z-]+$/;
export const fullname = /^[a-zA-Z0-9]+(?:\s[a-zA-Z0-9\-.,()+]+)*$/;
export const addressregex = /^(?!\s)[\w\s\-.,()+*&^%$#@!\/.]+(?<!\s)$/;
export const  IBANRegex  = /^[A-Z0-9]+$/;
export const  accountTitleregex = /^[a-zA-Z'-]+(?:\s[a-zA-Z'-]+)*$/
export const banknameregex = /^[a-zA-Z'-]+(?:\s[a-zA-Z'-]+)*$/;
export const cityregex = /^[a-zA-Z]+(?:\s[a-zA-Z'-]+)*$/;
export const postalzipcdoregex = /^[0-9]+$/;
export const landmarkregex = /^[a-zA-Z0-9]+(?:\s[a-zA-Z0-9\-.,()+]+)*$/;
export const completeaddressregex = /^[a-zA-Z0-9\s\-_.,]+$/;
export const bankBranchCodeRegex = /^[a-zA-Z0-9\-]+$/;
export const productnameregex = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>]+$/;
